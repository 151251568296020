import { useRef } from 'react';

export function useDebounce<A = unknown, R = void>(
  fn: (args: A) => R,
  ms: number
): (args: A) => void {
  const ref = useRef<ReturnType<typeof setTimeout>>();
  return (args: A): void => {
    if (ref.current) {
      clearTimeout(ref.current);
    }
    ref.current = setTimeout(() => fn(args), ms);
  };
}
