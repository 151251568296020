import { Comment } from '@mui/icons-material';
import { DonationDTO } from '@/types/DonationDTO';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { format } from 'date-fns';
import { formatCurrency, formatStatus } from '@/utils/string-utils';
import React, { FC } from 'react';
interface Props {
  donationsList: DonationDTO[] | undefined;
}

export const PaymentTableContainer: FC<Props> = (props: Props) => {
  const { donationsList } = props;
  return (
    <TableContainer>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell align={'left'} sx={{ paddingLeft: '0px' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'18px'}>
                {'Дата'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ paddingLeft: '0px' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'18px'}>
                {'ФИО'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ paddingLeft: '0px' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'18px'}>
                {'E-mail'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ paddingLeft: '0px' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'18px'}>
                {'Статус'}
              </Typography>
            </TableCell>
            <TableCell align={'right'} sx={{ padding: '0px' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'18px'}>
                {'Сумма'}
              </Typography>
            </TableCell>
            <TableCell align={'right'} />
          </TableRow>
        </TableHead>
        <TableBody>
          {donationsList?.map((donation) => (
            <TableRow key={donation.id}>
              <TableCell align={'left'}>
                {format(donation.date, 'dd.MM.yyyy')}
              </TableCell>
              <TableCell
                align={'left'}
                sx={(theme: Theme) => ({
                  maxWidth: '350px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '150px',
                  },
                })}>
                <Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}>
                  {donation.isAnonymous || !donation.name
                    ? 'Анонимно'
                    : donation.name}
                </Typography>
              </TableCell>
              <TableCell align={'left'}>{donation.email}</TableCell>
              <TableCell align={'left'}>
                {formatStatus(donation.status)}
              </TableCell>
              <TableCell align={'right'} sx={{ padding: '0px' }}>
                {formatCurrency(donation.amount, 0)}
              </TableCell>
              <TableCell align={'right'}>
                <Tooltip
                  title={donation.comment}
                  placement={'bottom-start'}
                  sx={{ maxWidth: '180px' }}>
                  <Comment style={{ display: 'block' }} />
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
