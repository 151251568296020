import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { BurgerIcon } from '@/app/icons/BurgerIcon';
import { CloseIcon } from '@/app/icons/CloseIcon';
import { Logo } from '@/app/icons/Logo';
import { MenuLink } from '@/components/common/MenuLink';
import { NavigationItem } from '@/components/common/navigation/NavigationItem';
import { NavigationItemButton } from '@/components/common/navigation/NavigationItemButton';
import { ROLE_ADMIN, ROLE_DEACON } from '@/app/roles';
import {
  ROUTE_ABOUT,
  ROUTE_ADMIN,
  ROUTE_AUTHENTICATION,
  ROUTE_DONATIONS,
  ROUTE_HELP_CONNECT,
  ROUTE_HELP_FAQ,
  ROUTE_HELP_FEEDBACK,
  ROUTE_HELP_WORK,
  ROUTE_HOME,
  ROUTE_PAYMENTS,
  ROUTE_PROFILE,
  ROUTE_TEMPLES,
} from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { getCurrentUser, signOut } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import React, { FC, Fragment, useState } from 'react';

export const NavigationDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const [open, setOpen] = useState<boolean>(false);
  const handleBeforeRedirect = (): void => {
    setOpen(false);
  };
  const handleClickLogout = (): void => {
    history.push(ROUTE_HOME);
    dispatch(signOut());
  };
  return (
    <Fragment>
      <IconButton
        color={'primary'}
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          zIndex: 100,
          position: 'absolute',
          left: 0,
          m: (theme: Theme) => theme.spacing(-1),
        }}>
        <BurgerIcon />
      </IconButton>
      <Drawer
        open={open}
        anchor={'left'}
        PaperProps={{ sx: { width: '100%' } }}>
        <Box p={(theme: Theme) => theme.spacing(2.5, 3, 3, 2)}>
          <Grid container={true} direction={'column'}>
            <Grid item={true} pb={(theme: Theme) => theme.spacing(2)}>
              <Grid
                container={true}
                alignItems={'center'}
                justifyContent={'center'}
                position={'relative'}>
                <IconButton
                  color={'primary'}
                  onClick={() => {
                    setOpen(false);
                  }}
                  sx={{
                    position: 'absolute',
                    left: 0,
                    m: (theme: Theme) => theme.spacing(-1),
                  }}>
                  <CloseIcon />
                </IconButton>
                <Grid item={true} display={'flex'}>
                  <Logo
                    color={'primary'}
                    sx={{
                      width: (theme: Theme) => theme.spacing(7.646484375),
                      height: (theme: Theme) => theme.spacing(5.625),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'column'} spacing={1}>
                <Grid item={true}>
                  <MenuLink
                    label={'Главная'}
                    to={ROUTE_TEMPLES}
                    onBeforeRedirect={handleBeforeRedirect}
                    activeOnlyWhenExact={true}
                  />
                </Grid>
                <Grid item={true}>
                  <MenuLink
                    label={'Пожертвования'}
                    to={ROUTE_DONATIONS}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
                <Grid item={true}>
                  <MenuLink
                    label={'О портале'}
                    to={ROUTE_ABOUT}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
                {authUser && authUser.roles.indexOf(ROLE_DEACON) > -1 && (
                  <Fragment>
                    <Grid item={true}>
                      <MenuLink
                        label={'Профиль'}
                        to={ROUTE_PROFILE}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink
                        label={'Реестр платежей'}
                        to={ROUTE_PAYMENTS}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                  </Fragment>
                )}
                {authUser && authUser.roles.indexOf(ROLE_ADMIN) > -1 && (
                  <Grid item={true}>
                    <MenuLink
                      label={'Администрирование'}
                      to={ROUTE_ADMIN}
                      onBeforeRedirect={handleBeforeRedirect}
                    />
                  </Grid>
                )}
                <Grid item={true}>
                  {authUser ? (
                    <MenuLink
                      label={'Выйти'}
                      to={ROUTE_AUTHENTICATION}
                      onClick={handleClickLogout}
                    />
                  ) : (
                    <MenuLink
                      label={'Войти или Подключиться'}
                      to={ROUTE_AUTHENTICATION}
                      onBeforeRedirect={handleBeforeRedirect}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item={true}
              pt={(theme: Theme) => theme.spacing(2.75)}
              pb={(theme: Theme) => theme.spacing(1.5)}>
              <Divider textAlign={'left'}>
                <Typography variant={'label1'}>{'Помощь'}</Typography>
              </Divider>
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'column'} spacing={1}>
                <Grid item={true}>
                  <MenuLink
                    label={'Как работает сервис'}
                    to={ROUTE_HELP_WORK}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
                <Grid item={true}>
                  <MenuLink
                    label={'Часто задаваемые вопросы'}
                    to={ROUTE_HELP_FAQ}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
                <Grid item={true}>
                  <MenuLink
                    label={'Подключение к сервису'}
                    to={ROUTE_HELP_CONNECT}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
                <Grid item={true}>
                  <MenuLink
                    label={'Обратная связь'}
                    to={ROUTE_HELP_FEEDBACK}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Fragment>
  );
};
