import { Admin } from '@/components/Admin';
import {
  ROUTE_ABOUT,
  ROUTE_ADMIN,
  ROUTE_AUTHENTICATION,
  ROUTE_DONATIONS,
  ROUTE_HELP,
  ROUTE_PAYMENTS,
  ROUTE_PROFILE,
  ROUTE_REGISTRATION,
  ROUTE_RESTORE,
  ROUTE_TEMPLES,
} from '@/app/routes';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { config } from '@/app/config';
import Main from '@/components/Main';
import React, { FC } from 'react';

export const Root: FC = () => {
  return (
    <Router basename={config.basePath}>
      <Switch>
        <Route path={ROUTE_ADMIN}>
          <Admin />
        </Route>
        <Route
          path={[
            ROUTE_TEMPLES,
            ROUTE_DONATIONS,
            ROUTE_ABOUT,
            ROUTE_HELP,
            ROUTE_AUTHENTICATION,
            ROUTE_REGISTRATION,
            ROUTE_RESTORE,
            ROUTE_PROFILE,
            ROUTE_PAYMENTS,
          ]}>
          <Main />
        </Route>
        <Redirect from={'/'} to={ROUTE_TEMPLES} />
      </Switch>
    </Router>
  );
};
