import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { RadioButtonFilter } from '@/types/filter/RadioButtonFilter';
import React, { FC } from 'react';

interface Props extends RadioGroupProps {
  buttons: RadioButtonFilter[];
}

export const RadioGroupFilter: FC<Props> = (props: Props) => {
  const { buttons, ...other } = props;
  return (
    <FormControl>
      <RadioGroup name={'radio-buttons-group-request'} {...other}>
        {buttons.map((button, index) => (
          <FormControlLabel
            key={index}
            sx={{
              margin: '0px',
              paddingBottom: '10px',
              '.MuiFormControlLabel-label': {
                fontWeight: 400,
                lineHeight: '22px',
                fontSize: '14px',
              },
            }}
            value={button.value}
            control={
              <Radio
                size={'small'}
                sx={{ padding: '0px', paddingRight: '8px' }}
              />
            }
            label={button.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
