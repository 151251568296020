import { AttachFileDTO } from '@/types/AttachFileDTO';
import { CloseIcon } from '@/app/icons/CloseIcon';
import { Dialog, Grid, IconButton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';
import 'swiper/css';

interface Props {
  open: boolean;
  images: AttachFileDTO[];
  onClose?: () => void;
  selectedImage: number;
}

export const TempleMobileImageView: FC<Props> = (props: Props) => {
  const { open, images, selectedImage, onClose } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={true}
      PaperProps={{
        sx: (theme: Theme) => ({
          overflowY: 'unset',
          margin: theme.spacing(2),
          width: '100%',
          maxWidth: `calc(100% - ${theme.spacing(4)})`,
          height: '100%',
          maxHeight: `calc(100% - ${theme.spacing(20)})`,
          borderRadius: theme.spacing(1.5),
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }),
      }}
      BackdropProps={{ sx: { backgroundColor: 'rgba(0,0,0,0.8)' } }}>
      <Grid
        container={true}
        direction={'column'}
        height={'100%'}
        flexWrap={'nowrap'}>
        <Grid item={true} mt={-5.5}>
          <Grid
            container={true}
            direction={'row'}
            justifyContent={'flex-end'}
            wrap={'nowrap'}
            spacing={2}>
            <Grid item={true}>
              {onClose && (
                <IconButton
                  disableRipple={true}
                  sx={(theme: Theme) => ({
                    padding: theme.spacing(0.375),
                  })}
                  onClick={onClose}>
                  <CloseIcon sx={{ color: 'white', opacity: '0.5' }} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item={true}
          xs={true}
          position={'relative'}
          sx={(theme: Theme) => ({
            overflow: 'hidden',
            borderRadius: theme.spacing(1.5),
          })}>
          <Swiper loop={true} initialSlide={selectedImage} spaceBetween={16}>
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={`data:image/jpeg;base64,${image.content}`}
                  alt={'slide-image'}
                  width={'100%'}
                  height={'100%'}
                  style={{ objectFit: 'contain' }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
    </Dialog>
  );
};
