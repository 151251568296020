import { AdminPanelActiveType } from '@/types/filter/AdminPanelActiveType';
import { AdminPanelEntityType } from '@/types/filter/AdminPanelEntityType';
import { AdminPanelTableContentDTO } from '@/types/AdminPanelTableContentDTO';
import { AdminPanelTableDTO } from '@/types/AdminPanelTableDTO';
import { ApproveRequestDTO } from '@/types/ApproveRequestDTO';
import { EditTempleDTO } from '@/types/EditTempleDTO';
import { EditTempleResponseDTO } from '@/types/EditTempleResponseDTO';
import { RegionDTO } from '@/types/RegionDTO';
import { RegistrationRequestDTO } from '@/types/RegistrationRequestDTO';
import { TempleDTO } from '@/types/TempleDTO';
import { TempleViewDTO } from '@/types/TempleViewDTO';
import { apiSlice } from '@/services/api/apiSlice';
import { createRequest } from '@/utils/request-utils';

export const templesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTemplesCount: build.query<
      number,
      { regionId?: number; templeId?: number }
    >({
      query: ({ regionId, templeId }) => ({
        url: '/temple/count',
        method: 'GET',
        params: {
          regionId: regionId,
          templeId: templeId,
        },
      }),
    }),
    getRequestsCount: build.mutation<number, void>({
      query: () => ({
        url: '/temple/requests/count',
        method: 'GET',
      }),
    }),
    getRegions: build.query<RegionDTO[], void>({
      query: () => ({
        url: '/temple/regions/visible',
        method: 'GET',
      }),
      providesTags: ['Regions'],
    }),
    getTemplesPage: build.query<
      TempleDTO[],
      {
        pageSize: number;
        pageNumber: number;
        regionId?: number;
        templeId?: number;
      }
    >({
      query: ({ pageSize, pageNumber, regionId, templeId }) => ({
        url: '/temple/page',
        method: 'GET',
        params: {
          pageSize: pageSize,
          pageNumber: pageNumber,
          regionId: regionId,
          templeId: templeId,
        },
      }),
      providesTags: ['TemplePage'],
    }),
    getTemple: build.query<TempleViewDTO, number | undefined>({
      query: (id) => ({
        url: '/temple/id',
        method: 'GET',
        params: {
          id: id,
        },
      }),
      providesTags: ['TempleEditPage'],
    }),
    getTempleByDeacon: build.query<TempleViewDTO, number | undefined>({
      query: (id) => ({
        url: '/temple/deacon/id',
        method: 'GET',
        params: {
          id: id,
        },
      }),
      providesTags: ['TempleEditPage'],
    }),
    getTempleWithDeacon: build.query<TempleViewDTO, number | undefined>({
      query: (id: number) => ({
        url: '/temple/admin/id',
        method: 'GET',
        params: {
          id: id,
        },
      }),
      providesTags: ['TempleEditPage'],
    }),
    editTemple: build.mutation<
      EditTempleResponseDTO,
      { request: EditTempleDTO; images: File[] }
    >({
      query: ({ request, images }) => ({
        url: '/temple/edit',
        method: 'POST',
        body: createRequest(request, images),
      }),
      invalidatesTags: ['TempleEditPage', 'TemplePage', 'Regions'],
    }),
    getAdminTable: build.query<
      AdminPanelTableDTO,
      {
        pageSize: number;
        pageNumber: number;
        searchString: string | undefined;
        dateStart: string | null;
        dateEnd: string | null;
        entityType: AdminPanelEntityType;
        activeType: AdminPanelActiveType | null;
      }
    >({
      query: ({
        pageSize,
        pageNumber,
        searchString,
        dateStart,
        dateEnd,
        entityType,
        activeType,
      }) => ({
        url: '/temple/table',
        method: 'GET',
        params: {
          pageSize: pageSize,
          pageNumber: pageNumber,
          searchString: searchString,
          dateStart: dateStart ? dateStart : '',
          dateEnd: dateEnd ? dateEnd : '',
          entityType: entityType,
          activeType: activeType ? activeType : '',
        },
      }),
      providesTags: (page?: AdminPanelTableDTO) => {
        return page
          ? [
              ...page.content
                .filter((item: AdminPanelTableContentDTO) => {
                  return item.type === AdminPanelEntityType.TEMPLE;
                })
                .map((item: AdminPanelTableContentDTO) => ({
                  type: 'AdminTemple' as const,
                  id: item.id,
                })),
              'AdminTemple',
            ]
          : ['AdminTemple'];
      },
    }),
    activateTemple: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: '/temple/activate',
        method: 'POST',
        body: { id: id },
      }),
      invalidatesTags: (result: unknown, error, arg: { id: number }) => {
        return [{ type: 'AdminTemple', id: arg.id }, 'Regions', 'TemplePage'];
      },
    }),
    deactivateTemple: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: '/temple/deactivate',
        method: 'POST',
        body: { id: id },
      }),
      invalidatesTags: (result: unknown, error, arg: { id: number }) => {
        return [{ type: 'AdminTemple', id: arg.id }, 'Regions', 'TemplePage'];
      },
    }),
    createEditRequest: build.mutation<
      unknown,
      { request: EditTempleDTO; images: File[] }
    >({
      query: ({ request, images }) => ({
        url: '/temple/edit-request',
        method: 'POST',
        body: createRequest(request, images),
      }),
      invalidatesTags: ['TempleEditPage', 'TemplePage', 'Regions'],
    }),
    approveEditRequest: build.mutation<
      unknown,
      { request: RegistrationRequestDTO; images: File[] }
    >({
      query: ({ request, images }) => ({
        url: '/temple/edit-request/approve',
        method: 'POST',
        body: createRequest(request, images),
      }),
      invalidatesTags: ['TempleEditPage', 'TemplePage', 'Regions'],
    }),
    rejectEditRequest: build.mutation<unknown, ApproveRequestDTO>({
      query: (dto: ApproveRequestDTO) => ({
        url: '/temple/edit-request/reject',
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['TemplePage'],
    }),
  }),
});

export const {
  useGetTemplesCountQuery,
  useGetTempleByDeaconQuery,
  useGetRegionsQuery,
  useGetTemplesPageQuery,
  useGetTempleQuery,
  useEditTempleMutation,
  useGetTempleWithDeaconQuery,
  useGetAdminTableQuery,
  useActivateTempleMutation,
  useDeactivateTempleMutation,
  useGetRequestsCountMutation,
  useCreateEditRequestMutation,
  useApproveEditRequestMutation,
  useRejectEditRequestMutation,
} = templesApiSlice;
