import { AdminFilterRadioPopper } from '@/components/admin/panel/AdminFilterRadioPopper';
import { AdminPanelActiveType } from '@/types/filter/AdminPanelActiveType';
import { AdminPanelEntityType } from '@/types/filter/AdminPanelEntityType';
import { AdminPanelTableContentDTO } from '@/types/AdminPanelTableContentDTO';
import { Dot } from '@/components/common/Dot';
import { ErrorDTO } from '@/types/ErrorDTO';
import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { formatCurrency, formatPhoneNumber } from '@/utils/string-utils';
import { getAdminTemplePage, getRegistrationRequestPage } from '@/app/routes';
import {
  useActivateTempleMutation,
  useDeactivateTempleMutation,
} from '@/services/api/templesApiSlice';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';

interface Props {
  items?: AdminPanelTableContentDTO[];
  filterType: AdminPanelEntityType;
  activeType: AdminPanelActiveType | null;
  setActiveType: (type: AdminPanelActiveType | null) => void;
}

export const PanelList: FC<Props> = (props: Props) => {
  const { items, filterType, activeType, setActiveType } = props;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [templeIds, setTempleIds] = useState<number[]>([]);
  const [activateTemple] = useActivateTempleMutation();
  const [deactivateTemple] = useDeactivateTempleMutation();
  const handleActivate = async (
    temple: AdminPanelTableContentDTO
  ): Promise<void> => {
    if (temple?.edited) {
      enqueueSnackbar(
        'Учреждение находится на редактирование, вынесите решение по заявке, прежде чем активировать учреждение.',
        {
          variant: 'error',
        }
      );
      return;
    }
    setTempleIds([...templeIds, temple.id]);
    await activateTemple({ id: temple.id })
      .unwrap()
      .catch((e: { status: number; data: ErrorDTO }) => {
        console.log(e);
        enqueueSnackbar(e.data.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setTempleIds((templeIds: number[]) => {
          return templeIds.filter((id: number) => id !== temple.id);
        });
      });
  };
  const handleDeactivate = async (
    temple: AdminPanelTableContentDTO
  ): Promise<void> => {
    setTempleIds([...templeIds, temple.id]);
    await deactivateTemple({ id: temple.id })
      .unwrap()
      .catch((e: { status: number; data: ErrorDTO }) => {
        enqueueSnackbar(e.data.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setTempleIds((templeIds: number[]) => {
          return templeIds.filter((id: number) => id !== temple.id);
        });
      });
  };
  const handleEditTemple = (temple: AdminPanelTableContentDTO) => {
    history.push(getAdminTemplePage(temple.id));
  };
  const handleEditRequest = (request: AdminPanelTableContentDTO) => {
    if (!request.active) {
      history.push(getRegistrationRequestPage(request.id));
    }
  };
  return (
    <TableContainer>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'white' }}>
            <TableCell
              align={'left'}
              sx={{
                borderBottomLeftRadius: '6px',
                borderTopLeftRadius: '6px',
                border: 'none',
                padding: '15px 30px',
              }}>
              <Typography
                color={(theme: Theme) => theme.colors.darkGrayText}
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'Церковь'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ border: 'none', padding: '15px' }}>
              <Typography
                color={(theme: Theme) => theme.colors.darkGrayText}
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'Администратор'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ border: 'none', padding: '15px' }}>
              <Typography
                color={(theme: Theme) => theme.colors.darkGrayText}
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'Телефон'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ border: 'none', padding: '15px' }}>
              <Typography
                color={(theme: Theme) => theme.colors.darkGrayText}
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'ИНН'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ border: 'none', padding: '15px' }}>
              <Typography
                color={(theme: Theme) => theme.colors.darkGrayText}
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'Сумма, руб.'}
              </Typography>
            </TableCell>
            <TableCell
              align={'left'}
              sx={{
                border: 'none',
                padding: '15px',
                cursor: 'pointer',
              }}>
              <Grid container={true} alignItems={'center'} spacing={2}>
                <Grid item={true}>
                  <AdminFilterRadioPopper
                    tittle={'Статус'}
                    activeType={activeType}
                    setActiveType={setActiveType}
                    buttons={
                      filterType === AdminPanelEntityType.REQUEST
                        ? [
                            {
                              label: 'Новая',
                              value: AdminPanelActiveType.REQUEST_NEW,
                            },
                            {
                              label: 'Зарегистрированная',
                              value: AdminPanelActiveType.REQUEST_ACCEPTED,
                            },
                          ]
                        : filterType === AdminPanelEntityType.TEMPLE
                        ? [
                            {
                              label: 'Активная',
                              value: AdminPanelActiveType.TEMPLE_ACTIVE,
                            },
                            {
                              label: 'Неактивная',
                              value: AdminPanelActiveType.TEMPLE_INACTIVE,
                            },
                          ]
                        : [
                            {
                              label: 'Активная',
                              value: AdminPanelActiveType.TEMPLE_ACTIVE,
                            },
                            {
                              label: 'Неактивная',
                              value: AdminPanelActiveType.TEMPLE_INACTIVE,
                            },
                            {
                              label: 'Новая',
                              value: AdminPanelActiveType.REQUEST_NEW,
                            },
                            {
                              label: 'Зарегистрированная',
                              value: AdminPanelActiveType.REQUEST_ACCEPTED,
                            },
                          ]
                    }
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell
              align={'right'}
              sx={{
                border: 'none',
                padding: '15px',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {items &&
            items.map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  onClick={() => {
                    item.type === AdminPanelEntityType.TEMPLE
                      ? handleEditTemple(item)
                      : handleEditRequest(item);
                  }}
                  align={'left'}
                  sx={{
                    padding: '15px',
                    borderBottomLeftRadius: '6px',
                    borderTopLeftRadius: '6px',
                    cursor:
                      item.type === AdminPanelEntityType.REQUEST && item.active
                        ? 'auto'
                        : 'pointer',
                  }}>
                  <Grid container={true} spacing={1} alignItems={'center'}>
                    <Grid item={true} xs={0.5}>
                      <Dot
                        color={
                          item.type === AdminPanelEntityType.TEMPLE
                            ? item.active
                              ? '#3e9ded'
                              : 'rgba(28, 34, 50, 0.5)'
                            : '#4fad2d'
                        }
                      />
                    </Grid>
                    <Grid item={true} xs={10.5}>
                      <Typography
                        fontSize={'14px'}
                        lineHeight={'18px'}
                        whiteSpace={'pre-line'}
                        sx={{
                          wordWrap: 'break-word',
                          wordBreak: 'break-all',
                        }}
                        color={(theme: Theme) =>
                          item.type === AdminPanelEntityType.TEMPLE &&
                          !item.active
                            ? theme.colors.grayText
                            : theme.colors.darkGrayText
                        }>
                        {item.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align={'left'} sx={{ padding: '15px' }}>
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    color={(theme: Theme) => theme.colors.grayText}>
                    {item.firstName +
                      (item.middleName ? ' ' + item.middleName : '') +
                      ' ' +
                      item.lastName}
                  </Typography>
                </TableCell>
                <TableCell align={'left'} sx={{ padding: '15px' }}>
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    color={(theme: Theme) => theme.colors.grayText}>
                    {formatPhoneNumber(item.phone)}
                  </Typography>
                </TableCell>
                <TableCell align={'left'} sx={{ padding: '15px' }}>
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    color={(theme: Theme) => theme.colors.grayText}>
                    {item.inn}
                  </Typography>
                </TableCell>
                <TableCell align={'left'} sx={{ padding: '15px' }}>
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    color={(theme: Theme) =>
                      item.active
                        ? theme.colors.darkGrayText
                        : theme.colors.grayText
                    }>
                    {item.type === AdminPanelEntityType.TEMPLE
                      ? formatCurrency(item.amount, 0, undefined, false)
                      : ''}
                  </Typography>
                </TableCell>
                <TableCell align={'left'} sx={{ padding: '15px' }}>
                  {item.type === AdminPanelEntityType.TEMPLE ? (
                    <Typography
                      fontSize={'14px'}
                      lineHeight={'18px'}
                      color={(theme: Theme) =>
                        item.active
                          ? theme.colors.primary
                          : theme.colors.grayText
                      }>
                      {item.active ? 'Активна' : 'Не активна'}
                    </Typography>
                  ) : (
                    <Typography
                      fontSize={'14px'}
                      lineHeight={'18px'}
                      color={(theme: Theme) => theme.colors.darkGrayText}>
                      {item.active
                        ? 'Зарегистрированная'
                        : item.edited
                        ? 'Редактирование'
                        : 'Новая'}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align={'right'} sx={{ padding: '0px 15px' }}>
                  {item.type === AdminPanelEntityType.TEMPLE && (
                    <Switch
                      disabled={templeIds.includes(item.id)}
                      checked={item.active}
                      sx={{ margin: '0px' }}
                      onClick={() => {
                        item.active
                          ? handleDeactivate(item)
                          : handleActivate(item);
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
