import * as Yup from 'yup';
import {
  MESSAGE_INVALID_PHONE,
  MESSAGE_REQUIRED,
} from '@/utils/validation-utils';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const phoneSchema: Yup.StringSchema = Yup.string()
  .required(MESSAGE_REQUIRED)
  .test('phoneNumber', MESSAGE_INVALID_PHONE, (value) => {
    try {
      return value ? isValidPhoneNumber(value, 'RU') : false;
    } catch (e) {
      return false;
    }
  });
