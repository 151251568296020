import { Box, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { format } from 'date-fns';
import { formatCurrency, formatDonationName } from '@/utils/string-utils';
import { ru } from 'date-fns/locale';
import React, { FC } from 'react';

interface Props {
  donation: any;
  isMobile?: boolean;
}

export const LastDonationItem: FC<Props> = (props: Props) => {
  const { donation, isMobile } = props;

  return (
    <Box
      sx={{
        borderRadius: '16px',
        backgroundColor: (theme: Theme) => theme.colors.grayBackground,
        padding: '24px 28px 28px 28px',
      }}>
      <Grid container={true} direction={'column'}>
        <Grid item={true} style={{ maxWidth: '100%' }}>
          <Grid
            container={true}
            alignItems={'center'}
            justifyContent={'space-between'}>
            <Grid item={true}>
              <Typography
                fontSize={'24px'}
                fontWeight={500}
                lineHeight={'28px'}
                color={'primary'}>
                {formatCurrency(donation.amount, 0)}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                variant={'body3'}
                color={(theme: Theme) => theme.colors.grayText}>
                {format(donation.date, 'dd MMMM yyyy', {
                  locale: ru,
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item={true}
          minHeight={'48px'}
          mt={'12px'}
          style={{ maxWidth: '100%' }}>
          <Typography
            variant={'body1'}
            display={'inline'}
            whiteSpace={'pre-line'}
            sx={{
              display: '-webkit-box',
              lineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              boxOrient: 'vertical',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              wordWrap: 'break-word',
              maxWidth: '100%',
            }}>
            <Typography display={'inline'} component={'span'} fontWeight={500}>
              {donation.isAnonymous || !donation.name
                ? 'Анонимно'
                : formatDonationName(donation.name)}
            </Typography>
            {' для ' + donation.templeName}
          </Typography>
        </Grid>
        <Grid
          item={true}
          mt={'12px'}
          minHeight={'48px'}
          style={{ maxWidth: '100%' }}>
          <Typography
            variant={'body1'}
            display={'inline'}
            whiteSpace={'pre-line'}
            sx={{
              display: 'block',
              lineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              boxOrient: 'vertical',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              wordWrap: 'break-word',
              maxWidth: '100%',
            }}>
            {donation.comment}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
