import { Box } from '@mui/material';
import React, { FC } from 'react';

export const MobileWidthContainer: FC = (props) => {
  const { children } = props;
  return (
    <Box
      sx={{
        position: 'relative',
        width: 'calc(100% + 32px)',
        height: '100%',
        marginLeft: '-16px',
      }}>
      {children}
    </Box>
  );
};
