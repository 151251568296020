import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { ROUTE_REGISTRATION_RESTORE_SUCCESS } from '@/app/routes';
import { RestorePasswordForm } from '@/components/registration/RestorePasswordForm';
import { RestorePasswordFormValues } from '@/types/form/RestorePasswordFormValues';
import { useHistory } from 'react-router-dom';
import { useRestorePasswordMutation } from '@/services/api/authApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';

export const RegistrationRestorePage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [restorePassword, { isLoading: isLoading }] =
    useRestorePasswordMutation();

  const handleSubmit = async (
    values: RestorePasswordFormValues
  ): Promise<void> => {
    await restorePassword(values.email)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Запрос отправлен!', {
          variant: 'success',
        });
        history.push(ROUTE_REGISTRATION_RESTORE_SUCCESS);
      })
      .catch((e: { status: number; data: ErrorDTO }) => {
        enqueueSnackbar(e.data.message, {
          variant: 'error',
        });
      });
  };

  return (
    <Grid container={true} columns={16}>
      <Grid item={true} md={9} sm={14} xs={16}>
        <Grid container={true} direction={'column'} wrap={'nowrap'}>
          <Grid item={true} mb={1.25} mt={isMobile ? 2 : 0}>
            <Typography variant={'h2'} lineHeight={'30px'}>
              {'Восстановление пароля'}
            </Typography>
          </Grid>
          <Grid item={true} mb={isMobile ? 2 : 5.5}>
            <Typography variant={'body2'} lineHeight={'18px'} pb={'18px'}>
              {'Введите адрес электронной почты указанный при регистрации'}
            </Typography>
          </Grid>
          <Grid item={true}>
            <RestorePasswordForm
              handleSubmit={handleSubmit}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
