import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const OutlineWarnIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    fill={'none'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={'M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z'}
    />
  </SvgIcon>
);
