import { AdminRejectForm } from '@/components/admin/AdminRejectForm';
import { ApproveRequestDTO } from '@/types/ApproveRequestDTO';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { ErrorDTO } from '@/types/ErrorDTO';
import { ROUTE_ADMIN_PANEL } from '@/app/routes';
import { TempleViewDTO } from '@/types/TempleViewDTO';
import { useApproveRegistrationRequestMutation } from '@/services/api/requestApiSlice';
import { useHistory } from 'react-router-dom';
import { useRejectEditRequestMutation } from '@/services/api/templesApiSlice';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  temple: TempleViewDTO;
  setOpenPreview?: (isOpenPreview: boolean) => void;
}

export const AdminRejectView: FC<Props> = (props: Props) => {
  const { open, setOpen, temple, setOpenPreview } = props;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const handleClose = () => {
    if (setOpenPreview) {
      setOpenPreview(true);
    }
    setOpen(false);
  };

  const [rejectRegistrationRequest, { isLoading: isRegistrationLoading }] =
    useApproveRegistrationRequestMutation();

  const [rejectEditRequest, { isLoading: isEditLoading }] =
    useRejectEditRequestMutation();

  const handleSubmit = async (values: ApproveRequestDTO): Promise<void> => {
    if (temple?.edited) {
      await rejectEditRequest({
        idRequest: values.idRequest,
        isApprove: values.isApprove,
        rejectReasonId: values.rejectReasonId,
        comment: values.comment,
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar(`Заявка успешно отклонена!`, {
            variant: 'success',
          });
          history.push(ROUTE_ADMIN_PANEL);
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(e.data.message, {
            variant: 'error',
          });
        });
    } else {
      await rejectRegistrationRequest({
        idRequest: values.idRequest,
        isApprove: values.isApprove,
        rejectReasonId: values.rejectReasonId,
        comment: values.comment,
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar(`Заявка успешно отклонена!`, {
            variant: 'success',
          });
          history.push(ROUTE_ADMIN_PANEL);
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(e.data.message, {
            variant: 'error',
          });
        });
    }
  };
  return (
    <Dialog
      open={open}
      scroll={'body'}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: (theme: Theme) => theme.spacing(6, 8),
          overflowY: 'unset',
          width: (theme: Theme) => theme.spacing(75),
        },
      }}
      BackdropProps={{ sx: { backgroundColor: 'rgba(0,0,0,0.8)' } }}>
      <DialogTitle
        sx={(theme: Theme) => ({
          marginBottom: theme.spacing(3),
        })}>
        <Grid container={true} justifyContent={'space-between'} wrap={'nowrap'}>
          <Grid item={true}>
            <Typography variant={'h1'} lineHeight={'28px'}>
              {temple?.name}
            </Typography>
          </Grid>
          <Grid
            item={true}
            sx={(theme: Theme) => ({
              marginTop: theme.spacing(-10.5),
              marginRight: theme.spacing(-9.5),
            })}>
            <IconButton disableRipple={true} onClick={handleClose}>
              <CloseIcon sx={{ color: 'white', opacity: '0.5' }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <AdminRejectForm
          idRequest={temple.id}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          loading={isRegistrationLoading || isEditLoading}
        />
      </DialogContent>
    </Dialog>
  );
};
