import { Grid, Skeleton, Typography } from '@mui/material';
import { ROUTE_HOME } from '@/app/routes';
import { TempleMobileSwiper } from '@/components/common/swiper/TempleMobileSwiper';
import { TemplePrimaryImage } from '@/components/temples/TemplePrimaryImage';
import { TempleSecondaryImage } from '@/components/temples/TempleSecondaryImage';
import { TempleViewDTO } from '@/types/TempleViewDTO';
import { useHistory } from 'react-router-dom';
import React, { FC, Fragment, useEffect, useState } from 'react';

interface Props {
  temple: TempleViewDTO | undefined;
  isTempleLoading: boolean;
  isTempleFetching: boolean;
  dialog?: boolean;
  isMobile?: boolean;
}

export const TempleInfo: FC<Props> = (props: Props) => {
  const { temple, isTempleLoading, isTempleFetching, dialog, isMobile } = props;
  const history = useHistory();
  const [selectedTempleImage, setSelectedTempleImage] = useState<number>(0);
  useEffect(() => {
    if (!isTempleFetching && !isTempleLoading && !temple) {
      history.push(ROUTE_HOME);
    } else {
      temple?.attachFile.map((image, index) => {
        if (image.main) {
          setSelectedTempleImage(index);
        }
      });
    }
  }, [temple, isTempleLoading, isTempleFetching, history]);
  return (
    <Grid container={true} direction={'column'} spacing={2}>
      {!dialog && (
        <Grid item={true}>
          <Typography variant={'h1'} lineHeight={'28px'}>
            {temple?.name}
          </Typography>
        </Grid>
      )}
      {isMobile ? (
        <Grid item={true} style={{ maxWidth: '100%' }}>
          {isTempleFetching ? (
            <Skeleton
              width={'100%'}
              height={'250px'}
              variant={'rectangular'}
              sx={{ borderRadius: '6px' }}
            />
          ) : (
            <TempleMobileSwiper attachFiles={temple?.attachFile || []} />
          )}
        </Grid>
      ) : (
        <Fragment>
          <Grid item={true}>
            {isTempleFetching ? (
              <Skeleton
                width={'100%'}
                height={'400px'}
                variant={'rectangular'}
                sx={{ borderRadius: '6px' }}
              />
            ) : (
              <TemplePrimaryImage
                imageContent={
                  temple?.attachFile[selectedTempleImage]?.content || ''
                }
                images={temple?.attachFile}
                selectedImage={selectedTempleImage}
              />
            )}
          </Grid>
          <Grid item={true} container={true} spacing={3} mb={1}>
            {isTempleLoading ? (
              <Fragment>
                {Array.from(new Array(3)).map((index) => (
                  <Grid item={true} key={index}>
                    <Skeleton
                      width={'100%'}
                      height={'150px'}
                      variant={'rectangular'}
                      sx={{ borderRadius: '6px' }}
                    />
                  </Grid>
                ))}
              </Fragment>
            ) : (
              temple?.attachFile?.map((image, index) => (
                <Grid item={true} key={image.id}>
                  <TempleSecondaryImage
                    image={image}
                    selected={selectedTempleImage === index}
                    handleSelected={() => setSelectedTempleImage(index)}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Fragment>
      )}
      <Grid item={true} mt={isMobile ? 0 : 5}>
        <Typography
          fontSize={'18px'}
          lineHeight={'28px'}
          whiteSpace={'pre-line'}>
          {temple?.description}
        </Typography>
      </Grid>
      {!dialog && (
        <Grid item={true} mt={isMobile ? 0 : 5}>
          <Typography fontSize={'20px'} lineHeight={'28px'} fontWeight={500}>
            {temple?.address}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
