import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const VisaIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 46 15'}
    sx={{ width: '46px', height: '15px', display: 'flex' }}
    {...props}>
    <g
      style={{ mixBlendMode: 'luminosity' }}
      opacity={'0.5'}
      clipPath={'url(#clip0_1579_92237)'}>
      <path
        d={'M19.9334 14.7821H16.207L18.5377 0.261719H22.2639L19.9334 14.7821Z'}
        fill={'#00579f'}
      />
      <path
        d={
          'M33.4416 0.618191C32.7066 0.324391 31.5409 0 30.0994 0C26.4195 0 23.8282 1.97714 23.8123 4.80386C23.7818 6.88941 25.6676 8.04777 27.0781 8.74316C28.5198 9.45374 29.0099 9.91754 29.0099 10.5509C28.9952 11.5237 27.8449 11.9721 26.7721 11.9721C25.2843 11.9721 24.4872 11.7409 23.2758 11.1997L22.7851 10.9677L22.2637 14.2272C23.1376 14.6284 24.7478 14.9844 26.4195 15C30.3295 15 32.8749 13.0535 32.9051 10.0411C32.92 8.38818 31.9242 7.12161 29.7772 6.08664C28.4739 5.42226 27.6758 4.97427 27.6758 4.29449C27.6911 3.6765 28.3509 3.04353 29.8221 3.04353C31.0335 3.01252 31.9235 3.30591 32.5978 3.59951L32.9349 3.7537L33.4416 0.618191Z'
        }
        fill={'#00579f'}
      />
      <path
        d={
          'M38.3941 9.63806C38.701 8.80388 39.8819 5.57537 39.8819 5.57537C39.8664 5.60637 40.1881 4.72579 40.3721 4.18521L40.6325 5.43637C40.6325 5.43637 41.3382 8.91208 41.4914 9.63806C40.9091 9.63806 39.1302 9.63806 38.3941 9.63806ZM42.9938 0.261719H40.1115C39.2227 0.261719 38.5474 0.524106 38.1639 1.46648L32.6289 14.7819H36.5388C36.5388 14.7819 37.1825 12.9898 37.3209 12.6038C37.7498 12.6038 41.5534 12.6038 42.1052 12.6038C42.2122 13.1136 42.5498 14.7819 42.5498 14.7819H46L42.9938 0.261719Z'
        }
        fill={'#00579f'}
      />
      <path
        d={
          'M13.0945 0.261719L9.4452 10.1632L9.04642 8.1551C8.37174 5.83796 6.25581 3.32044 3.89453 2.06866L7.23716 14.7667H11.1776L17.0348 0.261719H13.0945Z'
        }
        fill={'#00579f'}
      />
      <path
        d={
          'M6.05662 0.261719H0.0613342L0 0.555108C4.6767 1.76008 7.77399 4.66461 9.04652 8.15571L7.74322 1.48229C7.52865 0.554902 6.86925 0.29231 6.05662 0.261719Z'
        }
        fill={'#faa61a'}
      />
    </g>
    <defs>
      <clipPath id={'clip0_1579_92237'}>
        <rect width={'46'} height={'15'} fill={'white'} />
      </clipPath>
    </defs>
  </SvgIcon>
);
