import { BackIcon } from '@/app/icons/BackIcon';
import { ButtonLink, ButtonLinkProps } from '@/components/common/ButtonLink';
import { Grid } from '@mui/material';
import React, { FC } from 'react';

type Props = ButtonLinkProps;

const NavigatePreviousStep: FC<Props> = (props: Props) => {
  const { to, children, ...other } = props;
  return (
    <Grid container={true} sx={{ position: 'relative' }}>
      <ButtonLink
        to={to}
        color={'primary'}
        fullWidth={false}
        disableRipple={true}
        sx={{
          padding: '12px',
          margin: '-12px',
          position: 'absolute',
          marginTop: '-36px',
          '&:hover': {
            background: 'none',
          },
        }}
        size={'small'}
        {...other}>
        <Grid
          container={true}
          direction={'row'}
          alignItems={'end'}
          spacing={0.625}>
          <Grid item={true} display={'flex'}>
            <BackIcon
              sx={{ width: '16px', height: '20px', marginBottom: '1px' }}
            />
          </Grid>
          <Grid item={true}>{children}</Grid>
        </Grid>
      </ButtonLink>
    </Grid>
  );
};

export default NavigatePreviousStep;
