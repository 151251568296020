import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const AdminPanelIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 14 14'}
    sx={{ width: '14px', height: '14px' }}
    {...props}>
    <path
      d={
        'M6.14397 0.429688H0.715402C0.558259 0.429688 0.429688 0.558259 0.429688 0.715402V6.14397C0.429688 6.30112 0.558259 6.42969 0.715402 6.42969H6.14397C6.30112 6.42969 6.42969 6.30112 6.42969 6.14397V0.715402C6.42969 0.558259 6.30112 0.429688 6.14397 0.429688ZM5.2154 5.2154H1.64397V1.64397H5.2154V5.2154ZM13.2868 0.429688H7.85826C7.70112 0.429688 7.57255 0.558259 7.57255 0.715402V6.14397C7.57255 6.30112 7.70112 6.42969 7.85826 6.42969H13.2868C13.444 6.42969 13.5725 6.30112 13.5725 6.14397V0.715402C13.5725 0.558259 13.444 0.429688 13.2868 0.429688ZM12.3583 5.2154H8.78683V1.64397H12.3583V5.2154ZM6.14397 7.57255H0.715402C0.558259 7.57255 0.429688 7.70112 0.429688 7.85826V13.2868C0.429688 13.444 0.558259 13.5725 0.715402 13.5725H6.14397C6.30112 13.5725 6.42969 13.444 6.42969 13.2868V7.85826C6.42969 7.70112 6.30112 7.57255 6.14397 7.57255ZM5.2154 12.3583H1.64397V8.78683H5.2154V12.3583ZM13.2868 7.57255H7.85826C7.70112 7.57255 7.57255 7.70112 7.57255 7.85826V13.2868C7.57255 13.444 7.70112 13.5725 7.85826 13.5725H13.2868C13.444 13.5725 13.5725 13.444 13.5725 13.2868V7.85826C13.5725 7.70112 13.444 7.57255 13.2868 7.57255ZM12.3583 12.3583H8.78683V8.78683H12.3583V12.3583Z'
      }
      fill={'#797A7B'}
    />
  </SvgIcon>
);
