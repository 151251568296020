import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const InfoIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12ZM11.5 7C11.2239 7 11 7.22386 11 7.5V8.5C11 8.77614 11.2239 9 11.5 9H12.5C12.7761 9 13 8.77614 13 8.5V7.5C13 7.22386 12.7761 7 12.5 7H11.5Z'
      }
    />
  </SvgIcon>
);
