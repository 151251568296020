import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  color: string;
}

export const Dot: FC<Props> = (props: Props) => {
  const { color, ...other } = props;
  return (
    <Box
      sx={{
        width: (theme: Theme) => theme.spacing(1),
        height: (theme: Theme) => theme.spacing(1),
        backgroundColor: color ? color : 'primary',
        borderRadius: '50%',
      }}
      {...other}
    />
  );
};
