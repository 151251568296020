import { Grid, Skeleton, Typography } from '@mui/material';
import { LastDonationItem } from '@/components/donations/LastDonationItem';
import { MobileWidthContainer } from '@/components/common/MobileWidthContainer';
import { useGetLastDonationsQuery } from '@/services/api/donationsApiSlice';
import React, { FC, Fragment } from 'react';

interface Props {
  isMobile?: boolean;
}

export const LastDonations: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const { data: donations, isFetching } = useGetLastDonationsQuery();

  return (
    <Fragment>
      <Grid container={true}>
        <Grid item={true}>
          <Typography variant={'h1'} lineHeight={'30px'}>
            {'Последние пожертвования'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} spacing={3} mt={isMobile ? 0 : 4}>
        {isFetching ? (
          <Fragment>
            {Array.from(new Array(4)).map((index) => (
              <Grid item={true} lg={6} xs={12} key={index}>
                <Skeleton width={'100%'} height={'200px'} />
              </Grid>
            ))}
          </Fragment>
        ) : (
          donations?.map((donation) => (
            <Grid item={true} lg={6} xs={12} key={donation.id}>
              {isMobile ? (
                <MobileWidthContainer>
                  <LastDonationItem donation={donation} />
                </MobileWidthContainer>
              ) : (
                <LastDonationItem donation={donation} />
              )}
            </Grid>
          ))
        )}
      </Grid>
    </Fragment>
  );
};
