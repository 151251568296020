import { Collapse, Divider, Grid, Theme, Typography } from '@mui/material';
import { DownIcon } from '@/app/icons/DownIcon';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

interface Props {
  tittle?: string;
  hide?: boolean;
}

const CollapseHelpText: FC<Props> = (props: PropsWithChildren<Props>) => {
  const { tittle, hide, children } = props;
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (hide != undefined) {
      setOpen(!hide);
    }
  }, [hide]);
  return (
    <Grid container={true} direction={'column'} sx={{ marginBottom: '8px' }}>
      <Grid item={true} sx={{ cursor: 'pointer' }}>
        <Grid
          container={true}
          direction={'column'}
          onClick={() => setOpen(!open)}>
          <Grid item={true} sx={{ padding: '12px 0px' }}>
            <Grid
              container={true}
              justifyContent={'space-between'}
              wrap={'nowrap'}>
              <Grid item={true}>
                <Typography variant={'h4'}>{tittle}</Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true}>
                  <DownIcon
                    sx={{
                      color: (theme: Theme) => theme.colors.primary,
                      transform: open ? 'rotate(180deg)' : '',
                      transition: 'transform 0.3s',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!open && (
            <Grid item={true}>
              <Divider />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Collapse in={open}>
        <Grid item={true} sx={{ padding: '8px 0px 16px' }}>
          {children}
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default CollapseHelpText;
