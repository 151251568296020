import { AdminApproveRequestPage } from '@/components/admin/AdminApproveRequestPage';
import { AdminEditTemplePage } from '@/components/admin/AdminEditTemplePage';
import { AdminMenu } from '@/components/admin/AdminMenu';
import { AdminPaymentsPage } from '@/components/admin/payments/AdminPaymentsPage';
import { AdminRegistrationTemplePage } from '@/components/admin/AdminRegistrationTemplePage';
import { Box, Grid, Theme } from '@mui/material';
import { PanelPage } from '@/components/admin/panel/PanelPage';
import { PrivateRoute } from '@/components/auth/PrivateRoute';
import { ROLE_ADMIN } from '@/app/roles';
import {
  ROUTE_ADMIN_ADD_TEMPLE,
  ROUTE_ADMIN_EDIT_TEMPLE,
  ROUTE_ADMIN_FEEDBACK,
  ROUTE_ADMIN_PANEL,
  ROUTE_ADMIN_PAYMENTS,
  ROUTE_ADMIN_REGISTRATION_REQUEST,
} from '@/app/routes';
import { Redirect, Switch } from 'react-router-dom';
import React, { FC, Fragment } from 'react';

export const Admin: FC = () => {
  return (
    <Fragment>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: (theme: Theme) => theme.colors.grayBackground,
          zIndex: -1,
        }}
      />
      <Box
        display={'flex'}
        flex={1}
        sx={{
          display: 'flex',
          minHeight: '100%',
          minWidth: '100%',
          margin: '0px',
          padding: '0px',
        }}>
        <Grid container={true} flex={1} wrap={'nowrap'}>
          <Grid
            flex={1}
            item={true}
            sx={{
              padding: '32px 24px',
              backgroundColor: (theme: Theme) => theme.colors.white,
            }}
            maxWidth={'max-content'}>
            <AdminMenu />
          </Grid>
          <Grid
            item={true}
            flex={1}
            sx={{
              backgroundColor: (theme: Theme) => theme.colors.grayBackground,
              padding: '32px',
            }}
            width={'100%'}>
            <Switch>
              <PrivateRoute
                exact={true}
                path={ROUTE_ADMIN_PANEL}
                role={ROLE_ADMIN}>
                <PanelPage />
              </PrivateRoute>
              <PrivateRoute
                exact={true}
                path={ROUTE_ADMIN_PAYMENTS}
                role={ROLE_ADMIN}>
                <AdminPaymentsPage />
              </PrivateRoute>
              <PrivateRoute
                exact={true}
                path={ROUTE_ADMIN_FEEDBACK}
                role={ROLE_ADMIN}>
                {'feedback'}
              </PrivateRoute>
              <PrivateRoute
                exact={true}
                path={ROUTE_ADMIN_ADD_TEMPLE}
                role={ROLE_ADMIN}>
                <AdminRegistrationTemplePage />
              </PrivateRoute>
              <PrivateRoute
                exact={true}
                path={ROUTE_ADMIN_EDIT_TEMPLE}
                role={ROLE_ADMIN}>
                <AdminEditTemplePage />
              </PrivateRoute>
              <PrivateRoute
                exact={true}
                path={ROUTE_ADMIN_REGISTRATION_REQUEST}
                role={ROLE_ADMIN}>
                <AdminApproveRequestPage />
              </PrivateRoute>
              <Redirect to={ROUTE_ADMIN_PANEL} />
            </Switch>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
