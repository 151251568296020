import { EditTempleDTO } from '@/types/EditTempleDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, Typography } from '@mui/material';
import { ROUTE_ADMIN_PANEL } from '@/app/routes';
import { TempleForm } from '@/components/common/TempleForm';
import { TempleFormValues } from '@/types/form/TempleFormValues';
import { fillDtoFromRegistrationForm } from '@/utils/request-utils';
import { getCurrentCreateTempleForm } from '@/services/templesSlice';
import { useAppSelector } from '@/app/hooks';
import { useCreateTempleAndUserMutation } from '@/services/api/requestApiSlice';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';

export const AdminRegistrationTemplePage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [createRequest, { isLoading: isLoading }] =
    useCreateTempleAndUserMutation();
  const handleSubmit = async (values: TempleFormValues): Promise<void> => {
    const requestDto: EditTempleDTO = fillDtoFromRegistrationForm(values);
    await createRequest({ request: requestDto, images: values.attachFiles })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Церковь создана!', {
          variant: 'success',
        });
        history.push(ROUTE_ADMIN_PANEL);
      })
      .catch((e: { status: number; data: ErrorDTO }) => {
        enqueueSnackbar(e.data.message, {
          variant: 'error',
        });
      });
  };
  const initialCreateValues = useAppSelector(getCurrentCreateTempleForm);
  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true} pb={2}>
        <Typography variant={'h2'}>
          {'Подключение церкви к сервису пожертвования'}
        </Typography>
      </Grid>
      <Grid item={true} pb={4}>
        <Typography variant={'body2'}>
          {'Для создания церкви и пользователя необходимо заполнить все поля'}
        </Typography>
      </Grid>
      <Grid item={true}>
        <TempleForm
          initialFormValues={initialCreateValues}
          sendingData={isLoading}
          handleSubmit={handleSubmit}
          submitButtonText={'Подключить'}
          isAdminAddTemplePage={true}
          isAdminPage={true}
        />
      </Grid>
    </Grid>
  );
};
