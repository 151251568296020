import * as Yup from 'yup';
import {
  MESSAGE_INVALID_BANK_DETAILS,
  MESSAGE_REQUIRED,
} from '@/utils/validation-utils';
import { removeWhitespace } from '@/utils/string-utils';

declare module 'yup' {
  interface StringSchema {
    removeWhitespace(msg?: string): this;
  }
}

Yup.addMethod<Yup.StringSchema>(Yup.string, 'removeWhitespace', function () {
  return this.transform((value) => removeWhitespace(value));
});

export const bankDetailsSchema: Yup.StringSchema = Yup.string()
  .trim()
  .required(MESSAGE_REQUIRED)
  .matches(/^[\d ]+$/, MESSAGE_INVALID_BANK_DETAILS)
  .removeWhitespace();
