import { Grid, Typography } from '@mui/material';
import { getNumEnding } from '@/utils/string-utils';
import { useGetTemplesCountQuery } from '@/services/api/templesApiSlice';
import React, { FC } from 'react';

export const MainHeader: FC = () => {
  const { data: count } = useGetTemplesCountQuery({});
  return (
    <Grid container={true} direction={'column'} spacing={2.25}>
      <Grid item={true}>
        <Typography
          color={'white'}
          variant={'h1'}
          fontSize={'40px'}
          lineHeight={'48px'}
          whiteSpace={'pre-line'}
          textTransform={'uppercase'}
          maxWidth={'600px'}>
          {'Сервис пожертвований для евангелистских церквей'}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography
          color={'white'}
          fontSize={'16px'}
          lineHeight={'24px'}
          whiteSpace={'pre-line'}
          maxWidth={'500px'}>
          <span>{'Дорогие друзья, приветствуем вас!'}</span>
          <br />
          <span>
            {
              'Здесь вы можете поддержать служение одной или нескольких церквей, сделав добровольное пожертвование прямо сейчас!'
            }
          </span>
          <br />
          <span>
            {
              'Пожалуйста, выберите церковь из списка или воспользуйтесь фильтром для быстрого поиска.'
            }
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};
