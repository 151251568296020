import { CSSProperties } from '@mui/styles';
import React, { FC } from 'react';

interface Props {
  src?: string;
  alt?: string;
  style?: CSSProperties;
}

export const ImageContainer: FC<Props> = (props: Props) => {
  const { src, alt, style } = props;
  return (
    <img
      src={src}
      alt={alt || 'slide-image'}
      width={'100%'}
      height={'100%'}
      style={{ objectFit: 'cover', ...style }}
    />
  );
};
