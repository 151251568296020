import { ArrowRightIcon } from '@/app/icons/ArrowRightIcon';
import { Button, Grid, Typography } from '@mui/material';
import { CalendarIcon } from '@/app/icons/CalendarIcon';
import { DateRangePicker } from '@/components/common/date/DateRangePicker';
import { VerticalDividerIcon } from '@/app/icons/VerticalDividerIcon';
import { format } from 'date-fns';
import { getEndDateRange, getStartDateRange } from '@/utils/date-utils';
import React, { FC, Fragment, useState } from 'react';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  setDates: (dateStart: Date | null, dateEnd: Date | null) => void;
}

export const DateRangeButton: FC<Props> = (props: Props) => {
  const { startDate, endDate, setDates } = props;

  const active: boolean = !!startDate && !!endDate;
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = (): void => {
    setOpen(true);
  };
  const handleRange = (startDate: Date | null, endDate: Date | null): void => {
    if (!!startDate && !!endDate) {
      setDates(getStartDateRange(startDate), getEndDateRange(endDate));
    } else {
      setDates(null, null);
    }
    setOpen(false);
  };
  return (
    <Fragment>
      <Button
        variant={'outlined'}
        onClick={handleClick}
        size={'medium'}
        fullWidth={true}
        sx={{
          backgroundColor: 'white',
          paddingBottom: '5px',
          maxWidth: '270px',
          minWidth: '155px',
        }}>
        <Grid container={true} justifyContent={'flex-end'}>
          {active ? (
            <Grid item={true}>
              <Grid
                container={true}
                spacing={1}
                alignItems={'center'}
                alignContent={'center'}>
                <Grid item={true}>
                  <Grid
                    container={true}
                    spacing={1}
                    alignItems={'center'}
                    wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography
                        fontSize={'15px'}
                        lineHeight={'18px'}
                        fontWeight={400}
                        color={'black'}>
                        {startDate ? format(startDate, 'dd.MM.yyyy') : ''}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <ArrowRightIcon />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Grid
                    container={true}
                    spacing={1}
                    alignItems={'center'}
                    wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography
                        fontSize={'15px'}
                        lineHeight={'18px'}
                        fontWeight={400}
                        color={'black'}>
                        {endDate ? format(endDate, 'dd.MM.yyyy') : ''}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <VerticalDividerIcon />
                    </Grid>
                    <Grid item={true}>
                      <CalendarIcon />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item={true}>
              <CalendarIcon />
            </Grid>
          )}
        </Grid>
      </Button>
      <DateRangePicker open={open} onRange={handleRange} />
    </Fragment>
  );
};
