import { EditTempleDTO } from '@/types/EditTempleDTO';
import { RegistrationRequestDTO } from '@/types/RegistrationRequestDTO';
import { TempleFormValues } from '@/types/form/TempleFormValues';
import {
  getNewRegionNameFromLabel,
  removeExtraSpaces,
  removeWhitespace,
} from '@/utils/string-utils';

export const DA_DATA_URL =
  'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';

export const DA_DATA_TOKEN = '8a8b9d09d2e647fb335c0375c42e15784cdce699';

export const MOBILE_PAGE_SIZE = 4;

export const createRequest = (
  request: RegistrationRequestDTO,
  images: File[]
): FormData => {
  const formData: FormData = new FormData();
  formData.append(
    'registrationRequest',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    })
  );
  if (images.length > 0) {
    images.map((image) => {
      formData.append('images', image);
    });
  }
  return formData;
};

//TODO: обобщить методы
export const fillDtoFromRegistrationForm = (
  formValues: TempleFormValues,
  requestId?: number
): RegistrationRequestDTO => {
  return {
    id: requestId,
    name: removeExtraSpaces(formValues.name),
    address: removeExtraSpaces(formValues.address),
    description: formValues.description,
    inn: removeWhitespace(formValues.inn),
    kpp: removeWhitespace(formValues.kpp),
    oktmo: removeWhitespace(formValues.oktmo),
    bic: removeWhitespace(formValues.bic),
    bankAccount: removeWhitespace(formValues.bankAccount),
    bankName: removeExtraSpaces(formValues.bankName),
    firstName: removeExtraSpaces(formValues.firstName),
    lastName: removeExtraSpaces(formValues.lastName),
    middleName: removeExtraSpaces(formValues.middleName),
    phone: formValues.phone,
    email: removeExtraSpaces(formValues.email),
    regionId: formValues.region?.id,
    mainImageIndex: formValues.mainImageIndex,
    newRegionName: getNewRegionNameFromLabel(formValues.region?.newRegionName),
  };
};

export const fillDtoFromEditForm = (
  formValues: TempleFormValues,
  templeId?: number
): EditTempleDTO => {
  return {
    templeId: templeId,
    name: removeExtraSpaces(formValues.name),
    address: removeExtraSpaces(formValues.address),
    description: formValues.description,
    inn: removeWhitespace(formValues.inn),
    kpp: removeWhitespace(formValues.kpp),
    oktmo: removeWhitespace(formValues.oktmo),
    bic: removeWhitespace(formValues.bic),
    bankAccount: removeWhitespace(formValues.bankAccount),
    bankName: removeExtraSpaces(formValues.bankName),
    firstName: removeExtraSpaces(formValues.firstName),
    lastName: removeExtraSpaces(formValues.lastName),
    middleName: removeExtraSpaces(formValues.middleName),
    phone: formValues.phone,
    email: removeExtraSpaces(formValues.email),
    regionId: formValues.region?.id,
    mainImageIndex: formValues.mainImageIndex,
    newRegionName: getNewRegionNameFromLabel(formValues.region?.newRegionName),
  };
};
