import { Box } from '@mui/material';
import { Logo } from '@/app/icons/Logo';
import { NavigationDrawer } from '@/components/common/navigation/NavigationDrawer';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  hideMenu?: boolean;
}

export const MobileHeader: FC<Props> = (props: Props) => {
  const { hideMenu } = props;
  return (
    <Box
      display={'flex'}
      position={'relative'}
      alignItems={'center'}
      justifyContent={'center'}>
      {!hideMenu && <NavigationDrawer />}
      <Logo
        color={'primary'}
        sx={{
          width: (theme: Theme) => theme.spacing(7.646484375),
          height: (theme: Theme) => theme.spacing(5.625),
        }}
      />
    </Box>
  );
};
