import { Grid, Skeleton } from '@mui/material';
import { useGetConnectContentQuery } from '@/services/api/helpApiSlice';
import React, { FC } from 'react';

export const ConnectPage: FC = () => {
  const { data: data, isFetching: isFetching } = useGetConnectContentQuery();
  return (
    <Grid
      container={true}
      direction={'column'}
      wrap={'nowrap'}
      marginBottom={6}>
      {isFetching ? (
        <Grid item={true}>
          <Skeleton
            width={'100%'}
            height={'132px'}
            variant={'rectangular'}
            sx={{ borderRadius: '6px' }}
          />
        </Grid>
      ) : (
        data && (
          <Grid item={true}>
            <div
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            />
          </Grid>
        )
      )}
    </Grid>
  );
};
