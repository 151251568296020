import { Box, Theme, useMediaQuery } from '@mui/material';
import { InformationTemplate } from '@/components/common/InformationTemplate';
import React, { FC } from 'react';

export const RegistrationRestoreSuccessPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  return (
    <Box mt={isMobile ? 4 : 0}>
      <InformationTemplate
        title={'Письмо отправлена на почту!'}
        content={
          'На электронный адрес ' +
          'указанный при регистрации было ' +
          'направлено письмо с инструкцией ' +
          'по восстановлению пароля.'
        }
        isMobile={isMobile}
      />
    </Box>
  );
};
