import { AttachFileDTO } from '@/types/AttachFileDTO';
import { Buffer } from 'buffer';
import { getFileMEME } from '@/utils/string-utils';

export const convertAttachToFile = (attachFile: AttachFileDTO): File => {
  const dataurl: string = attachFile.content || '';
  const mime = getFileMEME(attachFile.name);
  const bstr = atob(dataurl);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], attachFile.name || 'image.jpeg', { type: mime });
};

export const convertFileToAttach = (
  file: File,
  buffer: ArrayBuffer
): AttachFileDTO => {
  return { content: Buffer.from(buffer).toString('base64'), name: file.name };
};

export const getFileArray = (attachFiles: AttachFileDTO[]): File[] => {
  return attachFiles.map((attach) => convertAttachToFile(attach));
};

export const convertFileToPromiseAttach = (
  files: File[]
): Promise<AttachFileDTO>[] => {
  return files.map((file) =>
    file.arrayBuffer().then((buffer) => {
      return convertFileToAttach(file, buffer);
    })
  );
};
