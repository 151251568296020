import { Button, Grid, Typography } from '@mui/material';
import { FeedbackIcon } from '@/app/icons/auth/FeedbackIcon';
import { InfoItem } from '@/components/auth/InfoItem';
import { OnlinePaymentIcon } from '@/app/icons/auth/OnlinePaymentIcon';
import { PaymentRegistryIcon } from '@/app/icons/auth/PaymentRegistryIcon';
import { ROUTE_REGISTRATION } from '@/app/routes';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

export const RegistrationInfo: FC = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push(ROUTE_REGISTRATION);
  };

  return (
    <Grid
      container={true}
      direction={'column'}
      spacing={3}
      alignItems={{ md: 'flex-start', xs: 'center' }}>
      <Grid item={true}>
        <Typography
          variant={'h1'}
          lineHeight={'30px'}
          whiteSpace={'pre-line'}
          maxWidth={{ md: '350px', xs: '500px' }}>
          {'Подключение к сервису пожертвований'}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'h5'} fontWeight={400} whiteSpace={'pre-line'}>
          {
            'Сервис позволит вашей церкви получать электронные\nпожертвования. Подробнее о работе сервиса в разделе\n'
          }
        </Typography>
        <Typography variant={'h5'} whiteSpace={'pre-line'}>
          {'«О портале»'}
        </Typography>
      </Grid>
      <Grid
        item={true}
        container={true}
        spacing={6}
        direction={'row'}
        justifyContent={{ md: 'flex-start', xs: 'center' }}
        alignItems={'end'}>
        <Grid item={true}>
          <InfoItem label={'Электронные\nплатежи'}>
            <OnlinePaymentIcon />
          </InfoItem>
        </Grid>
        <Grid item={true}>
          <InfoItem label={'Реестр\nоплат'}>
            <PaymentRegistryIcon />
          </InfoItem>
        </Grid>
        <Grid item={true}>
          <InfoItem label={'Обратная\nсвязь'}>
            <FeedbackIcon />
          </InfoItem>
        </Grid>
      </Grid>
      <Grid item={true} mt={4} width={'60%'}>
        <Button
          variant={'contained'}
          color={'primary'}
          fullWidth={true}
          onClick={handleClick}>
          {'Подключиться'}
        </Button>
      </Grid>
    </Grid>
  );
};
