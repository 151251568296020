import { ConnectPage } from '@/components/help/ConnectPage';
import { FaqBlock } from '@/components/help/FaqBlock';
import { FeedbackBlock } from '@/components/help/FeedbackBlock';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import {
  ROUTE_HELP_CONNECT,
  ROUTE_HELP_FAQ,
  ROUTE_HELP_FEEDBACK,
  ROUTE_HELP_WORK,
} from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { WorkBlock } from '@/components/help/WorkBlock';
import HelpMenu from '@/components/help/HelpMenu';
import React, { FC } from 'react';

export const HelpPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  return (
    <Grid container={true} direction={'column'} mt={'20px'}>
      <Grid item={true}>
        <Typography variant={'h1'} lineHeight={'30px'}>
          {'Помощь'}
        </Typography>
      </Grid>
      <Grid item={true} mt={isMobile ? '16px' : '50px'}>
        <Grid container={true} columnSpacing={isMobile ? 0 : 7}>
          {!isMobile && (
            <Grid item={true} xs={3}>
              <HelpMenu />
            </Grid>
          )}
          <Grid item={true} xs={isMobile ? 12 : 9}>
            <Switch>
              <Route path={ROUTE_HELP_WORK}>
                <WorkBlock />
              </Route>
              <Route path={ROUTE_HELP_FAQ}>
                <FaqBlock />
              </Route>
              <Route path={ROUTE_HELP_CONNECT}>
                <ConnectPage />
              </Route>
              <Route exact={true} path={ROUTE_HELP_FEEDBACK}>
                <FeedbackBlock isMobile={isMobile} />
              </Route>
              <Redirect to={ROUTE_HELP_WORK} />
            </Switch>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
