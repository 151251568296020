import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const CheckboxIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={'M1 6C1 3.23858 3.23858 1 6 1H23V18C23 20.7614 20.7614 23 18 23H1V6Z'}
      fill={'white'}
      stroke={'#9B51E0'}
      strokeWidth={'2'}
    />
  </SvgIcon>
);
