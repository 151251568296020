import { MobileDatePicker, PickersDay, PickersDayProps } from '@mui/lab';
import { TextField, TextFieldProps } from '@mui/material';
import {
  endOfWeek,
  isAfter,
  isSameDay,
  isWithinInterval,
  startOfWeek,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { styled } from '@mui/material/styles';
import React, { FC, ReactElement, useState } from 'react';

interface Props {
  open: boolean;
  onRange: (startDate: Date | null, endDate: Date | null) => void;
}

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...{ width: '40px' },
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>;

export const DateRangePicker: FC<Props> = (props: Props) => {
  const { open, onRange } = props;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isStartDateSelected, setStartDateSelected] = useState<boolean>(false);
  const handleChange = (date: Date | null): void => {
    if (!!startDate && isStartDateSelected) {
      if (!!date && isAfter(startDate, date)) {
        setEndDate(startDate);
        setStartDate(date);
      } else {
        setEndDate(date);
      }
      setStartDateSelected(false);
    } else {
      if (!!date && !!endDate && isAfter(date, endDate)) {
        setStartDate(endDate);
        setEndDate(date);
      } else {
        setStartDate(date);
      }
      setStartDateSelected(true);
    }
  };
  const reset = (): void => {
    setStartDate(null);
    setEndDate(null);
    setStartDateSelected(false);
  };
  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    if (!startDate || !endDate) {
      return (
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin={true}
          dayIsBetween={false}
          isFirstDay={false}
          isLastDay={false}
          allowSameDateSelection={true}
          showDaysOutsideCurrentMonth={true}
        />
      );
    }
    const dayIsBetween: boolean =
      isSameDay(date, startDate) ||
      isSameDay(date, endDate) ||
      isWithinInterval(date, {
        start: startDate,
        end: endDate,
      });
    const isFirstDay: boolean =
      isSameDay(date, startDate) ||
      isSameDay(date, startOfWeek(date, { locale: ru }));
    const isLastDay: boolean =
      isSameDay(date, endDate) ||
      isSameDay(date, endOfWeek(date, { locale: ru }));
    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin={true}
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        allowSameDateSelection={true}
        showDaysOutsideCurrentMonth={true}
      />
    );
  };
  return (
    <MobileDatePicker
      open={open}
      views={['day']}
      showToolbar={false}
      allowSameDateSelection={true}
      okText={'Принять'}
      cancelText={'Отмена'}
      value={isStartDateSelected ? startDate : endDate}
      minDate={isStartDateSelected && startDate ? startDate : undefined}
      maxDate={!isStartDateSelected && endDate ? endDate : undefined}
      renderInput={(props: TextFieldProps) => (
        <TextField
          {...props}
          sx={{ position: 'absolute', visibility: 'hidden' }}
        />
      )}
      renderDay={renderWeekPickerDay}
      onChange={handleChange}
      onAccept={() => {
        onRange(startDate, endDate);
        reset();
      }}
      onClose={() => {
        reset();
      }}
    />
  );
};
