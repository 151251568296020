import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { CloseTutorialModalIcon } from '@/app/icons/CloseTutorialModalIcon';
import { ROUTE_HOME } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import Image1 from '@assets/images/tutorial/1.png';
import Image2 from '@assets/images/tutorial/2.png';
import Image3 from '@assets/images/tutorial/3.png';
import React, { FC, useState } from 'react';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const TutorialDialog: FC<Props> = (props: Props) => {
  const { open, setOpen } = props;
  const history = useHistory();
  const [counter, setCounter] = useState<number>(1);

  const handleClose = () => {
    setOpen(false);
    history.replace(ROUTE_HOME);
  };

  const handleClickForward = () => {
    if (counter < 3) {
      setCounter(counter + 1);
    } else {
      handleClose();
    }
  };

  const handleGetImage = () => {
    switch (counter) {
      case 1:
        return Image1;
      case 2:
        return Image2;
      case 3:
        return Image3;
    }
  };

  const handleGetHeader = () => {
    switch (counter) {
      case 1:
        return 'Вы зарегистрированы!';
      case 2:
        return 'Профиль';
      case 3:
        return 'Реестр платажей';
    }
  };

  const handleGetText = () => {
    switch (counter) {
      case 1:
        return 'Теперь вам доступны новые пункты меню, через них вы\nможете редактировать общедоступный профиль\n церкви и смотреть реестр платежей.';
      case 2:
        return 'В профиле церкви можно изменить описание, добавить\nдополнительные фотографии и сменить пароль.';
      case 3:
        return 'В реестре платежей можно посмотреть список\nжертвователей и комментариев.';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflowY: 'unset',
          backgroundColor: (theme: Theme) => theme.colors.primary,
          color: (theme: Theme) => theme.colors.white,
        },
      }}>
      <DialogTitle sx={{ marginTop: '19px', marginRight: '19px' }}>
        <Grid container={true} justifyContent={'flex-end'} wrap={'nowrap'}>
          <Grid item={true}>
            <Button
              onClick={handleClose}
              sx={{
                margin: 0,
                padding: 0,
                maxWidth: '19px',
                minWidth: '19px',
              }}>
              <CloseTutorialModalIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ margin: '-14px 24px 40px' }}>
        <Grid container={true} direction={'column'}>
          <Grid item={true}>
            <Typography fontSize={'24px'} fontWeight={500} lineHeight={'32px'}>
              {handleGetHeader()}
            </Typography>
          </Grid>
          <Grid item={true} mt={2.25}>
            <img
              src={handleGetImage()}
              alt={'slide-image'}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'contain' }}
            />
          </Grid>
          <Grid item={true} mt={2.25}>
            <Typography
              fontSize={'16px'}
              lineHeight={'24px'}
              whiteSpace={'pre-line'}>
              {handleGetText()}
            </Typography>
          </Grid>
          <Grid item={true} mt={4}>
            <Grid container={true} justifyContent={'space-between'}>
              <Grid item={true}>
                <Typography fontSize={'16px'} lineHeight={'24px'}>
                  {`${counter}/3`}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Button
                  sx={{
                    padding: '0px',
                    color: (theme: Theme) => theme.colors.white,
                  }}
                  onClick={handleClickForward}>
                  <Typography fontSize={'16px'} lineHeight={'24px'}>
                    {'Далее'}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
