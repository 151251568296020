import { AdminDonationComment } from '@/app/icons/admin/AdminDonationComment';
import { DonationDTO } from '@/types/DonationDTO';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import {
  formatCurrency,
  formatStatus,
  getStatusColor,
} from '@/utils/string-utils';
import React, { FC } from 'react';

export interface Props {
  donations?: DonationDTO[];
}

export const AdminPaymentsList: FC<Props> = (props: Props) => {
  const { donations } = props;

  return (
    <TableContainer>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'white' }}>
            <TableCell
              align={'left'}
              sx={{
                borderBottomLeftRadius: '6px',
                borderTopLeftRadius: '6px',
                border: 'none',
                padding: '15px 30px',
              }}>
              <Typography
                color={(theme: Theme) => theme.colors.darkGrayText}
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'Дата'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ border: 'none', padding: '15px' }}>
              <Typography
                color={(theme: Theme) => theme.colors.darkGrayText}
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'Церковь'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ border: 'none', padding: '15px' }}>
              <Typography
                color={(theme: Theme) => theme.colors.darkGrayText}
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'E-mail'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ border: 'none', padding: '15px' }}>
              <Typography
                color={(theme: Theme) => theme.colors.darkGrayText}
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'ФИО'}
              </Typography>
            </TableCell>
            <TableCell align={'left'} sx={{ border: 'none', padding: '15px' }}>
              <Typography
                color={(theme: Theme) => theme.colors.darkGrayText}
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'Сумма, руб.'}
              </Typography>
            </TableCell>
            <TableCell
              align={'left'}
              sx={{
                border: 'none',
                padding: '15px',
              }}>
              <Grid container={true} alignItems={'center'} spacing={2}>
                <Grid item={true}>
                  <Typography
                    color={(theme: Theme) => theme.colors.darkGrayText}
                    fontSize={'16px'}
                    fontWeight={400}
                    lineHeight={'24px'}>
                    {'Статус'}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell
              align={'right'}
              sx={{
                border: 'none',
                padding: '15px',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {donations &&
            donations.map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  align={'left'}
                  sx={{
                    padding: '15px',
                    borderBottomLeftRadius: '6px',
                    borderTopLeftRadius: '6px',
                  }}>
                  <Grid container={true} spacing={1} alignItems={'center'}>
                    <Grid item={true}>
                      <Typography
                        fontSize={'14px'}
                        lineHeight={'18px'}
                        color={(theme: Theme) => theme.colors.darkGrayText}>
                        {format(item.date, 'dd.MM.yyyy')}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align={'left'} sx={{ padding: '15px' }}>
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    color={(theme: Theme) => theme.colors.darkGrayText}
                    sx={{
                      wordWrap: 'break-word',
                      wordBreak: 'break-all',
                    }}>
                    {item.templeName}
                  </Typography>
                </TableCell>
                <TableCell
                  align={'left'}
                  sx={{
                    padding: '15px',
                    maxWidth: '300px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}>
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    color={(theme: Theme) => theme.colors.grayText}
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>
                    {item.email}
                  </Typography>
                </TableCell>
                <TableCell
                  align={'left'}
                  sx={{
                    padding: '15px',
                    maxWidth: '300px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}>
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    color={(theme: Theme) => theme.colors.darkGrayText}
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>
                    {item.isAnonymous || !item.name ? 'Анонимно' : item.name}
                  </Typography>
                </TableCell>
                <TableCell align={'left'} sx={{ padding: '15px' }}>
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    color={(theme: Theme) => theme.colors.darkGrayText}>
                    {formatCurrency(item.amount, 0, undefined, false)}
                  </Typography>
                </TableCell>
                <TableCell align={'left'} sx={{ padding: '15px' }}>
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    color={getStatusColor(item.status)}>
                    {formatStatus(item.status)}
                  </Typography>
                </TableCell>
                <TableCell align={'center'} sx={{ padding: '0px 15px' }}>
                  {item.comment && (
                    <Tooltip
                      title={item.comment}
                      placement={'bottom-start'}
                      sx={{ maxWidth: '180px' }}>
                      <Grid>
                        <AdminDonationComment />
                      </Grid>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
