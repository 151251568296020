import * as Yup from 'yup';
import {
  MAX_DONATION,
  MESSAGE_REQUIRED,
  MIN_DONATION,
  getMessageInvalidMaxAmount,
  getMessageInvalidMinAmount,
} from '@/utils/validation-utils';

export const donationSchema: Yup.NumberSchema = Yup.number()
  .min(MIN_DONATION, getMessageInvalidMinAmount())
  .max(MAX_DONATION, getMessageInvalidMaxAmount())
  .required(MESSAGE_REQUIRED);
