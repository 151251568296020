import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const InvoiceBoxIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 1733.3333 354.14667'}
    height={'354.14667'}
    width={'1733.3333'}
    sx={{ width: '120px', height: '24px', display: 'flex' }}
    {...props}>
    <g
      transform={'matrix(1.3333333,0,0,-1.3333333,0,354.14667)'}
      style={{ mixBlendMode: 'luminosity' }}
      opacity={'0.5'}>
      <g transform={'scale(0.1)'}>
        <path
          fill={'#fad008'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'm 9923.23,2292.55 c -367.06,0 -664.62,-297.56 -664.62,-664.62 0,-367.06 297.56,-664.61 664.62,-664.61 367.07,0 664.57,297.55 664.57,664.61 0,367.06 -297.5,664.62 -664.57,664.62 z m 0,-316.92 c 191.77,0 347.67,-155.98 347.67,-347.7 0,-191.72 -155.9,-347.7 -347.67,-347.7 -191.72,0 -347.7,155.98 -347.7,347.7 0,191.72 155.98,347.7 347.7,347.7'
          }
        />
        <path
          fill={'#303945'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'M 0,2495.8 H 322.742 V 1582.04 L 992.043,2495.8 H 1296.68 V 1093.03 H 976.059 v 911.8 L 306.598,1093.03 H 0 V 2495.8'
          }
        />
        <path
          fill={'#303945'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'm 1599.04,2171.18 h 310.68 v -420.84 h 468.86 v 420.84 h 312.63 V 1093.03 h -312.63 v 402.81 h -468.86 v -402.81 h -310.68 v 1078.15'
          }
        />
        <path
          fill={'#303945'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'm 3702.01,1423.69 c 0,41.42 -13.37,71.83 -40.12,91.16 -26.75,19.4 -68.82,29.11 -126.23,29.11 h -268.43 v -238.52 h 262.4 c 114.97,0 172.38,39.39 172.38,118.25 z m -434.78,304.63 h 236.47 c 109.43,0 164.22,38.73 164.22,116.2 0,76.16 -54.79,114.25 -164.22,114.25 h -236.47 z m 727.35,-328.69 c 0,-97.53 -36.86,-173.03 -110.24,-226.44 -73.39,-53.41 -182.98,-80.16 -328.62,-80.16 h -575.2 v 1078.15 h 551.23 c 134.71,0 240.38,-24.38 316.55,-73.14 76.15,-48.76 114.16,-117.26 114.16,-205.41 0,-53.49 -13.71,-100.54 -40.94,-141.31 -27.4,-40.77 -66.54,-72.49 -117.42,-95.16 126.88,-42.81 190.48,-128.27 190.48,-256.53'
          }
        />
        <path
          fill={'#303945'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'm 5559.7,2598.05 h 204.34 c 1.3,-46.8 14.19,-83.17 38.98,-109.26 24.79,-26.02 61.15,-39.06 109.27,-39.06 48.1,0 85.12,13.04 111.22,39.06 26.09,26.09 39.14,62.46 39.14,109.26 h 208.42 c -1.31,-207.11 -120.36,-310.67 -356.83,-310.67 -235.17,0 -353.24,103.56 -354.54,310.67 z m -186.57,-426.87 h 310.67 v -641.24 l 524.97,641.24 h 286.7 V 1093.03 h -310.68 v 641.25 l -523,-641.25 h -288.66 v 1078.15'
          }
        />
        <path
          fill={'#303945'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'm 7002.01,1148.15 c -91.65,47.38 -163.08,113.18 -214.62,197.42 -51.37,84.15 -76.97,179.63 -76.97,286.53 0,106.91 25.6,202.39 76.97,286.54 51.54,84.24 122.97,149.96 214.62,197.42 91.33,47.37 194.56,71.1 309.53,71.1 113.51,0 212.67,-23.73 297.47,-71.1 84.8,-47.46 146.77,-115.22 185.42,-203.37 l -242.5,-130.3 c -56.1,98.82 -136.99,148.32 -242.34,148.32 -81.54,0 -149.06,-26.75 -202.55,-80.16 -53.5,-53.48 -80.08,-126.3 -80.08,-218.45 0,-92.22 26.58,-165.04 80.08,-218.44 53.49,-53.41 121.01,-80.16 202.55,-80.16 106.82,0 187.55,49.41 242.34,148.32 l 242.5,-132.26 c -38.65,-85.53 -100.62,-152.32 -185.42,-200.43 -84.8,-48.11 -183.96,-72.16 -297.47,-72.16 -114.97,0 -218.2,23.73 -309.53,71.18'
          }
        />
        <path
          fill={'#303945'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'm 8737.55,1389.6 c 48.6,52.1 73.07,118.24 73.07,198.39 0,81.46 -24.47,146.29 -73.07,194.4 -48.93,48.11 -113.17,72.16 -193.42,72.16 -81.54,0 -146.93,-24.05 -196.35,-72.16 -49.58,-48.11 -74.21,-112.94 -74.21,-194.4 0,-81.46 25.29,-148 76.17,-199.37 50.88,-51.45 115.47,-77.14 194.39,-77.14 80.25,0 144.49,26.01 193.42,78.12 z m 151.35,644.26 c 77.29,-42.07 137.31,-101.19 179.38,-177.35 42.08,-76.08 62.96,-164.31 62.96,-264.52 0,-102.83 -24.95,-194.08 -75.02,-273.5 -50.23,-79.5 -120.03,-140.99 -209.4,-184.36 -89.7,-43.38 -191.14,-65.15 -304.64,-65.15 -188.53,0 -336.28,60.42 -443.92,181.35 -107.63,120.92 -161.45,298.28 -161.45,532.06 0,216.41 47.29,389.12 141.39,518.04 94.1,128.91 238.75,213.39 433.97,253.51 l 512.9,102.17 42.08,-268.52 -452.89,-90.18 c -122.96,-24.05 -214.13,-64.09 -273.49,-120.2 -59.53,-56.18 -94.59,-135.6 -105.19,-238.51 46.64,50.81 102.74,89.86 168.3,117.26 65.4,27.31 136.99,41.1 214.29,41.1 103.07,0 193.1,-21.04 270.73,-63.2'
          }
        />
        <path
          fill={'#303945'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'm 11229.6,1501.88 h -162.5 v -408.85 h -312.6 v 1078.15 h 312.6 v -418.8 h 168.3 l 292.6,418.8 h 332.7 l -376.7,-517.06 402.8,-561.09 h -368.7 l -288.5,408.85'
          }
        />
        <path
          fill={'#303945'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'm 12207.6,1148.15 c -91.5,47.38 -162.9,113.18 -214.5,197.42 -51.5,84.15 -77.1,179.63 -77.1,286.53 0,106.91 25.6,202.39 77.1,286.54 51.6,84.24 123,149.96 214.5,197.42 91.5,47.37 194.5,71.1 309.7,71.1 113.5,0 212.6,-23.73 297.4,-71.1 84.8,-47.46 146.6,-115.22 185.3,-203.37 l -242.3,-130.3 c -56.1,98.82 -137,148.32 -242.6,148.32 -81.3,0 -148.8,-26.75 -202.3,-80.16 -53.5,-53.48 -80.3,-126.3 -80.3,-218.45 0,-92.22 26.8,-165.04 80.3,-218.44 53.5,-53.41 121,-80.16 202.3,-80.16 106.9,0 187.8,49.41 242.6,148.32 l 242.3,-132.26 c -38.7,-85.53 -100.5,-152.32 -185.3,-200.43 -84.8,-48.11 -183.9,-72.16 -297.4,-72.16 -115.2,0 -218.2,23.73 -309.7,71.18'
          }
        />
        <path
          fill={'#303945'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'm 4648.6,2096.03 c -130.41,0 -260.83,-49.75 -360.34,-149.25 -199,-199.02 -199,-521.66 0,-720.67 99.51,-99.5 229.93,-149.25 360.34,-149.25 130.41,0 260.83,49.75 360.33,149.25 199,199.01 199,521.65 0,720.67 -99.5,99.5 -229.92,149.25 -360.33,149.25 z m 0,-267.49 c 64.66,0 125.45,-25.2 171.18,-70.91 94.38,-94.4 94.38,-247.98 0,-342.36 -45.73,-45.72 -106.52,-70.9 -171.18,-70.9 -64.66,0 -125.46,25.18 -171.18,70.89 -94.38,94.39 -94.38,247.97 -0.01,342.36 45.73,45.72 106.53,70.92 171.19,70.92'
          }
        />
        <path
          fill={'#fad008'}
          fillOpacity={1}
          fillRule={'nonzero'}
          stroke={'none'}
          d={
            'M 9365.97,758.578 C 8752.48,242.34 7947.67,-30.7891 7157.31,2.76953 6366.07,27.6914 5595.34,359.578 5037.31,906.262 c -33.19,32.039 -34.09,84.906 -2.04,118.078 31.27,32.39 82.97,33.52 116.24,3.77 548.33,-498.731 1280,-778.758 2010.66,-774.899 731.19,-3.902 1451.04,274.148 1985.14,757.839 68.37,61.92 174.01,56.7 235.94,-11.679 61.94,-68.383 56.67,-174 -11.7,-235.941 -1.34,-1.2 -2.69,-2.379 -4.03,-3.539 l -1.55,-1.313'
          }
        />
      </g>
    </g>
  </SvgIcon>
);
