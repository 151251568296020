import { AddressDTO } from '@/types/form/AddressDTO';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  CircularProgress,
  Paper,
  TextField,
} from '@mui/material';
import { AutocompleteValue } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { DaDataResponseDTO } from '@/types/form/DaDataResponseDTO';
import { RegionDTO } from '@/types/RegionDTO';
import { Theme } from '@mui/material/styles';
import { isBlank } from '@/utils/string-utils';
import { useDebounce } from '@/app/hooks/useDebounce';
import { useGetAddressesSuggestionsQuery } from '@/services/api/daDataApiSlice';
import React, {
  FC,
  Fragment,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';

interface Props {
  error?: boolean;
  initialAddress: string;
  regions?: RegionDTO[];
  setRegionToForm: (value: RegionDTO | null) => void;
  helperText?: ReactNode;
  setAddressToForm: (value?: string) => void;
  disabled?: boolean;
}

export const AddressFieldFreeSolo: FC<Props> = (props: Props) => {
  const {
    error,
    helperText,
    setAddressToForm,
    initialAddress,
    regions,
    setRegionToForm,
    disabled,
  } = props;
  const [address, setAddress] = useState<AddressDTO | null | string>(
    initialAddress
  );
  const [searchQuery, setSearchQuery] = useState<string>(initialAddress);
  const [open, setOpen] = useState<boolean>(false);
  const {
    data: response,
    isFetching,
    isLoading,
  } = useGetAddressesSuggestionsQuery(searchQuery, {
    skip: isBlank(searchQuery),
  });
  const loading = isFetching || isLoading;
  const handleAddressee = useDebounce<string>((addressee: string): void => {
    setSearchQuery(addressee);
  }, 500);
  const handleSearch = (addressee: string): void => {
    handleAddressee(addressee);
  };

  const getCurrentOptions = (response?: DaDataResponseDTO): AddressDTO[] => {
    if (
      response?.suggestions &&
      response.suggestions.length > 0 &&
      !isBlank(searchQuery)
    ) {
      return response.suggestions;
    } else {
      return [];
    }
  };
  const handleFreeSoloValue = (
    address: AddressDTO | null | string
  ): string | undefined => {
    return typeof address == 'string' ? address : address?.value;
  };
  const handleChangeAddress = (address: AddressDTO | null | string) => {
    setAddress(address);
    setAddressToForm(handleFreeSoloValue(address));
  };
  useEffect(() => {
    if (
      response?.suggestions &&
      response.suggestions.length > 0 &&
      !isBlank(searchQuery)
    ) {
      if (
        response.suggestions[0].data.region &&
        response.suggestions[0].data.region_type_full &&
        regions
      ) {
        const regionName = response.suggestions[0].data.region.concat(
          ' ',
          response.suggestions[0].data.region_type_full
        );
        const findRegion = regions.find((region) => region.name === regionName);
        if (findRegion) {
          setRegionToForm(findRegion);
        }
      }
    }
  }, [searchQuery, response]);
  return (
    <Autocomplete
      id={'address-autocomplete'}
      open={open}
      autoSelect={true}
      freeSolo={true}
      disabled={disabled}
      loading={loading}
      options={getCurrentOptions(response)}
      value={address}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        handleSearch(handleFreeSoloValue(address) || '');
      }}
      onChange={(
        e: SyntheticEvent,
        value: AutocompleteValue<AddressDTO, undefined, undefined, boolean>
      ) => handleChangeAddress(value)}
      fullWidth={true}
      blurOnSelect={true}
      noOptionsText={'Не найдено'}
      loadingText={'Загрузка ...'}
      openText={''}
      clearText={''}
      sx={(theme: Theme) => ({
        marginTop: theme.spacing(3),
      })}
      PaperComponent={({ children }) => (
        <Paper sx={{ marginTop: '3px' }}>{children}</Paper>
      )}
      isOptionEqualToValue={(option: AddressDTO, value: AddressDTO) =>
        option.value === value.value
      }
      getOptionLabel={(option: AddressDTO | string) =>
        handleFreeSoloValue(option) || ''
      }
      filterOptions={(options) => options}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'input') {
          handleSearch(newInputValue);
        }
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          sx={() => ({
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              right: '9px',
            },
            '& .MuiOutlinedInput-notchedOutline span': { display: 'none' },
          })}
          label={'Адрес церкви'}
          placeholder={'Введите адрес церкви'}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color={'inherit'} size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};
