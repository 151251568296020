import { EditTempleDTO } from '@/types/EditTempleDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { FormikHelpers } from 'formik';
import { Grid, Typography } from '@mui/material';
import { TempleForm } from '@/components/common/TempleForm';
import { TempleFormValues } from '@/types/form/TempleFormValues';
import { fillDtoFromEditForm } from '@/utils/request-utils';
import { getCurrentEditTempleForm } from '@/services/templesSlice';
import { useAppSelector } from '@/app/hooks';
import {
  useEditTempleMutation,
  useGetTempleWithDeaconQuery,
} from '@/services/api/templesApiSlice';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';

interface Params {
  id: string;
}

export const AdminEditTemplePage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<Params>();

  const {
    data: temple,
    isLoading: isTempleLoading,
    isFetching: isTempleFetching,
  } = useGetTempleWithDeaconQuery(parseInt(id));

  const [editTemple, { isLoading: isLoading }] = useEditTempleMutation();
  const handleSubmit = async (
    values: TempleFormValues,
    helpers?: FormikHelpers<TempleFormValues>
  ): Promise<void> => {
    const dto: EditTempleDTO = fillDtoFromEditForm(values, temple?.id);
    await editTemple({ request: dto, images: values.attachFiles })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Данные успешно изменены!', {
          variant: 'success',
        });
        if (helpers) {
          helpers.resetForm();
        }
      })
      .catch((e: { status: number; data: ErrorDTO }) => {
        enqueueSnackbar(e.data.message, {
          variant: 'error',
        });
      });
  };
  const initialEditValues = useAppSelector(getCurrentEditTempleForm);
  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true} pb={2}>
        <Typography variant={'h2'}>
          {'Редактирование подключенной церкви'}
        </Typography>
      </Grid>
      <Grid item={true} pb={4}>
        <Typography variant={'body2'}>
          {'Для сохранения изменений необходимо заполнить все поля'}
        </Typography>
      </Grid>
      <Grid item={true}>
        <TempleForm
          initialFormValues={initialEditValues}
          temple={temple}
          authUser={temple?.deacon}
          loadingData={isTempleFetching || isTempleLoading}
          sendingData={isLoading}
          handleSubmit={handleSubmit}
          submitButtonText={'Сохранить'}
          isAdminPage={true}
        />
      </Grid>
    </Grid>
  );
};
