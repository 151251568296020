import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const OnlinePaymentIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 128 80'}
    sx={{ width: '128px', height: '80px' }}
    {...props}>
    <g clipPath={'url(#clip0_189_10399)'}>
      <path
        d={
          'M127.964 25.0992C127.95 25.3686 127.747 25.6501 127.361 25.8732L122.851 28.5108L120.283 30.0085L51.8237 70.0407L49.4742 71.4218L44.964 74.0594C44.863 74.1165 44.7583 74.1668 44.6507 74.2102L44.6788 79.801C44.0678 80.0444 43.3914 80.0679 42.7651 79.8673L42.737 74.2805C42.6559 74.2515 42.5773 74.2159 42.502 74.174L0.427729 50.1281C0.285499 50.0536 0.165605 49.9426 0.0803238 49.8065C0.0296635 49.7205 0.0020001 49.6229 0 49.523L0.0100382 51.1856V51.4007L0.0341378 55.1319C0.038683 55.2297 0.0669387 55.3249 0.116469 55.4094C0.199794 55.542 0.316029 55.6507 0.453836 55.725L8.96628 60.58L9.15304 60.6865L20.6516 67.2544L20.8383 67.3609L32.3369 73.9307L32.5216 74.0373L42.5361 79.7588C42.9969 79.9794 43.5153 80.0497 44.0181 79.9598L44.2029 79.9276C44.4813 79.8731 44.7492 79.774 44.9961 79.6341L47.195 78.3475L47.3797 78.2409L55.6352 73.4161L55.8199 73.3095L58.81 71.5605L58.9948 71.452L67.2522 66.6271L67.5996 66.4261L70.423 64.7756L70.6077 64.667L78.6844 59.9447L79.0318 59.7437H79.0499L82.04 57.9967L82.2248 57.8881L90.4822 53.0633L90.6669 52.9547L93.657 51.2077L93.8418 51.0992L102.099 46.2743L102.284 46.1678L105.274 44.4187L105.459 44.3102L113.716 39.4853L113.901 39.3768L116.891 37.6298L117.076 37.5212L125.333 32.6964L125.518 32.5878L127.205 31.6007L127.394 31.4922C127.448 31.46 127.498 31.4258 127.546 31.3917C127.671 31.3171 127.777 31.2132 127.853 31.0889C127.929 30.9645 127.974 30.8235 127.984 30.678V30.5413V30.3282L127.952 25.1234'
        }
        fill={'#E7DDF0'}
      />
      <path
        d={
          'M127.884 24.7816C127.942 24.8805 127.97 24.9945 127.964 25.1093C127.95 25.3787 127.747 25.6601 127.361 25.8833L122.851 28.5208L78.4916 3.16225L82.8533 0.611116C83.034 0.506577 83.2128 0.391986 83.3895 0.273375C83.647 0.103646 83.9467 0.00904552 84.2549 0.000168949C84.5631 -0.00870763 84.8677 0.0684904 85.1345 0.223116L108.83 13.7669L113.6 16.4909L113.786 16.5974L125.293 23.1733L125.478 23.2799L127.536 24.4539C127.678 24.5291 127.798 24.6408 127.884 24.7776V24.7816ZM29.9532 31.5424L5.10868 46.0692L49.4742 71.4218L44.964 74.0594C44.863 74.1164 44.7583 74.1668 44.6507 74.2101C44.04 74.4538 43.3639 74.4786 42.737 74.2805C42.6559 74.2515 42.5773 74.2159 42.502 74.174L0.427729 50.1281C0.285499 50.0536 0.165605 49.9426 0.0803238 49.8065C0.0296635 49.7205 0.0020001 49.6228 0 49.523C0 49.2416 0.200813 48.94 0.602438 48.7048L8.88797 43.8619L9.07272 43.7533L9.59684 43.4457L9.78158 43.3371L20.505 37.0689L20.6897 36.9603L21.2138 36.6547L21.3986 36.5462L29.9572 31.5424'
        }
        fill={'#DAC2F0'}
      />
      <path
        d={
          'M78.4924 3.16229L122.858 28.5108L49.4749 71.4158L5.10938 46.0693L78.4924 3.16229Z'
        }
        fill={'#9B51E0'}
      />
      <path
        d={
          'M99.7566 11.4852L107.789 16.0607C108.024 16.1954 108.159 16.3462 108.012 16.5311C107.957 16.6024 107.889 16.6625 107.811 16.7081C107.627 16.8118 107.421 16.871 107.209 16.8808C106.998 16.8905 106.787 16.8507 106.594 16.7643L98.4834 12.1305L98.4171 12.0863C98.3509 11.8289 98.3709 11.7686 98.7023 11.5736L98.8127 11.5515C99.1742 11.3404 99.4473 11.3062 99.7606 11.4852H99.7566Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M109.089 39.4089V39.5135C109.068 39.7423 108.994 39.9631 108.872 40.1579C108.751 40.3528 108.585 40.5163 108.388 40.6353L103.617 43.4256C103.228 43.6528 102.908 43.5201 102.906 43.1301V43.0236C102.928 42.7947 103.003 42.5741 103.125 42.3793C103.247 42.1846 103.413 42.021 103.609 41.9018L108.38 39.1134C108.77 38.8842 109.087 39.0169 109.089 39.4089Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M116.374 35.151V35.2556C116.353 35.4846 116.278 35.7053 116.156 35.9002C116.034 36.095 115.868 36.2584 115.672 36.3773L110.9 39.1677C110.511 39.3949 110.193 39.2622 110.191 38.8722V38.7656C110.212 38.5368 110.287 38.3161 110.409 38.1213C110.53 37.9265 110.696 37.763 110.892 37.6439L115.666 34.8555C116.055 34.6263 116.372 34.759 116.374 35.151Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M115.086 44.4549L114.303 44.3524C114.216 44.2884 114.125 44.2293 114.032 44.1755L53.5469 9.21947C51.9846 8.31682 49.2556 8.4294 47.4503 9.47278L12.1795 29.8658C11.7653 30.0943 11.3992 30.4008 11.1012 30.7684L10.6875 30.6478V31.8681C10.6875 32.437 11.0369 32.9818 11.7398 33.3879L72.2225 68.346C73.7848 69.2486 76.5139 69.136 78.3192 68.0947L113.59 47.6996C114.594 47.1186 115.104 46.3688 115.08 45.6631V44.4569L115.086 44.4549Z'
        }
        fill={'#A883CC'}
      />
      <path
        d={
          'M72.2208 67.1217L11.732 32.1636C10.1697 31.261 10.3705 29.6829 12.1778 28.6415L47.4606 8.24646C49.2679 7.20308 51.995 7.0905 53.5573 7.99315L114.03 42.9512C115.592 43.8538 115.398 45.432 113.592 46.4753L78.3095 66.8684C76.5021 67.9117 73.7751 68.0243 72.2128 67.1217H72.2208Z'
        }
        fill={'#DAC2F0'}
      />
      <path
        d={
          'M81.9756 64.7515L15.8359 26.5246L22.8563 22.4657L88.996 60.6926L81.9756 64.7515Z'
        }
        fill={'#350E59'}
      />
    </g>
    <defs>
      <clipPath id={'clip0_189_10399'}>
        <rect width={'128'} height={'80'} fill={'white'} />
      </clipPath>
    </defs>
  </SvgIcon>
);
