import { AdminState, adminSlice } from '@/services/adminSlice';
import { AuthState, authSlice } from '@/services/authSlice';
import {
  CombinedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import { TemplesState, templesSlice } from '@/services/templesSlice';
import { apiSlice } from '@/services/api/apiSlice';
import { unauthorizedMiddleware } from '@/app/middleware/unauthorizedMiddleware';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';

interface ReducerState {
  temples: TemplesState;
  auth: AuthState;
  admin: AdminState;
  api: CombinedState<any>;
}

export const rootReducer = combineReducers({
  temples: templesSlice.reducer,
  auth: authSlice.reducer,
  admin: adminSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const config: PersistConfig<ReducerState> = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['temples', 'auth'],
};

const reducer = persistReducer(config, rootReducer);

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(apiSlice.middleware)
      .concat(unauthorizedMiddleware)
      .concat(logger);
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
