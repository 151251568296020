import * as Yup from 'yup';
import {
  MESSAGE_PASSWORD_CAPITAL_LETTER,
  MESSAGE_PASSWORD_NUMBER,
  MESSAGE_REQUIRED,
  passwordCapitalLetterRegExp,
  passwordNumberRegExp,
} from '@/utils/validation-utils';

export const passwordSchema: Yup.StringSchema = Yup.string()
  .required(MESSAGE_REQUIRED)
  .matches(passwordNumberRegExp, MESSAGE_PASSWORD_NUMBER)
  .matches(passwordCapitalLetterRegExp, MESSAGE_PASSWORD_CAPITAL_LETTER);
