export enum AdminPanelActiveType {
  TEMPLE_ACTIVE = 'TEMPLE_ACTIVE',
  TEMPLE_INACTIVE = 'TEMPLE_INACTIVE',
  REQUEST_NEW = 'REQUEST_NEW',
  REQUEST_ACCEPTED = 'REQUEST_ACCEPTED',
}

export const getValueFromString = (
  value: string
): AdminPanelActiveType | null => {
  return AdminPanelActiveType[value as keyof typeof AdminPanelActiveType];
};
