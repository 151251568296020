import { DonationDTO } from '@/types/DonationDTO';
import { DonationMobileTableContainer } from '@/components/donations/DonationMobileTableContainer';
import { DonationTableContainer } from '@/components/donations/DonationTableContainer';
import {
  Grid,
  Pagination,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { RegionsList } from '@/components/temples/RegionsList';
import { Theme } from '@mui/material/styles';
import {
  clearRegion,
  getCurrentRegion,
  getCurrentTemple,
  getPageRequest,
  setDefaultPageValues,
  setPageNumber,
} from '@/services/templesSlice';
import { getTotalNumberOfPages } from '@/utils/pagination-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetDonationsPageQuery } from '@/services/api/donationsApiSlice';
import { useGetRegionsQuery } from '@/services/api/templesApiSlice';
import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';

export const DonationsPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const dispatch = useAppDispatch();
  const pageRequest = useAppSelector(getPageRequest);
  const currentRegion = useAppSelector(getCurrentRegion);
  const currentTemple = useAppSelector(getCurrentTemple);
  const { data: regions, isFetching: isRegionsFetching } = useGetRegionsQuery();
  const { data: donationsPage, isFetching: isDonationsFetching } =
    useGetDonationsPageQuery({
      pageSize: pageRequest.pageSize,
      pageNumber: pageRequest.pageNumber,
      regionId: currentRegion ? currentRegion.id : undefined,
      templeId: currentTemple ? currentTemple.id : undefined,
    });
  const [donationsList, setDonationsList] = useState<DonationDTO[] | undefined>(
    undefined
  );
  const handlePageChange = (page: number) => {
    if (pageRequest.pageNumber !== page - 1) {
      dispatch(setPageNumber(page - 1));
    }
  };
  useEffect(() => {
    if (donationsPage && donationsPage.donations) {
      setDonationsList(donationsPage.donations);
    }
  }, [donationsPage, setDonationsList]);

  useEffect(() => {
    return () => {
      dispatch(setDefaultPageValues());
      dispatch(clearRegion());
    };
  }, [dispatch]);

  return (
    <Grid
      container={true}
      direction={'column'}
      mt={'20px'}
      style={{ maxWidth: '100%' }}>
      <Grid item={true} mb={4}>
        <Typography variant={'h1'} lineHeight={'30px'}>
          {'Список пожертвований'}
        </Typography>
      </Grid>
      <Grid item={true} style={{ maxWidth: '100%' }}>
        <RegionsList regions={regions} isFetching={isRegionsFetching} />
      </Grid>
      <Grid item={true} mt={'20px'} style={{ maxWidth: '100%' }}>
        {isDonationsFetching ? (
          <Fragment>
            {Array.from(new Array(4)).map((index) => (
              <Skeleton key={index} width={'100%'} height={'70px'} />
            ))}
          </Fragment>
        ) : isMobile ? (
          <DonationMobileTableContainer donationsList={donationsList} />
        ) : (
          <DonationTableContainer donationsList={donationsList} />
        )}
      </Grid>
      <Grid item={true} mt={3} mb={10}>
        <Grid
          container={true}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Grid item={true} mt={2} display={'flex'}>
            <Pagination
              defaultPage={pageRequest.pageNumber + 1}
              count={
                donationsPage && donationsPage.transactionsAmount > 0
                  ? getTotalNumberOfPages(
                      donationsPage.transactionsAmount,
                      pageRequest.pageSize
                    )
                  : 1
              }
              onChange={(e: ChangeEvent<unknown>, page: number) => {
                handlePageChange(page);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
