import {
  AdminPanelActiveType,
  getValueFromString,
} from '@/types/filter/AdminPanelActiveType';
import {
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@mui/material';
import { FilterIcon } from '@/app/icons/FilterIcon';
import { RadioButtonFilter } from '@/types/filter/RadioButtonFilter';
import { RadioGroupFilter } from '@/components/admin/panel/RadioGroupFilter';
import React, { FC, useRef, useState } from 'react';

interface Props {
  tittle: string;
  activeType: AdminPanelActiveType | null;
  setActiveType: (type: AdminPanelActiveType | null) => void;
  buttons: RadioButtonFilter[];
}

export const AdminFilterRadioPopper: FC<Props> = (props: Props) => {
  const { tittle, buttons, activeType, setActiveType } = props;

  const [open, setOpen] = useState(false);
  const [currentType, setCurrentType] = useState<AdminPanelActiveType | null>(
    activeType
  );
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setCurrentType(activeType);
    setOpen(false);
  };

  const handleClean = () => {
    setCurrentType(null);
    setActiveType(null);
    setOpen(false);
  };

  const handleConfirm = () => {
    setActiveType(currentType);
    setOpen(false);
  };

  return (
    <div>
      <Button
        ref={anchorRef}
        fullWidth={true}
        sx={{ padding: 0 }}
        aria-controls={open ? 'filter-list-grow' : undefined}
        aria-haspopup={'true'}
        onClick={handleToggle}
        disableRipple={true}>
        <Grid container={true} justifyContent={'space-between'} wrap={'nowrap'}>
          <Grid item={true}>
            <Typography
              color={(theme: Theme) => theme.colors.darkGrayText}
              fontSize={'16px'}
              fontWeight={400}
              lineHeight={'24px'}>
              {tittle}
            </Typography>
          </Grid>
          <Grid item={true} mt={-0.25}>
            <FilterIcon style={{ display: 'block' }} />
          </Grid>
        </Grid>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition={true}
        placement={'bottom-start'}
        disablePortal={false}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              marginTop: '1px',
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper
              sx={{
                padding: '12px',
                background: '#ffffff',
                border: '1px solid #D2D6E1',
                boxShadow: '0px 2px 12px rgba(28, 34, 50, 0.06)',
                borderRadius: '6px',
                width: '200px',
              }}>
              <ClickAwayListener onClickAway={() => handleClose()}>
                <Grid container={true} direction={'column'} wrap={'nowrap'}>
                  <Grid item={true}>
                    <RadioGroupFilter
                      value={currentType}
                      buttons={buttons}
                      onChange={(event, value) =>
                        setCurrentType(getValueFromString(value))
                      }
                    />
                  </Grid>
                  <Grid item={true} mb={1.25}>
                    <Divider
                      sx={{ marginLeft: '-12px', width: 'calc(100% + 24px)' }}
                    />
                  </Grid>
                  <Grid item={true}>
                    <Grid
                      container={true}
                      p={0}
                      justifyContent={'space-between'}>
                      <Grid item={true}>
                        <Button
                          sx={{ padding: '1px 8px' }}
                          onClick={handleClean}>
                          <Typography
                            fontSize={'14px'}
                            lineHeight={'18px'}
                            color={(theme: Theme) => theme.colors.grayText}>
                            {'Очистить'}
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item={true}>
                        <Button
                          variant={'contained'}
                          sx={{ padding: '1px 8px' }}
                          onClick={handleConfirm}>
                          <Typography fontSize={'14px'} lineHeight={'18px'}>
                            {'Ок'}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
