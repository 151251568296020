import { Grid, Typography } from '@mui/material';
import React, { FC } from 'react';

export const AboutPage: FC = () => {
  return (
    <Grid container={true} direction={'column'} mt={'20px'}>
      <Grid item={true}>
        <Typography variant={'h1'} lineHeight={'30px'}>
          {'О портале'}
        </Typography>
      </Grid>
      <Grid item={true} mt={'20px'} mb={'30px'}>
        <Typography
          fontSize={'18px'}
          lineHeight={'28px'}
          whiteSpace={'pre-line'}>
          <Grid
            container={true}
            direction={'column'}
            wrap={'nowrap'}
            rowSpacing={3}>
            <Grid item={true}>
              <Typography variant={'inherit'}>
                {
                  'Портал «Благодаритель» – это простой и удобный сервис для взаимодействия евангельских церквей и прихожан.'
                }
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant={'inherit'}>
                {
                  'Система позволяет легко зарегистрировать церковь в базе сервиса, а также направлять пожертвования общинам, чье служение нуждается в поддержке или развитии. '
                }
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant={'inherit'}>
                {
                  'Здесь вы можете собирать или жертвовать средства на организацию мероприятий, конференций или проектов.'
                }
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant={'inherit'}>
                {
                  'Кроме этого, данный сервис позволит вам перечислять десятину в вашу поместную церковь.'
                }
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant={'inherit'}>
                {
                  'Если вы находитесь за рубежом, но хотите продолжать участвовать в жизни своей общины, то можете это сделать, осуществив пожертвование через данный ресурс'
                }
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant={'inherit'}>
                {'Перечислить пожертвование может любой пользователь!'}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant={'inherit'}>
                {'Оставайтесь на связи с теми, кто вам дорог!'}
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};
