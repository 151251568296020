import * as Yup from 'yup';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { CreateFeedbackDTO } from '@/types/CreateFeedbackDTO';
import { FeedbackDTO } from '@/types/FeedbackDTO';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { GET_OFFER_URL } from '@/app/config';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MESSAGE_REQUIRED } from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { TypographyLink } from '@/components/common/TypographyLink';
import { emailSchema } from '@/validation/emailSchema';
import { isBlank } from '@/utils/string-utils';
import { useCreateFeedbackMutation } from '@/services/api/helpApiSlice';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC } from 'react';

interface FormValues {
  email?: string;
  question?: string;
}
interface Props {
  isMobile?: boolean;
}
export const FeedbackBlock: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [createFeedback, { isLoading }] = useCreateFeedbackMutation();
  const initialValues: FormValues = {
    email: '',
    question: '',
  };
  const initialErrors: FormikErrors<FormValues> = {
    email: isBlank(initialValues.email) ? MESSAGE_REQUIRED : undefined,
    question: isBlank(initialValues.question) ? MESSAGE_REQUIRED : undefined,
  };
  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    email: emailSchema.required(MESSAGE_REQUIRED).nullable(),
    letterNumber: Yup.string(),
    question: Yup.string().required(MESSAGE_REQUIRED).nullable(),
  });
  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ): Promise<void> => {
    const { setFieldValue, setFieldTouched } = helpers;
    const dto: CreateFeedbackDTO = {
      email: values.email,
      text: values.question,
    };
    await createFeedback(dto)
      .unwrap()
      .then((feedback: FeedbackDTO) => {
        setFieldValue('email', '');
        setFieldTouched('email', false);
        setFieldValue('question', '');
        setFieldTouched('question', false);
        enqueueSnackbar('Ваш отзыв отправлен', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Ошибка отправки отзыва', {
          variant: 'error',
        });
      });
  };

  return (
    <Grid container={true} direction={'column'} mb={12}>
      <Grid item={true}>
        <Typography
          fontSize={'18px'}
          lineHeight={'28px'}
          whiteSpace={'pre-line'}>
          {'Если вы не нашли ответы в разделе «Помощь», заполните форму ниже:'}
        </Typography>
      </Grid>
      <Grid item={true} mt={3}>
        <Box
          sx={(theme: Theme) => ({
            background: theme.colors.grayBackground,
            borderRadius: '12px',
            padding: theme.spacing(8, 3, 7),
            width: '100%',
            [theme.breakpoints.down('sm')]: {
              borderRadius: '0px',
              padding: theme.spacing(6, 2, 4),
              marginLeft: theme.spacing(-2),
              width: 'calc(100% + 32px)',
            },
          })}>
          <Formik
            initialValues={initialValues}
            initialErrors={initialErrors}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnChange={true}
            onSubmit={handleSubmit}>
            {({
              values,
              touched,
              errors,
              setFieldValue,
              setFieldTouched,
              submitForm,
              isValid,
            }: FormikProps<FormValues>) => {
              return (
                <Grid container={true} direction={'column'} spacing={4}>
                  <Grid item={true}>
                    <Grid container={true} spacing={6}>
                      <Grid item={true} sm={6} xs={12}>
                        <OutlinedField
                          name={'email'}
                          label={'E-mail для ответа'}
                          value={values.email}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email ? errors.email : ''}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldTouched(e.target.name, true, false);
                            setFieldValue(e.target.name, e.target.value, true);
                          }}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <OutlinedField
                          name={'question'}
                          label={'Текст сообщения'}
                          value={values.question}
                          error={touched.question && Boolean(errors.question)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldTouched(e.target.name, true, false);
                            setFieldValue(e.target.name, e.target.value, true);
                          }}
                          multiline={true}
                          rows={10}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item={true}>
                    <Grid
                      container={true}
                      direction={isMobile ? 'column-reverse' : 'row'}
                      columnSpacing={8}
                      rowSpacing={2}
                      alignItems={'center'}
                      justifyContent={'space-between'}>
                      <Grid item={true} xs={12} sm={6}>
                        <Typography
                          component={'div'}
                          variant={'body3'}
                          whiteSpace={'pre-line'}
                          sx={{
                            color: (theme: Theme) => theme.colors.grayText,
                          }}>
                          {'Нажимая кнопку “Отправить”, я соглашаюсь\nс '}
                          <a
                            href={GET_OFFER_URL}
                            target={'_blank'}
                            rel={'noopener noreferrer'}>
                            <TypographyLink>
                              {'условиями использования сервиса'}
                            </TypographyLink>
                          </a>
                          {' и принимаю их'}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={12} sm={6} sx={{ width: '100%' }}>
                        <LoadingButton
                          fullWidth={true}
                          variant={'contained'}
                          type={'submit'}
                          onClick={submitForm}
                          isLoading={isLoading}
                          disabled={isLoading || !isValid}>
                          {'Отправить'}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};
