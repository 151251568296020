import { Box, Button, Grid, Typography } from '@mui/material';
import { TempleDTO } from '@/types/TempleDTO';
import { TempleViewType } from '@/types/filter/TempleViewType';
import { Theme } from '@mui/material/styles';
import { getTemplePage } from '@/app/routes';
import { useHistory } from 'react-router-dom';
import React, { FC, Fragment } from 'react';

interface Props {
  temple: TempleDTO;
  isMobile?: boolean;
}

export const TempleCard: FC<Props> = (props: Props) => {
  const { temple, isMobile } = props;
  const history = useHistory();
  const handleClick = (type: TempleViewType) => {
    history.push(getTemplePage(temple.id, type));
  };

  return (
    <Fragment>
      <Box
        sx={{
          border: '1px solid',
          borderColor: isMobile ? '#dfdde4' : '#f8f7fc',
          borderRadius: '6px',
        }}>
        <Grid container={true} p={'14px'} direction={'column'} wrap={'nowrap'}>
          <Grid item={true} justifyContent={'center'}>
            <Box
              onClick={() => handleClick(TempleViewType.VIEW)}
              sx={{
                position: 'relative',
                height: '240px',
                width: '100%',
                overflow: 'hidden',
                cursor: 'pointer',
              }}>
              <img
                src={`data:image/jpeg;base64,${temple.attachFile?.content}`}
                alt={'temple-preview'}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
          <Grid item={true} mt={'12px'} minWidth={'100%'}>
            <Typography
              variant={'h2'}
              lineHeight={'28px'}
              whiteSpace={'pre-line'}
              minHeight={'84px'}
              sx={{
                display: '-webkit-box',
                lineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                boxOrient: 'vertical',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
              }}>
              {temple.name}
            </Typography>
          </Grid>
          <Grid item={true} mt={'12px'} minWidth={'100%'}>
            <Typography
              variant={'h4'}
              fontWeight={400}
              whiteSpace={'pre-line'}
              minHeight={'72px'}
              sx={{
                display: '-webkit-box',
                lineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                boxOrient: 'vertical',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
              }}>
              {temple.description}
            </Typography>
          </Grid>
          <Grid item={true} mt={'12px'}>
            <Typography
              variant={'body3'}
              whiteSpace={'pre-line'}
              minHeight={'32px'}
              sx={{
                color: (theme: Theme) => theme.colors.grayText,
                display: '-webkit-box',
                lineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                boxOrient: 'vertical',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}>
              {temple.address}
            </Typography>
          </Grid>
          <Grid item={true} mt={'12px'} mb={'10px'} minWidth={'100%'}>
            <Button
              color={'primary'}
              variant={'contained'}
              fullWidth={true}
              onClick={() => handleClick(TempleViewType.DONATE)}>
              {'Пожертвовать'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
