import { Box, Divider, Grid, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  title: string;
  content: string;
  isMobile?: boolean;
}

export const InformationTemplate: FC<Props> = (props: Props) => {
  const { title, content, isMobile } = props;

  return (
    <Grid container={true} justifyContent={'center'}>
      <Grid
        container={true}
        direction={'column'}
        justifyContent={'center'}
        maxWidth={'400px'}
        minWidth={'240px'}
        sx={{
          backgroundColor: (theme: Theme) => theme.colors.grayBackground,
          borderRadius: '12px',
        }}>
        <Grid item={true} pl={isMobile ? 1.5 : 3} pt={3.5} pr={2}>
          <Grid container={true} wrap={'nowrap'}>
            <Grid item={true} pr={1}>
              <CheckCircle color={'primary'} style={{ display: 'block' }} />
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'column'}>
                <Grid item={true}>
                  <Typography
                    fontSize={'20px'}
                    fontWeight={500}
                    lineHeight={'24px'}>
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} mt={3} width={'100%'}>
          <Divider />
        </Grid>
        <Grid item={true} mt={isMobile ? 1 : 3}>
          <Box
            padding={(theme: Theme) =>
              isMobile ? theme.spacing(0, 2, 1, 5) : theme.spacing(0, 3, 3, 6.5)
            }>
            <Typography
              fontSize={'16px'}
              fontWeight={400}
              lineHeight={'24px'}
              whiteSpace={'pre-line'}
              maxWidth={'290px'}>
              {content}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
