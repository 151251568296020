import { AdminPreviewInfoLabel } from '@/components/admin/AdminPreviewInfoLabel';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { ErrorDTO } from '@/types/ErrorDTO';
import { ROUTE_ADMIN_PANEL } from '@/app/routes';
import { RegistrationRequestDTO } from '@/types/RegistrationRequestDTO';
import { TempleInfo } from '@/components/temples/TempleInfo';
import { TempleViewDTO } from '@/types/TempleViewDTO';
import {
  formatDeaconFullName,
  formatPhoneNumber,
  getNumberSpaceFormat,
} from '@/utils/string-utils';
import {
  requestApiSlice,
  useApproveRegistrationRequestMutation,
} from '@/services/api/requestApiSlice';
import { useApproveEditRequestMutation } from '@/services/api/templesApiSlice';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  temple: TempleViewDTO | undefined;
  isTempleLoading: boolean;
  isTempleFetching: boolean;
  setOpenRejectView?: (isOpenRejectView: boolean) => void;
  editRequestValue?: {
    request: RegistrationRequestDTO;
    images: File[];
  } | null;
  clearEditRequestValue?: () => void;
}

export const AdminTemplePreview: FC<Props> = (props: Props) => {
  const {
    open,
    setOpen,
    temple,
    isTempleLoading,
    isTempleFetching,
    setOpenRejectView,
    editRequestValue,
    clearEditRequestValue,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { refetch } =
    requestApiSlice.endpoints.getRegistrationRequest.useQuerySubscription(
      temple?.id
    );

  const [approveEditRequest, { isLoading: isEditLoading }] =
    useApproveEditRequestMutation();

  const [approveRegistrationRequest, { isLoading: isRegistrationLoading }] =
    useApproveRegistrationRequestMutation();

  const handleSubmitApprove = async (isApprove: boolean): Promise<void> => {
    if (temple) {
      if (isApprove) {
        if (temple?.edited && editRequestValue) {
          approveEditRequest(editRequestValue)
            .unwrap()
            .then(() => {
              enqueueSnackbar(`Заявка одобрена!`, {
                variant: 'success',
              });
              history.push(ROUTE_ADMIN_PANEL);
            })
            .catch((e: { status: number; data: ErrorDTO }) => {
              enqueueSnackbar(e.data.message, {
                variant: 'error',
              });
            });
        } else {
          await approveRegistrationRequest({
            idRequest: temple.id,
            isApprove: isApprove,
          })
            .unwrap()
            .then(() => {
              enqueueSnackbar(`Заявка одобрена!`, {
                variant: 'success',
              });
              history.push(ROUTE_ADMIN_PANEL);
            })
            .catch((e: { status: number; data: ErrorDTO }) => {
              enqueueSnackbar(e.data.message, {
                variant: 'error',
              });
            });
        }
      } else {
        if (setOpenRejectView) {
          setOpenRejectView(true);
          setOpen(false);
          if (clearEditRequestValue) {
            clearEditRequestValue();
          }
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    refetch();
    if (clearEditRequestValue) {
      clearEditRequestValue();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'body'}
      PaperProps={{
        sx: {
          padding: (theme: Theme) => theme.spacing(6, 14),
          overflowY: 'unset',
          width: '70%',
          minWidth: '1100px',
        },
      }}
      BackdropProps={{ sx: { backgroundColor: 'rgba(0,0,0,0.8)' } }}>
      <DialogTitle
        sx={(theme: Theme) => ({
          marginBottom: theme.spacing(3),
        })}>
        <Grid container={true} justifyContent={'space-between'} wrap={'nowrap'}>
          <Grid item={true}>
            <Typography variant={'h1'} lineHeight={'28px'} mb={0.5}>
              {temple?.name}
            </Typography>
            <Typography
              variant={'h4'}
              color={(theme: Theme) => theme.colors.headerGray}>
              {temple?.address}
            </Typography>
          </Grid>
          <Grid
            item={true}
            sx={(theme: Theme) => ({
              marginTop: theme.spacing(-10.5),
              marginRight: theme.spacing(-15.5),
            })}>
            <IconButton disableRipple={true} onClick={handleClose}>
              <CloseIcon sx={{ color: 'white', opacity: '0.5' }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={(theme: Theme) => ({
          marginBottom: theme.spacing(6),
        })}>
        <Grid
          container={true}
          direction={'column'}
          rowSpacing={6}
          wrap={'nowrap'}>
          <Grid item={true}>
            <TempleInfo
              temple={temple}
              isTempleLoading={isTempleLoading}
              isTempleFetching={isTempleFetching}
              dialog={true}
            />
          </Grid>
          <Grid item={true}>
            <Divider />
          </Grid>
          <Grid item={true}>
            <Grid
              container={true}
              direction={'column'}
              rowSpacing={3}
              wrap={'nowrap'}>
              <Grid item={true}>
                <Typography
                  sx={(theme: Theme) => ({
                    fontSize: theme.spacing(2.25),
                    lineHeight: theme.spacing(3.5),
                    fontWeight: 500,
                  })}>
                  {'Реквизиты (общедоступно)'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true}>
                  <Grid item={true} xs={true}>
                    <AdminPreviewInfoLabel
                      label={'инн'}
                      content={getNumberSpaceFormat(temple?.inn, 3)}
                    />
                  </Grid>
                  <Grid item={true} xs={true}>
                    <AdminPreviewInfoLabel
                      label={'кпп'}
                      content={getNumberSpaceFormat(temple?.kpp, 3)}
                    />
                  </Grid>
                  <Grid item={true} xs={true}>
                    <AdminPreviewInfoLabel
                      label={'огрн'}
                      content={temple?.oktmo || ''}
                      mask={'9 99 99 9999999 9'}
                    />
                  </Grid>
                  <Grid item={true} xs={true}>
                    <AdminPreviewInfoLabel
                      label={'бик'}
                      content={getNumberSpaceFormat(temple?.bic, 3)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true}>
                <Grid container={true}>
                  <Grid item={true} xs={true}>
                    <AdminPreviewInfoLabel
                      label={'номер счета'}
                      content={getNumberSpaceFormat(temple?.bankAccount, 4)}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <AdminPreviewInfoLabel
                      label={'название банка'}
                      content={temple?.bankName || ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Divider />
          </Grid>
          <Grid item={true}>
            <Grid container={true} mb={3}>
              <Grid item={true}>
                <Typography
                  sx={(theme: Theme) => ({
                    fontSize: theme.spacing(2.25),
                    lineHeight: theme.spacing(3.5),
                    fontWeight: 500,
                  })}>
                  {'Контакты представителя церкви'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container={true}>
              <Grid item={true} xs={6}>
                <AdminPreviewInfoLabel
                  label={'ФИО'}
                  content={formatDeaconFullName(
                    temple?.deacon?.lastName,
                    temple?.deacon?.firstName,
                    temple?.deacon?.middleName
                  )}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <AdminPreviewInfoLabel
                  label={'телефон'}
                  content={formatPhoneNumber(temple?.deacon?.phone)}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <AdminPreviewInfoLabel
                  label={'E-mail'}
                  content={temple?.deacon?.email || ''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container={true}
          justifyContent={'space-between'}
          columnSpacing={3}>
          <Grid item={true} xs={3}>
            <Button
              variant={'outlined'}
              onClick={handleClose}
              fullWidth={true}
              disabled={isRegistrationLoading || isEditLoading}>
              {'Редактировать'}
            </Button>
          </Grid>
          <Grid item={true} xs={true}>
            <Grid
              container={true}
              columnSpacing={3}
              justifyContent={'flex-end'}>
              <Grid item={true} xs={4}>
                <Button
                  onClick={() => handleSubmitApprove(true)}
                  variant={'contained'}
                  fullWidth={true}
                  disabled={isRegistrationLoading || isEditLoading}>
                  {'Опубликовать'}
                </Button>
              </Grid>
              <Grid item={true} xs={4}>
                <Button
                  onClick={() => handleSubmitApprove(false)}
                  variant={'outlined'}
                  fullWidth={true}
                  disabled={isRegistrationLoading || isEditLoading}>
                  {'Отклонить'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
