import { FaqDTO } from '@/types/FaqDTO';
import { Grid, Skeleton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useGetFaqQuery } from '@/services/api/helpApiSlice';
import CollapseHelpText from '@/components/help/CollapseHelpText';
import React, { FC, useEffect, useState } from 'react';

export const FaqBlock: FC = () => {
  const { data: faq, isFetching: isFetching } = useGetFaqQuery();
  const [donationsFaq, setDonationsFaq] = useState<FaqDTO[]>([]);
  const [connectFaq, setConnectFaq] = useState<FaqDTO[]>([]);
  useEffect(() => {
    if (!isFetching && faq) {
      setDonationsFaq(
        faq.filter((value: FaqDTO) => value.type === 'DONATIONS')
      );
      setConnectFaq(faq.filter((value: FaqDTO) => value.type === 'CONNECT'));
    }
  }, [setDonationsFaq, setConnectFaq, isFetching, faq]);

  return (
    <Grid container={true} direction={'column'} mb={12}>
      <Grid
        item={true}
        sx={{
          background: (theme: Theme) => theme.colors.grayBackground,
          borderRadius: '6px',
          padding: '4px 8px',
          marginBottom: '12px',
        }}>
        <Typography variant={'h1'} fontSize={20}>
          {'Пожертвования'}
        </Typography>
      </Grid>
      {isFetching ? (
        <Grid container={true} direction={'column'} rowSpacing={2}>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
        </Grid>
      ) : (
        donationsFaq.map((question: FaqDTO, index: number) => {
          return (
            <Grid item={true} key={index}>
              <CollapseHelpText tittle={question.tittle}>
                <div
                  style={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{
                    __html: question.answer,
                  }}
                />
              </CollapseHelpText>
            </Grid>
          );
        })
      )}
      <Grid
        item={true}
        mt={6}
        sx={{
          background: (theme: Theme) => theme.colors.grayBackground,
          borderRadius: '6px',
          padding: '4px 8px',
          marginBottom: '12px',
        }}>
        <Typography variant={'h1'} fontSize={20}>
          {'Подключение к сервису'}
        </Typography>
      </Grid>
      {isFetching ? (
        <Grid container={true} direction={'column'} rowSpacing={2}>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
        </Grid>
      ) : (
        connectFaq.map((question: FaqDTO, index: number) => {
          return (
            <Grid item={true} key={index}>
              <CollapseHelpText tittle={question.tittle}>
                <div
                  style={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{
                    __html: question.answer,
                  }}
                />
              </CollapseHelpText>
            </Grid>
          );
        })
      )}
    </Grid>
  );
};
