import { Grid, Pagination, Skeleton } from '@mui/material';
import { TempleCard } from '@/components/temples/TempleCard';
import { TempleDTO } from '@/types/TempleDTO';
import {
  clearRegion,
  getCurrentRegion,
  getCurrentTemple,
  getPageRequest,
  setDefaultPageValues,
  setPageNumber,
} from '@/services/templesSlice';
import { getTotalNumberOfPages } from '@/utils/pagination-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetTemplesPageQuery } from '@/services/api/templesApiSlice';
import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';

interface Props {
  count?: number;
  isMobile?: boolean;
}

export const TemplesList: FC<Props> = (props: Props) => {
  const { count, isMobile } = props;
  const dispatch = useAppDispatch();
  const pageRequest = useAppSelector(getPageRequest);
  const currentRegion = useAppSelector(getCurrentRegion);
  const currentTemple = useAppSelector(getCurrentTemple);
  const { data: templesPage, isFetching } = useGetTemplesPageQuery({
    pageSize:
      isMobile && pageRequest.mobilePageSize
        ? pageRequest.mobilePageSize
        : pageRequest.pageSize,
    pageNumber: pageRequest.pageNumber,
    regionId: currentRegion ? currentRegion.id : undefined,
    templeId: currentTemple ? currentTemple.id : undefined,
  });
  const [templesList, setTemplesList] = useState<TempleDTO[] | undefined>(
    undefined
  );
  const handlePageChange = (page: number) => {
    if (pageRequest.pageNumber !== page - 1) {
      dispatch(setPageNumber(page - 1));
    }
  };
  useEffect(() => {
    setTemplesList(templesPage);
  }, [templesPage, templesList]);
  useEffect(() => {
    return () => {
      dispatch(setDefaultPageValues());
      dispatch(clearRegion());
    };
  }, [dispatch]);

  useEffect(() => {
    handlePageChange(1);
  }, [isMobile]);
  return (
    <Fragment>
      {isFetching ? (
        <Grid container={true} spacing={3}>
          {Array.from(new Array(4)).map((index) => (
            <Grid item={true} lg={3} md={4} sm={6} key={index}>
              <Skeleton
                width={'100%'}
                height={'200px'}
                variant={'rectangular'}
                sx={{ borderRadius: '6px' }}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container={true} spacing={3}>
          {templesList &&
            templesList.map((temple) => (
              <Grid item={true} lg={3} md={4} sm={6} xs={12} key={temple.id}>
                <TempleCard temple={temple} isMobile={isMobile} />
              </Grid>
            ))}
          <Grid
            container={true}
            mt={3}
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Grid item={true} display={'flex'}>
              <Pagination
                defaultPage={pageRequest.pageNumber + 1}
                count={
                  count && count > 0
                    ? getTotalNumberOfPages(
                        count,
                        isMobile && pageRequest.mobilePageSize
                          ? pageRequest.mobilePageSize
                          : pageRequest.pageSize
                      )
                    : 1
                }
                onChange={(e: ChangeEvent<unknown>, page: number) => {
                  handlePageChange(page);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};
