import { PageRequestDTO } from '@/types/PageRequestDTO';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';

export interface AdminState {
  pageRequest: PageRequestDTO;
}

const initialState: AdminState = {
  pageRequest: {
    pageNumber: 0,
    pageSize: 14,
  },
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminPageNumber(state: AdminState, action: PayloadAction<number>) {
      state.pageRequest.pageNumber = action.payload;
    },
    setAdminPageSize(state: AdminState, action: PayloadAction<number>) {
      state.pageRequest.pageSize = action.payload;
    },
    setDefaultAdminPageValues(state: AdminState) {
      state.pageRequest.pageNumber = 0;
      state.pageRequest.pageSize = 14;
    },
  },
});

export const {
  setAdminPageNumber,
  setDefaultAdminPageValues,
  setAdminPageSize,
} = adminSlice.actions;

export const getAdminPageRequest = (state: RootState): PageRequestDTO => {
  return state.admin.pageRequest;
};
