import { ButtonProps, Grid, Typography } from '@mui/material';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import { useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  to?: string;
  label: string;
  activeOnlyWhenExact?: boolean;
  onClick?: () => void;
}

export const NavigationItem: FC<Props> = (props: Props) => {
  const { to, label, activeOnlyWhenExact, onClick } = props;
  const match = useRouteMatch({
    path: to?.toString(),
    exact: activeOnlyWhenExact,
  });
  return (
    <Grid
      container={true}
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}>
      <Grid item={true}>
        {to ? (
          <RouteLink to={to}>
            <Typography
              variant={'h4'}
              fontSize={'14px'}
              lineHeight={'14px'}
              fontWeight={'normal'}
              color={(theme: Theme) =>
                match ? theme.colors.grayText : theme.colors.textColor
              }>
              {label}
            </Typography>
          </RouteLink>
        ) : (
          <Typography
            variant={'h4'}
            fontSize={'14px'}
            lineHeight={'14px'}
            fontWeight={'normal'}
            onClick={onClick}
            sx={{
              cursor: 'pointer',
            }}
            color={(theme: Theme) =>
              match ? theme.colors.grayText : theme.colors.textColor
            }>
            {label}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
