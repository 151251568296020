import { Button, Grid, Typography } from '@mui/material';
import { signOut } from '@/services/authSlice';
import { useAppDispatch } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import React, { FC, PropsWithChildren } from 'react';

interface Props {
  to: string;
  label: string;
  logout?: boolean;
}

export const AdminMenuItem: FC<Props> = (props: PropsWithChildren<Props>) => {
  const { label, to, logout, children } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleRedirect = () => {
    if (logout) {
      dispatch(signOut());
    }
    history.push(to);
  };

  return (
    <Button fullWidth={true} onClick={handleRedirect}>
      <Grid
        container={true}
        spacing={'14px'}
        alignItems={'center'}
        wrap={'nowrap'}>
        <Grid item={true}>{children}</Grid>
        <Grid item={true}>
          <Typography fontSize={'16px'} lineHeight={'24px'} color={'black'}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};
