import { ApproveRequestDTO } from '@/types/ApproveRequestDTO';
import { EditTempleDTO } from '@/types/EditTempleDTO';
import { EditTempleResponseDTO } from '@/types/EditTempleResponseDTO';
import { RegistrationRequestDTO } from '@/types/RegistrationRequestDTO';
import { RejectReasonDTO } from '@/types/form/RejectReasonDTO';
import { TempleViewDTO } from '@/types/TempleViewDTO';
import { apiSlice } from '@/services/api/apiSlice';
import { createRequest } from '@/utils/request-utils';

export const requestApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createRegistrationRequest: build.mutation<
      unknown,
      { request: RegistrationRequestDTO; images: File[] }
    >({
      query: ({ request, images }) => ({
        url: '/registration/create',
        method: 'POST',
        body: createRequest(request, images),
      }),
      invalidatesTags: ['TemplePage', 'AdminTemple'],
    }),
    getRegistrationRequest: build.query<TempleViewDTO, number | undefined>({
      query: (id: number) => ({
        url: '/registration',
        method: 'GET',
        params: {
          id: id,
        },
      }),
      providesTags: ['RequestPage'],
    }),
    getEditRequest: build.query<TempleViewDTO, number | undefined>({
      query: (templeId: number) => ({
        url: '/registration/edit-request',
        method: 'GET',
        params: {
          templeId: templeId,
        },
      }),
      providesTags: ['TemplePage'],
    }),
    editRegistrationRequest: build.mutation<
      EditTempleResponseDTO,
      { request: EditTempleDTO; images: File[] }
    >({
      query: ({ request, images }) => ({
        url: '/registration/edit',
        method: 'POST',
        body: createRequest(request, images),
      }),
      invalidatesTags: ['RequestPage', 'Regions'],
    }),
    approveRegistrationRequest: build.mutation<unknown, ApproveRequestDTO>({
      query: (dto: ApproveRequestDTO) => ({
        url: '/registration/approve',
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['TemplePage', 'AdminTemple'],
    }),
    createTempleAndUser: build.mutation<
      unknown,
      { request: RegistrationRequestDTO; images: File[] }
    >({
      query: ({ request, images }) => ({
        url: '/registration/create-temple',
        method: 'POST',
        body: createRequest(request, images),
      }),
      invalidatesTags: ['TemplePage', 'Regions', 'AdminTemple'],
    }),
    getRejectReasons: build.query<RejectReasonDTO[], void>({
      query: () => ({
        url: '/registration/reasons',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetRegistrationRequestQuery,
  useEditRegistrationRequestMutation,
  useCreateRegistrationRequestMutation,
  useApproveRegistrationRequestMutation,
  useCreateTempleAndUserMutation,
  useGetRejectReasonsQuery,
  useGetEditRequestQuery,
} = requestApiSlice;
