import { Grid } from '@mui/material';
import { MenuLink } from '@/components/common/MenuLink';
import {
  ROUTE_HELP_CONNECT,
  ROUTE_HELP_FAQ,
  ROUTE_HELP_FEEDBACK,
  ROUTE_HELP_WORK,
} from '@/app/routes';
import React, { FC } from 'react';

const HelpMenu: FC = () => {
  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true}>
        <MenuLink
          to={ROUTE_HELP_WORK}
          label={'Как работает\nсервис'}
          style={{ textAlign: 'left' }}
        />
      </Grid>
      <Grid item={true}>
        <MenuLink
          to={ROUTE_HELP_FAQ}
          label={'Часто задаваемые\nвопросы'}
          style={{ textAlign: 'left' }}
        />
      </Grid>
      <Grid item={true}>
        <MenuLink
          to={ROUTE_HELP_CONNECT}
          label={'Подключение к сервису'}
          style={{ textAlign: 'left' }}
        />
      </Grid>
      <Grid item={true}>
        <MenuLink
          to={ROUTE_HELP_FEEDBACK}
          label={'Обратная связь'}
          style={{ textAlign: 'left' }}
        />
      </Grid>
    </Grid>
  );
};

export default HelpMenu;
