import { AboutPage } from '@/components/about/AboutPage';
import { AuthenticationMobilePage } from '@/components/auth/AuthenticationMobilePage';
import { AuthenticationPage } from '@/components/auth/AuthenticationPage';
import {
  Box,
  Container,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DonationsPage } from '@/components/donations/DonationsPage';
import { Footer } from '@/components/common/Footer';
import { HelpPage } from '@/components/help/HelpPage';
import { MainPage } from '@/components/main/MainPage';
import { MobileHeader } from '@/components/common/MobileHeader';
import { Navigation } from '@/components/common/navigation/Navigation';
import { PaymentsPage } from '@/components/deacon/payments/PaymentsPage';
import { PrivateRoute } from '@/components/auth/PrivateRoute';
import { ProfilePage } from '@/components/deacon/profile/ProfilePage';
import { ROLE_DEACON } from '@/app/roles';
import {
  ROUTE_ABOUT,
  ROUTE_AUTHENTICATION,
  ROUTE_DONATIONS,
  ROUTE_HELP,
  ROUTE_HOME,
  ROUTE_PAYMENTS,
  ROUTE_PROFILE,
  ROUTE_REGISTRATION,
  ROUTE_TEMPLES,
} from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RegistrationBlock } from '@/components/registration/RegistrationBlock';
import { useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';

export const Main: FC = () => {
  const isMainPage = useRouteMatch({
    path: ROUTE_TEMPLES.toString(),
    exact: true,
  });
  const isLoginPage = useRouteMatch({
    path: ROUTE_AUTHENTICATION.toString(),
    exact: true,
  });
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  return (
    <Box
      sx={{
        position: isMainPage && 'relative',
        display: 'flex',
        flex: 0,
        minHeight: '100%',
      }}>
      <Container
        sx={(theme: Theme) => ({
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          paddingTop: '20px',
          [theme.breakpoints.down('sm')]: {
            paddingBottom: isLoginPage ? '0' : '24px',
          },
        })}>
        <Box display={'flex'} flex={1}>
          <Grid container={true} direction={'column'} wrap={'nowrap'}>
            <Grid item={true} mb={isMobile && !isMainPage ? 0.5 : '41px'}>
              {isMobile ? <MobileHeader /> : <Navigation />}
            </Grid>
            <Grid item={true} xs={true}>
              <Switch>
                <Route path={ROUTE_TEMPLES}>
                  <MainPage />
                </Route>
                <Route path={ROUTE_DONATIONS}>
                  <DonationsPage />
                </Route>
                <Route path={ROUTE_ABOUT}>
                  <AboutPage />
                </Route>
                <Route path={ROUTE_HELP}>
                  <HelpPage />
                </Route>
                <Route path={ROUTE_AUTHENTICATION}>
                  {isMobile ? (
                    <AuthenticationMobilePage />
                  ) : (
                    <AuthenticationPage />
                  )}
                </Route>
                <Route path={ROUTE_REGISTRATION}>
                  <RegistrationBlock />
                </Route>
                <PrivateRoute path={ROUTE_PROFILE} role={ROLE_DEACON}>
                  <ProfilePage />
                </PrivateRoute>
                <PrivateRoute path={ROUTE_PAYMENTS} role={ROLE_DEACON}>
                  <PaymentsPage />
                </PrivateRoute>
                <Redirect from={ROUTE_HOME} to={ROUTE_TEMPLES} />
              </Switch>
            </Grid>
            {isLoginPage && isMobile ? null : (
              <Grid item={true}>
                <Footer />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Main;
