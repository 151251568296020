import { BannerProvider } from '@/components/common/banner/BannerProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Root } from '@/components/Root';
import { persistor, store } from '@/app/store';
import { ru } from 'date-fns/locale';
import DateAdapter from '@mui/lab/AdapterDateFns';
import React, { FC } from 'react';
import theme from '@/app/theme';
import '@assets/styles/index.scss';

export const App: FC = () => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={ru}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BannerProvider>
              <Root />
            </BannerProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  );
};
