import { AdminFeedbackIcon } from '@/app/icons/admin/AdminFeedbackIcon';
import { AdminMenuItem } from '@/components/admin/AdminMenuItem';
import { AdminPanelIcon } from '@/app/icons/admin/AdminPanelIcon';
import { ExitIcon } from '@/app/icons/admin/ExitIcon';
import { Grid } from '@mui/material';
import { Logo } from '@/app/icons/Logo';
import { PaymentsRegisterIcon } from '@/app/icons/admin/PaymentsRegisterIcon';
import {
  ROUTE_ADMIN_PANEL,
  ROUTE_ADMIN_PAYMENTS,
  ROUTE_HOME,
  ROUTE_TEMPLES,
} from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import React, { FC } from 'react';

export const AdminMenu: FC = () => {
  return (
    <Grid
      container={true}
      height={'100%'}
      direction={'column'}
      justifyContent={'space-between'}>
      <Grid item={true}>
        <Grid container={true} direction={'column'}>
          <Grid item={true} alignSelf={'center'}>
            <RouteLink to={ROUTE_TEMPLES}>
              <Logo color={'primary'} />
            </RouteLink>
          </Grid>
          <Grid item={true} mt={6}>
            <AdminMenuItem label={'На главную'} to={ROUTE_HOME}>
              <AdminFeedbackIcon style={{ display: 'block' }} />
            </AdminMenuItem>
          </Grid>
          <Grid item={true}>
            <AdminMenuItem
              label={'Панель администратора'}
              to={ROUTE_ADMIN_PANEL}>
              <AdminPanelIcon style={{ display: 'block' }} />
            </AdminMenuItem>
          </Grid>
          <Grid item={true}>
            <AdminMenuItem label={'Реестр платежей'} to={ROUTE_ADMIN_PAYMENTS}>
              <PaymentsRegisterIcon style={{ display: 'block' }} />
            </AdminMenuItem>
          </Grid>
          {/*<Grid item={true}>*/}
          {/*  <AdminMenuItem label={'Обратная связь'} to={ROUTE_ADMIN_FEEDBACK}>*/}
          {/*    <AdminFeedbackIcon />*/}
          {/*  </AdminMenuItem>*/}
          {/*</Grid>*/}
          {/*<Grid item={true}>*/}
          {/*  <AdminMenuItem*/}
          {/*    label={'Редактирование страниц'}*/}
          {/*    to={ROUTE_ADMIN_EDIT_TEMPLE}>*/}
          {/*    <EditPagesIcon />*/}
          {/*  </AdminMenuItem>*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
      <Grid item={true}>
        <Grid item={true}>
          <AdminMenuItem label={'Выйти'} to={ROUTE_HOME} logout={true}>
            <ExitIcon style={{ display: 'block' }} />
          </AdminMenuItem>
        </Grid>
      </Grid>
    </Grid>
  );
};
