import { RootState } from '@/app/store';
import { config } from '@/app/config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

export const baseQuery = fetchBaseQuery({
  baseUrl: config.baseUrl,
  prepareHeaders: (headers: Headers, { getState, endpoint }) => {
    const state: RootState = getState() as RootState;
    const token: string | undefined = state.auth.user?.token;
    if (
      token &&
      endpoint != 'getAddressesSuggestions' &&
      endpoint != 'setNewPassword'
    ) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: [
    'Regions',
    'Temples',
    'TempleEditPage',
    'TemplePage',
    'RequestPage',
    'AdminTemple',
  ],
  endpoints: () => ({}),
});
