import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const BackIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={
        'M3 12.4136C3 12.6699 3.11279 12.916 3.31787 13.1108L9.74707 19.5298C9.95215 19.7246 10.1777 19.8169 10.4238 19.8169C10.9468 19.8169 11.3364 19.4375 11.3364 18.9146C11.3364 18.6582 11.2441 18.4224 11.0801 18.2583L8.90625 16.0435L5.77881 13.2031L8.10645 13.3467H20.1958C20.7495 13.3467 21.1289 12.957 21.1289 12.4136C21.1289 11.8599 20.7495 11.4702 20.1958 11.4702H8.10645L5.78906 11.6138L8.90625 8.77344L11.0801 6.55859C11.2441 6.39453 11.3364 6.15869 11.3364 5.90234C11.3364 5.37939 10.9468 5 10.4238 5C10.1777 5 9.95215 5.09229 9.72656 5.30762L3.31787 11.7061C3.11279 11.9009 3 12.147 3 12.4136Z'
      }
    />
  </SvgIcon>
);
