import {
  OutlinedField,
  OutlinedFieldProps,
} from '@/components/common/field/OutlinedField';
import { PhoneNumberInput } from '@/components/common/field/PhoneNumberInput';
import React, { FC } from 'react';

type Props = OutlinedFieldProps;

export const PhoneNumberField: FC<Props> = (props: Props) => {
  return <OutlinedField {...props} inputComponent={PhoneNumberInput} />;
};
