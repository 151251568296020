import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const VerticalDividerIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 1 24'}
    sx={{ width: '1px', height: '24px' }}
    {...props}>
    <rect width={'1'} height={'24'} fill={'#e2e1e5'} />
  </SvgIcon>
);
