import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const EyeCrossedIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={
        'M16.8413 16.8783C17.0254 17.0624 17.3174 17.0751 17.5015 16.8783C17.6982 16.6815 17.6855 16.4022 17.5015 16.2181L7.41504 6.13806C7.23096 5.95398 6.93262 5.95398 6.74854 6.13806C6.5708 6.3158 6.5708 6.62048 6.74854 6.79822L16.8413 16.8783ZM17.4062 15.1073C19.1582 13.9457 20.2944 12.3524 20.2944 11.6415C20.2944 10.4227 16.9746 6.52527 12.1504 6.52527C11.1284 6.52527 10.1763 6.69666 9.30664 6.98865L10.8936 8.57556C11.2808 8.41052 11.7061 8.32166 12.1504 8.32166C13.9976 8.32166 15.4893 9.78796 15.4893 11.6415C15.4893 12.0858 15.394 12.5175 15.2163 12.892L17.4062 15.1073ZM12.1504 16.7577C13.2612 16.7577 14.2832 16.5609 15.1909 16.2245L13.5913 14.6249C13.1597 14.847 12.6709 14.9677 12.1504 14.9677C10.2969 14.9677 8.81787 13.4506 8.81152 11.6415C8.81152 11.1146 8.93213 10.6195 9.14795 10.1815L7.05957 8.08044C5.17432 9.26746 4 10.9242 4 11.6415C4 12.8539 7.3833 16.7577 12.1504 16.7577ZM14.0674 11.5145C14.0674 10.4672 13.2231 9.61658 12.1631 9.61658C12.0869 9.61658 12.0107 9.61658 11.9473 9.62927L14.0547 11.7367C14.061 11.6669 14.0674 11.5907 14.0674 11.5145ZM10.2334 11.5209C10.2334 12.5682 11.103 13.4252 12.1504 13.4252C12.2266 13.4252 12.3027 13.4188 12.3853 13.4125L10.2461 11.2733C10.2397 11.3558 10.2334 11.4384 10.2334 11.5209Z'
      }
    />
  </SvgIcon>
);
