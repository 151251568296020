import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { WarningIcon } from '@/app/icons/WarningIcon';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  isMobile?: boolean;
}

export const DeaconEditDialog: FC<Props> = (props: Props) => {
  const { open, onClose, onConfirm, isMobile } = props;
  return (
    <Dialog
      open={open}
      scroll={isMobile ? 'paper' : 'body'}
      onClose={onClose}
      PaperProps={{
        sx: (theme: Theme) => ({
          padding: theme.spacing(6, 8),
          overflowY: 'unset',
          width: theme.spacing(75),
          [theme.breakpoints.down('sm')]: {
            minWidth: '300px',
            width: '100%',
            padding: theme.spacing(2),
          },
        }),
      }}
      BackdropProps={{ sx: { backgroundColor: 'rgba(0,0,0,0.8)' } }}>
      <DialogTitle
        sx={(theme: Theme) => ({
          marginBottom: theme.spacing(3),
        })}>
        <Grid container={true} justifyContent={'flex-end'} wrap={'nowrap'}>
          <Grid
            item={true}
            sx={(theme: Theme) => ({
              marginTop: theme.spacing(-10.5),
              marginRight: theme.spacing(-9.5),
              [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(-7.5),
                marginRight: theme.spacing(-3),
              },
            })}>
            <IconButton disableRipple={true} onClick={onClose}>
              <CloseIcon sx={{ color: 'white', opacity: '0.5' }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container={true}
          direction={'column'}
          wrap={'nowrap'}
          rowSpacing={isMobile ? 2 : 4}>
          <Grid item={true}>
            <Grid
              container={true}
              justifyContent={'center'}
              alignItems={'center'}>
              <Grid item={true}>
                <WarningIcon style={{ display: 'block' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid
              container={true}
              justifyContent={'center'}
              alignItems={'center'}>
              <Grid item={true}>
                <Typography variant={'h2'} align={'center'}>
                  {
                    'После отправки запроса на редактирование, церковь будет не доступна на все время, пока администрация не подтвердит изменения.'
                  }
                </Typography>
                <Typography variant={'h2'} align={'center'}>
                  {'Вы уверены?'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid
              container={true}
              justifyContent={'space-between'}
              alignItems={'center'}
              rowSpacing={2}
              columnSpacing={3}>
              <Grid item={true} sm={6} xs={12}>
                <LoadingButton
                  variant={'contained'}
                  fullWidth={true}
                  onClick={onConfirm}>
                  {'Отправить'}
                </LoadingButton>
              </Grid>
              <Grid item={true} sm={6} xs={12}>
                <Button variant={'outlined'} fullWidth={true} onClick={onClose}>
                  {'Отмена'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
