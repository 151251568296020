import { CreateFeedbackDTO } from '@/types/CreateFeedbackDTO';
import { FaqDTO } from '@/types/FaqDTO';
import { FeedbackDTO } from '@/types/FeedbackDTO';
import { HelpContentDTO } from '@/types/HelpContentDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const helpApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFaq: build.query<FaqDTO[], void>({
      query: () => ({
        url: '/help/faq',
        method: 'GET',
      }),
    }),
    createFeedback: build.mutation<FeedbackDTO, CreateFeedbackDTO>({
      query: (dto: CreateFeedbackDTO) => ({
        url: '/help/feedback/create',
        method: 'POST',
        body: dto,
      }),
    }),
    getItWorkContent: build.query<HelpContentDTO[], void>({
      query: () => ({
        url: '/help/how-it-work',
        method: 'GET',
      }),
    }),
    getConnectContent: build.query<HelpContentDTO, void>({
      query: () => ({
        url: '/help/how-to-connect',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetFaqQuery,
  useCreateFeedbackMutation,
  useGetConnectContentQuery,
  useGetItWorkContentQuery,
} = helpApiSlice;
