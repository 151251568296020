import * as Yup from 'yup';
import { ApproveRequestDTO } from '@/types/ApproveRequestDTO';
import { Button, Grid, MenuItem, Theme, Typography } from '@mui/material';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { MESSAGE_REQUIRED } from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { useGetRejectReasonsQuery } from '@/services/api/requestApiSlice';
import React, { ChangeEvent, FC } from 'react';

interface Props {
  idRequest: number;
  handleClose: () => void;
  handleSubmit: (
    values: ApproveRequestDTO,
    helpers?: FormikHelpers<ApproveRequestDTO>
  ) => Promise<void>;
  loading?: boolean;
}

export const AdminRejectForm: FC<Props> = (props: Props) => {
  const { idRequest, handleSubmit, loading, handleClose } = props;
  const {
    data: reasons,
    isLoading: isReasonsLoading,
    isFetching: isReasonsFetching,
  } = useGetRejectReasonsQuery();

  const initialValues: ApproveRequestDTO = {
    idRequest: idRequest,
    isApprove: false,
  };

  const validationSchema = Yup.object({
    rejectReasonId: Yup.number().required(MESSAGE_REQUIRED),
  });

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {(formikProps: FormikProps<ApproveRequestDTO>) => {
        const { values, errors, submitForm, setFieldValue } = formikProps;
        const handleSetFieldValue = (
          field: string,
          value: string | unknown,
          isErrorField: boolean
        ) => {
          setFieldValue(field, value, isErrorField);
        };

        return (
          <Grid container={true} direction={'column'}>
            <Grid item={true} mb={3}>
              <OutlinedSelect
                displayEmpty={true}
                label={'Причина'}
                name={'rejectReasonId'}
                withLabel={true}
                defaultValue={-1}
                disabled={isReasonsFetching || isReasonsLoading}
                value={values.rejectReasonId}
                placeholder={'Не выбрано'}
                error={Boolean(errors.rejectReasonId)}
                helperText={errors.rejectReasonId}
                onChange={(event) => {
                  handleSetFieldValue(
                    event.target.name,
                    event.target.value,
                    Boolean(errors.rejectReasonId)
                  );
                }}>
                {reasons &&
                  reasons.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
              </OutlinedSelect>
            </Grid>
            <Grid item={true} mb={3}>
              <OutlinedField
                disabled={loading}
                required={true}
                name={'comment'}
                label={'Коментарий'}
                withLabel={true}
                value={values.comment}
                multiline={true}
                minRows={6}
                isNotAbsoluteHelperText={true}
                placeholder={'Опишите причину отказа'}
                error={Boolean(errors.comment)}
                helperText={errors.comment}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleSetFieldValue(
                    e.target.name,
                    e.target.value,
                    Boolean(errors.comment)
                  );
                }}
              />
            </Grid>
            <Grid item={true}>
              <Grid container={true} columnSpacing={3}>
                <Grid item={true} xs={true}>
                  <Button
                    variant={'contained'}
                    disabled={loading}
                    fullWidth={true}
                    onClick={submitForm}>
                    {'Отклонить'}
                  </Button>
                </Grid>
                <Grid item={true} xs={true}>
                  <Button
                    variant={'outlined'}
                    fullWidth={true}
                    disabled={loading}
                    onClick={handleClose}>
                    {'Назад'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};
