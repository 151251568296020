import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { CloseDonationModalIcon } from '@/app/icons/CloseDonationModalIcon';
import { ROUTE_HOME } from '@/app/routes';
import { formatCurrency } from '@/utils/string-utils';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  templeName: string;
  donationAmount: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  isMobile?: boolean;
}

export const SuccessDonationDialog: FC<Props> = (props: Props) => {
  const { templeName, donationAmount, open, setOpen, isMobile } = props;
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
    history.replace(ROUTE_HOME);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={isMobile ? 'paper' : 'body'}
      PaperProps={{
        sx: (theme: Theme) => ({
          overflowY: 'unset',
          [theme.breakpoints.down('sm')]: {
            minWidth: '300px',
            width: '100%',
          },
        }),
      }}
      BackdropProps={{ sx: { backgroundColor: 'rgba(0,0,0,0.8)' } }}>
      <DialogTitle sx={{ marginTop: '19px', marginRight: '19px' }}>
        <Grid container={true} justifyContent={'flex-end'} wrap={'nowrap'}>
          <Grid item={true}>
            <Button
              onClick={handleClose}
              sx={{
                margin: 0,
                padding: 0,
                maxWidth: '19px',
                minWidth: '19px',
              }}>
              <CloseDonationModalIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ margin: '-14px 24px 40px' }}>
        <Grid container={true} direction={'column'} wrap={'nowrap'}>
          <Grid item={true}>
            <Typography fontSize={'24px'} fontWeight={500} lineHeight={'32px'}>
              {'Благодарим вас! 😊'}
            </Typography>
          </Grid>
          <Grid item={true} mt={4}>
            <Typography fontSize={'36px'} fontWeight={500} lineHeight={'24px'}>
              {formatCurrency(donationAmount, 0)}
            </Typography>
          </Grid>
          <Grid item={true} mt={2.5}>
            <Typography fontSize={'16px'} lineHeight={'24px'}>
              {`Для ${templeName}\nотправлено`}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
