import { Grid } from '@mui/material';
import { Logo } from '@/app/icons/Logo';
import { NavigationItem } from '@/components/common/navigation/NavigationItem';
import { NavigationItemButton } from '@/components/common/navigation/NavigationItemButton';
import { ROLE_ADMIN, ROLE_DEACON } from '@/app/roles';
import {
  ROUTE_ABOUT,
  ROUTE_ADMIN,
  ROUTE_AUTHENTICATION,
  ROUTE_DONATIONS,
  ROUTE_HELP,
  ROUTE_HOME,
  ROUTE_PAYMENTS,
  ROUTE_PROFILE,
  ROUTE_TEMPLES,
} from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import { UserDTO } from '@/types/user/UserDTO';
import { getCurrentUser, signOut } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import React, { FC, Fragment } from 'react';

export const Navigation: FC = () => {
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleClickLogout = (): void => {
    history.push(ROUTE_HOME);
    dispatch(signOut());
  };
  return (
    <Grid
      id={'top'}
      container={true}
      justifyContent={'space-between'}
      columnSpacing={1}
      alignItems={'center'}
      wrap={'nowrap'}>
      <Grid item={true} display={'flex'} zIndex={10}>
        <RouteLink to={ROUTE_TEMPLES}>
          <Logo color={'primary'} />
        </RouteLink>
      </Grid>
      <Grid item={true}>
        <Grid
          container={true}
          alignItems={{ md: 'center', sm: 'flex-end' }}
          spacing={{ md: 7, sm: 3 }}
          justifyContent={'flex-end'}
          direction={{ md: 'row', sm: 'column' }}>
          <Grid item={true} mt={{ md: 0, sm: 2 }}>
            <Grid
              container={true}
              spacing={3}
              alignItems={'center'}
              justifyContent={'flex-end'}>
              <Grid item={true}>
                <NavigationItem to={ROUTE_TEMPLES} label={'Главная'} />
              </Grid>
              <Grid item={true}>
                <NavigationItem to={ROUTE_DONATIONS} label={'Пожертвования'} />
              </Grid>
              <Grid item={true}>
                <NavigationItem to={ROUTE_ABOUT} label={'О портале'} />
              </Grid>
              <Grid item={true}>
                <NavigationItem to={ROUTE_HELP} label={'Помощь'} />
              </Grid>
              {authUser && authUser.roles.indexOf(ROLE_DEACON) > -1 && (
                <Grid item={true} md={'auto'} sm={12}>
                  <Grid
                    container={true}
                    spacing={3}
                    justifyContent={'flex-end'}>
                    <Grid item={true}>
                      <NavigationItem to={ROUTE_PROFILE} label={'Профиль'} />
                    </Grid>
                    <Grid item={true}>
                      <NavigationItem
                        to={ROUTE_PAYMENTS}
                        label={'Реестр платежей'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {authUser && authUser.roles.indexOf(ROLE_ADMIN) > -1 && (
                <Fragment>
                  <Grid item={true}>
                    <NavigationItem
                      to={ROUTE_ADMIN}
                      label={'Администрирование'}
                    />
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Grid>
          <Grid item={true}>
            {authUser ? (
              <NavigationItemButton
                label={'Выйти'}
                onClick={handleClickLogout}
              />
            ) : (
              <NavigationItemButton
                to={ROUTE_AUTHENTICATION}
                label={'Войти / Подключиться к сервису'}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
