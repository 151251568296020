import { DA_DATA_TOKEN, DA_DATA_URL } from '@/utils/request-utils';
import { DaDataResponseDTO } from '@/types/form/DaDataResponseDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const daDataApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAddressesSuggestions: build.query<DaDataResponseDTO, string>({
      query: (value: string) => ({
        url: DA_DATA_URL,
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + DA_DATA_TOKEN,
        },
        body: { query: value },
      }),
    }),
  }),
});

export const { useGetAddressesSuggestionsQuery } = daDataApiSlice;
