import { Grid, Theme, Typography } from '@mui/material';
import InputMask from 'react-input-mask';
import React, { FC } from 'react';
import theme from '@/app/theme';

interface Props {
  label: string;
  content: string;
  mask?: string;
}

export const AdminPreviewInfoLabel: FC<Props> = (props: Props) => {
  const { label, content, mask } = props;
  return (
    <Grid container={true} direction={'column'} rowSpacing={0.5}>
      <Grid item={true}>
        <Typography
          variant={'label1'}
          color={(theme: Theme) => theme.colors.grayText}>
          {label}
        </Typography>
      </Grid>
      <Grid item={true}>
        {mask ? (
          <InputMask
            mask={mask}
            value={content}
            disabled={true}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              padding: '0px',
              fontSize: '16px',
              lineHeight: '24px',
              color: theme.colors.textColor,
              width: '100%',
            }}
          />
        ) : (
          <Typography variant={'body1'}>{content}</Typography>
        )}
      </Grid>
    </Grid>
  );
};
