import { ReCaptchaDTO } from '@/types/ReCaptchaDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const staticApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCaptchaKey: build.mutation<ReCaptchaDTO, void>({
      query: () => ({
        url: '/static/captcha/key',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCaptchaKeyMutation } = staticApiSlice;
