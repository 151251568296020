import { DonationCard } from '@/components/donations/card/DonationCard';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { MobileWidthContainer } from '@/components/common/MobileWidthContainer';
import { ROUTE_TEMPLES } from '@/app/routes';
import { TempleInfo } from '@/components/temples/TempleInfo';
import { TempleViewType } from '@/types/filter/TempleViewType';
import { useGetTempleQuery } from '@/services/api/templesApiSlice';
import { useParams } from 'react-router-dom';
import NavigatePreviousStep from '@/components/common/NavigatePreviousStep';
import React, { FC, useEffect, useRef } from 'react';

interface Params {
  id: string;
  donate?: TempleViewType;
}

export const TemplePage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const { id, donate } = useParams<Params>();
  const templeId = Number(id);
  const {
    data: temple,
    isLoading: isTempleLoading,
    isFetching: isTempleFetching,
  } = useGetTempleQuery(templeId);

  const donationCardRef = useRef<HTMLDivElement>(null);
  const tittleTempleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (donate === TempleViewType.DONATE && donationCardRef?.current) {
      donationCardRef.current.scrollIntoView({ block: 'end' });
    } else {
      if (tittleTempleRef?.current) {
        tittleTempleRef.current.scrollIntoView();
      }
    }
  }, [donate, donationCardRef]);

  return (
    <Grid container={true} direction={'column'} spacing={2} mb={12}>
      <Grid item={true} mt={isMobile ? 6 : 2}>
        <NavigatePreviousStep to={ROUTE_TEMPLES}>
          {'К списку церквей'}
        </NavigatePreviousStep>
      </Grid>
      <Grid item={true} ref={tittleTempleRef} style={{ maxWidth: '100%' }}>
        <TempleInfo
          temple={temple}
          isTempleLoading={isTempleLoading}
          isTempleFetching={isTempleFetching}
          isMobile={isMobile}
        />
      </Grid>
      <Grid
        item={true}
        mt={isMobile ? 2 : 7}
        ref={donationCardRef}
        style={{ maxWidth: '100%' }}>
        {isMobile ? (
          <MobileWidthContainer>
            <DonationCard
              fromTemplePage={true}
              temple={temple}
              isMobile={isMobile}
            />
          </MobileWidthContainer>
        ) : (
          <DonationCard
            fromTemplePage={true}
            temple={temple}
            isMobile={isMobile}
          />
        )}
      </Grid>
    </Grid>
  );
};
