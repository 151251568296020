import { Button, ButtonProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  to?: string;
  label: string;
  activeOnlyWhenExact?: boolean;
  onClick?: () => void;
}

export const NavigationItemButton: FC<Props> = (props: Props) => {
  const { to, label, activeOnlyWhenExact, onClick } = props;
  const history = useHistory();
  const match = useRouteMatch({
    path: to?.toString(),
    exact: activeOnlyWhenExact,
  });
  return (
    <Button
      variant={'outlined'}
      color={'inherit'}
      sx={(theme: Theme) => ({
        padding: theme.spacing(1.25, 3),
        color: match ? theme.colors.grayText : theme.colors.textColor,
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '50px',
      })}
      onClick={() => {
        if (onClick) {
          onClick();
        } else if (to) {
          history.push(to);
        }
      }}>
      <Typography
        variant={'h4'}
        fontSize={'14px'}
        lineHeight={'14px'}
        fontWeight={'normal'}>
        {label}
      </Typography>
    </Button>
  );
};
