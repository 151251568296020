import { Comment } from '@mui/icons-material';
import { Divider, Grid, Theme, Tooltip, Typography } from '@mui/material';
import { DonationDTO } from '@/types/DonationDTO';
import {
  formatCurrency,
  formatDonationName,
  formatStatus,
} from '@/utils/string-utils';
import React, { FC, Fragment } from 'react';
import moment from 'moment';

interface Props {
  donationsList: DonationDTO[] | undefined;
}

export const PaymentMobileTableContainer: FC<Props> = (props: Props) => {
  const { donationsList } = props;
  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'}>
      <Grid
        item={true}
        sx={{ marginLeft: '-16px', width: 'calc(100% + 32px)' }}>
        <Divider />
      </Grid>
      {donationsList?.map((donation, index) => (
        <Fragment key={index}>
          <Grid item={true} style={{ maxWidth: '100%' }}>
            <Grid container={true} direction={'column'} wrap={'nowrap'}>
              <Grid item={true} pt={1.5}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true}>
                    <Typography
                      variant={'body1'}
                      fontSize={'14px'}
                      color={(theme: Theme) => theme.colors.grayText}>
                      {moment(donation.date).format('DD.MM.YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Tooltip
                      title={donation.comment}
                      placement={'bottom-start'}
                      sx={{ maxWidth: '180px' }}
                      enterTouchDelay={100}>
                      <Comment sx={{ display: 'block' }} />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} pt={0.75}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true}>
                    <Typography
                      variant={'body1'}
                      fontSize={'14px'}
                      color={(theme: Theme) => theme.colors.grayText}>
                      {formatStatus(donation.status)}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography
                      variant={'body1'}
                      fontSize={'14px'}
                      fontWeight={500}>
                      {formatCurrency(donation.amount, 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item={true}
                pt={0.75}
                pb={1.5}
                style={{ maxWidth: '100%' }}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <Typography
                  variant={'body1'}
                  fontSize={'14px'}
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {donation.isAnonymous || !donation.name
                    ? 'Анонимно'
                    : formatDonationName(donation.name)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item={true}
            sx={{ marginLeft: '-16px', width: 'calc(100% + 32px)' }}>
            <Divider />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};
