import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const FilterIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={
        'M12.0892 18.0156C12.0892 18.223 12.2556 18.3906 12.4618 18.3906H15.5368C15.7431 18.3906 15.9095 18.223 15.9095 18.0156V15.7188H12.0892V18.0156ZM18.313 10H9.68564C9.39853 10 9.21924 10.3129 9.36338 10.5625L11.9567 14.9688H16.0442L18.6376 10.5625C18.7794 10.3129 18.6001 10 18.313 10Z'
      }
      fill={'#a9abb2'}
    />
  </SvgIcon>
);
