import { Middleware, MiddlewareAPI, isRejected } from '@reduxjs/toolkit';
import { signOut } from '@/services/authSlice';

export const unauthorizedMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejected(action)) {
      if (action?.payload?.status === 401 || action?.payload?.status === 403) {
        api.dispatch(signOut());
      }
    }
    return next(action);
  };
