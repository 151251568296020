import { Checkbox, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { CheckboxCheckedIcon } from '@/app/icons/CheckboxCheckedIcon';
import { CheckboxIcon } from '@/app/icons/CheckboxIcon';
import { Theme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC, MouseEvent, useState } from 'react';
import theme from '@/app/theme';

interface Props {
  attachedFile?: File;
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  fullWidth?: boolean;
  isAdminPage?: boolean;
  isMain: boolean;
  setMainPhoto: (index: number) => void;
  index: number;
  disabled?: boolean;
}

export const AttachFilePanel: FC<Props> = (props: Props) => {
  const {
    attachedFile,
    onClear,
    fullWidth,
    isAdminPage,
    isMain,
    setMainPhoto,
    index,
    disabled,
  } = props;
  const [hover, setHover] = useState<boolean>(false);

  const handleChange = (): void => {
    setMainPhoto(index);
  };
  return (
    <Grid
      container={true}
      justifyContent={'space-between'}
      alignItems={'center'}
      wrap={'nowrap'}
      onMouseEnter={() => setHover(!disabled)}
      onMouseLeave={() => setHover(false)}
      sx={(theme: Theme) => ({
        background: isAdminPage
          ? theme.colors.attachFileBackground
          : theme.colors.grayBackground,
        borderRadius: '6px',
        width: fullWidth ? '100%' : theme.spacing(30),
        border: '2px solid',
        borderColor:
          hover || isMain
            ? theme.colors.primary
            : isAdminPage
            ? theme.colors.grayBackground
            : theme.colors.white,
        ':hover': {
          cursor: 'pointer',
        },
      })}>
      <Grid
        item={true}
        sx={() => ({
          borderRadius: '6px 0px 0px 6px',
        })}>
        <Grid
          container={true}
          direction={'column'}
          sx={{ borderRadius: '6px 0px 0px 6px' }}>
          {(hover || isMain) && (
            <Grid item={true} mt={-0.25} ml={-0.25}>
              <Tooltip
                hidden={disabled}
                title={'Сделать главной'}
                disableInteractive={true}
                placement={'top-start'}
                arrow={true}>
                <Checkbox
                  disableRipple={true}
                  checked={isMain}
                  onChange={handleChange}
                  sx={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                  disabled={disabled}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              </Tooltip>
            </Grid>
          )}
          <Grid
            item={true}
            mt={hover || isMain ? -2.75 : 0}
            sx={{ borderRadius: '6px 0px 0px 6px' }}>
            <img
              src={attachedFile ? URL.createObjectURL(attachedFile) : ''}
              style={{
                display: 'flex',
                width: '48px',
                height: '48px',
                borderRight: '2px solid',
                borderRadius: '6px 0px 0px 6px',
                borderColor:
                  hover || isMain
                    ? theme.colors.primary
                    : isAdminPage
                    ? theme.colors.attachFileBackground
                    : theme.colors.grayBackground,
              }}
              alt={attachedFile ? attachedFile.name : ''}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} ml={1} xs={7} sx={{ overflow: 'hidden' }}>
        <Typography
          variant={'body1'}
          sx={{
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}>
          {attachedFile?.name}
        </Typography>
      </Grid>
      {onClear && (
        <Grid item={true} mr={1.5}>
          <IconButton
            disableRipple={true}
            onClick={onClear}
            disabled={disabled}>
            <CloseIcon
              color={'primary'}
              fontSize={'small'}
              sx={{ display: 'block' }}
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
