import {
  ROUTE_REGISTRATION_FORM,
  ROUTE_REGISTRATION_PASSWORD,
  ROUTE_REGISTRATION_RESTORE,
  ROUTE_REGISTRATION_RESTORE_SUCCESS,
  ROUTE_REGISTRATION_SUCCESS,
} from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RegistrationChangePasswordPage } from '@/components/registration/RegistrationChangePasswordPage';
import { RegistrationPage } from '@/components/registration/RegistrationPage';
import { RegistrationRestorePage } from '@/components/registration/RegistrationRestorePage';
import { RegistrationRestoreSuccessPage } from '@/components/registration/RegistrationRestoreSuccessPage';
import { RegistrationSuccessPage } from '@/components/registration/RegistrationSuccessPage';
import React, { FC } from 'react';

export const RegistrationBlock: FC = () => {
  return (
    <Switch>
      <Route exact={true} path={ROUTE_REGISTRATION_FORM}>
        <RegistrationPage />
      </Route>
      <Route exact={true} path={ROUTE_REGISTRATION_SUCCESS}>
        <RegistrationSuccessPage />
      </Route>
      <Route path={ROUTE_REGISTRATION_PASSWORD}>
        <RegistrationChangePasswordPage />
      </Route>
      <Route path={ROUTE_REGISTRATION_RESTORE}>
        <RegistrationRestorePage />
      </Route>
      <Route path={ROUTE_REGISTRATION_RESTORE_SUCCESS}>
        <RegistrationRestoreSuccessPage />
      </Route>
      <Redirect to={ROUTE_REGISTRATION_FORM} />
    </Switch>
  );
};
