import { AttachFileDTO } from '@/types/AttachFileDTO';
import { Box } from '@mui/material';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TempleImageView } from '@/components/modal/TempleImageView';
import { TempleMobileImageView } from '@/components/modal/TempleMobileImageView';
import React, { FC, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';

interface Props {
  attachFiles: AttachFileDTO[];
}

export const TempleMobileSwiper: FC<Props> = (props: Props) => {
  const { attachFiles } = props;
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const delay = 1000;
  let startPress: number | null = null;

  const touchDown = () => {
    startPress = Date.now();
  };

  const touchUp = () => {
    if (startPress && Date.now() - startPress < delay) {
      setOpenDialog(true);
    }
  };
  return (
    <Box
      sx={{
        overflow: 'hidden',
        maxWidth: '100%',
        height: '250px',
      }}>
      <Swiper
        loop={true}
        pagination={{
          clickable: true,
        }}
        onSlideChange={(swiper) => setSelectedImage(swiper.realIndex)}
        modules={[Pagination]}
        className={'templeMobileSwiper'}>
        {attachFiles.map((image, index) => (
          <SwiperSlide
            key={index}
            onTouchStart={touchDown}
            onTouchEnd={touchUp}>
            <img
              src={`data:image/jpeg;base64,${image.content}`}
              alt={'slide-image'}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'cover' }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <TempleMobileImageView
        open={openDialog}
        images={attachFiles}
        selectedImage={selectedImage}
        onClose={() => setOpenDialog(false)}
      />
    </Box>
  );
};
