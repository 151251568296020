export const ADMIN_DAYS_PERIOD = 30;

export const getStartDateRange = (startDate: Date): Date => {
  startDate.setHours(0, 0, 0, 0);
  return startDate;
};

export const getEndDateRange = (endDate: Date): Date => {
  endDate.setHours(23, 59, 59, 999);
  return endDate;
};

export const getStartDate = (): Date => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - ADMIN_DAYS_PERIOD);
  return getStartDateRange(startDate);
};

export const dateToUTC = (date: Date): Date => {
  const newDate = new Date();
  newDate.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return newDate;
};
