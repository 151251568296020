import { AlertIcon } from '@/app/icons/AlertIcon';
import { Grid, Typography } from '@mui/material';
import { InfoIcon } from '@/app/icons/InfoIcon';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { FC, PropsWithChildren } from 'react';

const useLetterAlertStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '12px 16px',
      background: theme.colors.alert,
      borderRadius: '6px',
    },
  })
);

interface Props {
  error?: boolean;
}

export const InfoAlert: FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
) => {
  const { children, error } = props;
  const classes = useLetterAlertStyles();
  return (
    <Grid
      container={true}
      alignItems={'flex-start'}
      sx={{
        padding: '12px 16px',
        borderRadius: '6px',
        background: (theme: Theme) =>
          error ? theme.colors.redBackground : theme.colors.alert,
        color: (theme: Theme) =>
          error ? theme.colors.red : theme.colors.textColor,
      }}
      className={classes.container}>
      <Grid item={true} mr={1}>
        {error ? (
          <AlertIcon sx={{ display: 'block' }} />
        ) : (
          <InfoIcon sx={{ display: 'block' }} />
        )}
      </Grid>
      <Grid item={true} xs={true}>
        <Typography variant={'body1'} component={'div'}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};
