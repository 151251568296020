import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const WarningIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 80 80'}
    sx={{ width: '80px', height: '80px' }}
    {...props}>
    <path
      d={
        'M74.6621 66.875L42.1621 10.625C41.6778 9.78906 40.8418 9.375 39.9981 9.375C39.1543 9.375 38.3106 9.78906 37.834 10.625L5.33401 66.875C4.37307 68.5469 5.5762 70.625 7.49807 70.625H72.4981C74.4199 70.625 75.6231 68.5469 74.6621 66.875ZM37.4981 32.5C37.4981 32.1562 37.7793 31.875 38.1231 31.875H41.8731C42.2168 31.875 42.4981 32.1562 42.4981 32.5V46.875C42.4981 47.2188 42.2168 47.5 41.8731 47.5H38.1231C37.7793 47.5 37.4981 47.2188 37.4981 46.875V32.5ZM39.9981 60C39.0168 59.98 38.0824 59.5761 37.3955 58.875C36.7086 58.1739 36.3238 57.2315 36.3238 56.25C36.3238 55.2685 36.7086 54.3261 37.3955 53.625C38.0824 52.9239 39.0168 52.52 39.9981 52.5C40.9794 52.52 41.9137 52.9239 42.6007 53.625C43.2876 54.3261 43.6723 55.2685 43.6723 56.25C43.6723 57.2315 43.2876 58.1739 42.6007 58.875C41.9137 59.5761 40.9794 59.98 39.9981 60Z'
      }
      fill={'#ffaf37'}
    />
  </SvgIcon>
);
