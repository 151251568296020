import { AdminDonationPageDTO } from '@/types/AdminDonationPageDTO';
import { CreateDonationDTO } from '@/types/CreateDonationDTO';
import { CreateDonationResponseDTO } from '@/types/CreateDonationResponseDTO';
import { DonationDTO } from '@/types/DonationDTO';
import { DonationPageDTO } from '@/types/DonationPageDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const donationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getDonationsCount: build.query<
      number,
      { regionId?: number; templeId?: number }
    >({
      query: ({ regionId, templeId }) => ({
        url: '/donation/count',
        method: 'GET',
        params: {
          regionId: regionId,
          templeId: templeId,
        },
      }),
    }),
    getDonationsPage: build.query<
      DonationPageDTO,
      {
        pageSize: number;
        pageNumber: number;
        regionId?: number;
        templeId?: number;
      }
    >({
      query: ({ pageSize, pageNumber, regionId, templeId }) => ({
        url: '/donation/page',
        method: 'GET',
        params: {
          pageSize: pageSize,
          pageNumber: pageNumber,
          regionId: regionId,
          templeId: templeId,
        },
      }),
    }),
    getAdminDonationsPage: build.query<
      AdminDonationPageDTO,
      {
        pageSize: number;
        pageNumber: number;
        regionId?: number;
        templeId?: number;
        dateStart: number | null;
        dateEnd: number | null;
      }
    >({
      query: ({
        pageSize,
        pageNumber,
        regionId,
        templeId,
        dateStart,
        dateEnd,
      }) => ({
        url: '/donation/admin/page',
        method: 'GET',
        params: {
          pageSize: pageSize,
          pageNumber: pageNumber,
          regionId: regionId,
          templeId: templeId,
          dateStartTime: dateStart ? dateStart : '',
          dateEndTime: dateEnd ? dateEnd : '',
        },
      }),
    }),
    getLastDonations: build.query<DonationDTO[], void>({
      query: () => ({
        url: '/donation/last',
        method: 'GET',
      }),
    }),
    createDonation: build.mutation<
      CreateDonationResponseDTO,
      CreateDonationDTO
    >({
      query: (dto: CreateDonationDTO) => ({
        url: '/donation/create',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Re-Captcha-Token': dto.token,
        },
        body: dto,
      }),
    }),
  }),
});

export const {
  useGetDonationsCountQuery,
  useGetDonationsPageQuery,
  useGetLastDonationsQuery,
  useGetAdminDonationsPageQuery,
  useCreateDonationMutation,
} = donationsApiSlice;
