import { ROUTE_AUTHENTICATION } from '@/app/routes';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserDTO } from '@/types/user/UserDTO';
import { getAuthenticated, getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import React, { FC } from 'react';

interface Props extends RouteProps {
  role?: string;
}

export const PrivateRoute: FC<Props> = (props: Props) => {
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const isAuthenticated: boolean = useAppSelector(getAuthenticated);
  const { role, ...other } = props;
  if (role ? authUser && authUser.roles.indexOf(role) > -1 : isAuthenticated) {
    return <Route {...other} />;
  } else {
    return <Redirect to={ROUTE_AUTHENTICATION} />;
  }
};
