import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SecondaryArrowIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={
        'M19.1414 13.0711L14.8987 8.82843C13.3366 7.26633 10.804 7.26633 9.24189 8.82843L4.99924 13.0711'
      }
      stroke={'white'}
      strokeWidth={'2'}
      strokeLinecap={'round'}
    />
  </SvgIcon>
);
