import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const PaymentRegistryIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 59 119'}
    sx={{ width: '59px', height: '119px' }}
    {...props}>
    <g clipPath={'url(#clip0_189_10285)'}>
      <path
        d={
          'M4.90749 115.84C4.6954 115.703 4.56284 115.41 4.56284 115.005V27.0408C4.5627 26.9342 4.57093 26.8277 4.58746 26.7223L0.0255547 24.1473C0.118931 23.5481 0.422123 22.9991 0.883392 22.5941L5.43962 25.1655C5.50302 25.1151 5.57011 25.0693 5.64035 25.0285L45.2016 2.69168C45.3302 2.61292 45.4778 2.56885 45.6295 2.56395C45.7235 2.56363 45.816 2.58655 45.8984 2.63059L44.5407 1.86419L44.3664 1.76607L41.3195 0.0518444C41.2375 0.0107657 41.1465 -0.0102006 41.0544 -0.00924616C40.9068 -0.00547339 40.763 0.0367043 40.6378 0.112935L32.6426 4.64101L32.4683 4.73913L21.6553 10.8481L21.4811 10.9463L10.6681 17.0553L10.4939 17.1552L1.07845 22.4701C0.676537 22.7299 0.369492 23.1078 0.201668 23.5494C0.17894 23.6049 0.158108 23.6586 0.139172 23.7123C0.0506592 23.9593 0.0039711 24.2188 0.000934601 24.4805V26.8279V36.0192V39.2107V51.6138V60.8236V64.0151V73.2249V76.4164V85.6281V88.8196V98.0294V101.221V110.433V112.436C-0.00198364 112.491 -0.00198364 112.547 0.000934601 112.602C0.00283813 112.737 0.0383072 112.87 0.10429 112.989C0.170269 113.107 0.264778 113.208 0.379673 113.283L0.491394 113.345L0.665623 113.444L4.91508 115.836'
        }
        fill={'#E7DDF0'}
      />
      <path
        d={
          'M5.21255 115.927C5.36395 115.922 5.51141 115.879 5.64051 115.801L7.57398 114.709L7.7501 114.609L18.5612 108.5L18.7373 108.4L23.2216 105.869L19.6236 107.906C19.554 107.409 19.5167 106.908 19.5118 106.406V101.754L4.56869 110.188V115.001C4.56869 115.407 4.70125 115.699 4.91334 115.836C5.00384 115.894 5.11 115.925 5.21822 115.923L5.21255 115.927ZM46.2773 34.8269V25.4912V24.9359V13.0881V12.5327V3.48766C46.2773 3.05818 46.1296 2.74718 45.8986 2.63055C45.8162 2.58651 45.7236 2.56359 45.6297 2.56391C45.478 2.56881 45.3303 2.61288 45.2017 2.69164L5.64051 25.0192C5.57027 25.06 5.50318 25.1058 5.43979 25.1562C4.98147 25.5642 4.6806 26.1139 4.58762 26.713C4.57109 26.8184 4.56286 26.9249 4.563 27.0315V31.8446L46.2773 8.30268V34.8269Z'
        }
        fill={'#DAC2F0'}
      />
      <path
        d={'M46.2812 86.6444L4.56502 110.19V31.8484L46.2812 8.30273V86.6444Z'}
        fill={'#9B51E0'}
      />
      <path
        d={
          'M28.6678 15.9834L21.1214 20.2579C20.9018 20.3819 20.7143 20.4189 20.6328 20.2172C20.6038 20.1376 20.5897 20.0536 20.5912 19.9691C20.5939 19.774 20.6434 19.5821 20.7359 19.4092C20.8283 19.2362 20.961 19.0868 21.1233 18.9731L28.7492 14.6691L28.8174 14.6357C29.0579 14.7005 29.0977 14.7468 29.0977 15.1004L29.0636 15.2004C29.0636 15.5836 28.9632 15.8187 28.6678 15.9834Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M2.17798 41.1193L2.09277 41.0693C1.91634 40.9464 1.77195 40.7847 1.67128 40.5972C1.57062 40.4098 1.5165 40.2017 1.51329 39.99V34.8973C1.51329 34.4808 1.77273 34.2901 2.09277 34.4697L2.17798 34.5178C2.35221 34.642 2.49432 34.8042 2.59296 34.9916C2.6916 35.179 2.74404 35.3863 2.74609 35.5971V40.6916C2.74609 41.1082 2.48855 41.2988 2.17798 41.1193Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M2.17798 48.8851L2.09277 48.837C1.91634 48.7142 1.77195 48.5525 1.67128 48.365C1.57062 48.1775 1.5165 47.9695 1.51329 47.7577V42.6706C1.51329 42.2559 1.77273 42.0634 2.09277 42.2448L2.17798 42.2929C2.35243 42.4169 2.4947 42.5791 2.59336 42.7665C2.69203 42.954 2.74434 43.1614 2.74609 43.3722V48.4668C2.74609 48.8833 2.48855 49.074 2.17798 48.8925V48.8851Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M19.6168 107.911L23.2148 105.875C23.2148 105.83 23.2148 105.786 23.2148 105.742V99.6585L19.5013 101.754V106.412C19.5062 106.913 19.5435 107.414 19.613 107.911'
        }
        fill={'#A592B5'}
      />
      <path
        d={
          'M19.5052 101.786L23.2188 99.6881V62.325C23.2188 57.9394 20.8119 52.8301 17.407 49.3849L14.5664 50.9918C17.5055 54.3851 19.5109 58.9909 19.5109 62.9932V99.0087'
        }
        fill={'#7D42B5'}
      />
      <path
        d={
          'M10.2349 53.3521C10.2128 53.3324 10.1958 53.3079 10.1853 53.2805C10.1748 53.2532 10.171 53.2238 10.1743 53.1947V46.6488C10.1757 46.5722 10.1954 46.4969 10.232 46.4291C10.2685 46.3613 10.3207 46.3028 10.3845 46.2582L45.8554 26.2373C45.8804 26.222 45.9092 26.2136 45.9387 26.2132C45.9512 26.2105 45.9641 26.2105 45.9766 26.2132L43.1512 24.6212C43.1323 24.6101 43.1107 24.6043 43.0887 24.6045C43.0607 24.6054 43.0334 24.6131 43.0091 24.6267L7.5383 44.6569C7.47473 44.7015 7.42276 44.7601 7.38658 44.828C7.3504 44.8958 7.33102 44.971 7.33 45.0475V51.5934C7.32682 51.6216 7.33028 51.6501 7.34013 51.6767C7.34998 51.7034 7.36595 51.7274 7.38681 51.7471L10.2274 53.3447'
        }
        fill={'#B182E0'}
      />
      <path
        d={
          'M10.2962 53.3743C10.3257 53.3734 10.3544 53.3651 10.3796 53.3503L14.559 50.9918L17.3995 49.385L15.7368 50.3235C15.2568 49.7392 14.7421 49.1828 14.1954 48.6574L11.3851 50.2439C11.3692 50.2539 11.351 50.2597 11.3321 50.2606C11.2848 50.2606 11.2488 50.2124 11.2488 50.1384V48.4927C11.2495 48.4403 11.2629 48.3888 11.2879 48.3425C11.313 48.2961 11.3489 48.2562 11.3927 48.2261L14.0174 46.7451L41.8414 31.0375L44.1593 29.7306L44.8411 29.3455C44.8575 29.3352 44.8765 29.3294 44.896 29.3288C44.9471 29.3288 44.9831 29.3751 44.9831 29.451V31.0949C44.9823 31.1471 44.969 31.1984 44.9444 31.2446C44.9197 31.2909 44.8843 31.3309 44.8411 31.3615L43.4833 32.1279C44.102 32.6048 44.6866 33.1225 45.2331 33.6774L45.8523 33.3349C45.9162 33.2905 45.9686 33.232 46.0051 33.1641C46.0416 33.0962 46.0613 33.0209 46.0625 32.9443V26.3928C46.0625 26.3021 46.0265 26.2392 45.9735 26.2206C45.961 26.2179 45.9481 26.2179 45.9356 26.2206C45.9061 26.2211 45.8773 26.2294 45.8523 26.2447L10.3815 46.2582C10.3177 46.3028 10.2654 46.3613 10.2289 46.4291C10.1924 46.497 10.1727 46.5722 10.1713 46.6489V53.1948C10.1679 53.2238 10.1717 53.2532 10.1822 53.2806C10.1928 53.3079 10.2098 53.3324 10.2319 53.3521C10.2507 53.3668 10.2741 53.3746 10.2981 53.3743'
        }
        fill={'#BA94E0'}
      />
      <path
        d={
          'M15.7475 50.3235L17.4102 49.3849C16.8648 48.829 16.2808 48.3107 15.6623 47.8336L14.206 48.6556C14.7528 49.181 15.2675 49.7373 15.7475 50.3217'
        }
        fill={'#9F7EBF'}
      />
      <path
        d={
          'M42.8695 31.6225L15.0454 47.3042C14.9886 47.2634 23.3379 53.0429 23.3417 62.3249C23.3455 71.607 23.3417 105.351 23.3417 105.351C23.3417 105.351 23.2262 113.987 31.1702 119L59 103.294C59 103.294 52.338 99.8436 51.2169 91.0355C51.2169 83.5066 51.1696 46.7414 51.1696 46.7414C51.1696 46.7414 51.1696 37.3354 42.8695 31.6225Z'
        }
        fill={'url(#paint0_linear_189_10285)'}
      />
      <path
        d={
          'M28.3375 78.6694L33.4297 75.8074V75.2539L28.3394 78.1159L28.3375 78.6694Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M34.2041 75.3705L36.0372 74.3412L36.0391 73.7858L34.206 74.817L34.2041 75.3705Z'
        }
        fill={'white'}
      />
      <path
        d={'M36.8164 73.9043L38.0625 73.2027V72.6492L36.8164 73.349V73.9043Z'}
        fill={'white'}
      />
      <path
        d={
          'M28.3351 79.9356L31.1094 78.3768V77.8215L28.337 79.3821L28.3351 79.9356Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M31.888 77.94L35.2891 76.0277V75.4742L31.8899 77.3846L31.888 77.94Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M36.0646 75.5908L37.3106 74.891L37.3125 74.3357L36.0664 75.0373L36.0646 75.5908Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M37.9883 74.5079L39.2344 73.8081V73.2545L37.9902 73.9543L37.9883 74.5079Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3325 81.2018L30.3398 80.0726V79.5191L28.3325 80.6465V81.2018Z'}
        fill={'white'}
      />
      <path
        d={
          'M31.1173 79.6357L31.8028 79.2506L31.8047 78.6953L31.1192 79.0822L31.1173 79.6357Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M32.5821 78.8138L33.8281 78.1121V77.5586L32.584 78.2584L32.5821 78.8138Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3301 82.4662L35.7383 78.3028V77.7493L28.3301 81.9127V82.4662Z'}
        fill={'white'}
      />
      <path
        d={
          'M36.5166 77.8659L38.3497 76.8348L38.3516 76.2813L36.5185 77.3106L36.5166 77.8659Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3267 83.7324L30.334 82.6032L30.3359 82.0497L28.3286 83.1789L28.3267 83.7324Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M31.1137 82.1663L35.0469 79.956V79.4025L31.1156 81.6128L31.1137 82.1663Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M35.8224 79.5191L37.0684 78.8193L37.0703 78.2639L35.8243 78.9656L35.8224 79.5191Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3281 84.9968L32.4336 82.6902V82.1348L28.3281 84.4433V84.9968Z'}
        fill={'white'}
      />
      <path
        d={
          'M33.2085 82.2514L36.1797 80.5835V80.0281L33.2104 81.6979L33.2085 82.2514Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M36.9556 80.1466L38.9629 79.0174L38.9648 78.4638L36.9575 79.5913L36.9556 80.1466Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3229 86.2631L29.7109 85.4837V84.9302L28.3248 85.7095L28.3229 86.2631Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M30.4892 85.0468L32.3223 84.0157L32.3242 83.4622L30.4911 84.4933L30.4892 85.0468Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.1005 83.5788L36.0528 81.9183L36.0547 81.3647L33.1005 83.0253V83.5788Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3209 87.5293L32.5684 85.1412L32.5703 84.5859L28.3209 86.9739V87.5293Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.3496 84.7025L37.3301 82.4662L37.332 81.9108L33.3515 84.149L33.3496 84.7025Z'
        }
        fill={'white'}
      />
      <path
        d={'M38.1055 82.0294L39.3516 81.3277V80.7742L38.1055 81.474V82.0294Z'}
        fill={'white'}
      />
      <path
        d={
          'M40.0489 80.9371L41.295 80.2373L41.2969 79.682L40.0508 80.3817L40.0489 80.9371Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.322 88.7937L30.8672 87.3627V86.8092L28.322 88.2402V88.7937Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.2285 68.6117L47.2833 68.0193L47.2852 67.4658L46.2285 68.0582V68.6117Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M43.7554 70.0038L46.2305 68.6117V68.0581L43.7573 69.4484L43.7554 70.0038Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2304 69.8761L47.2833 69.2855L47.2852 68.7302L46.2304 69.3226V69.8761Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M44.5091 70.8443L46.2305 69.8761V69.3226L44.511 70.2889L44.5091 70.8443Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2284 71.1405L47.2794 70.55L47.2812 69.9965L46.2284 70.587V71.1405Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M45.2742 71.6774L46.2305 71.1405V70.5869L45.276 71.1238L45.2742 71.6774Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.3267 72.3493L47.2773 71.8162V71.2608L46.2282 71.8513V72.2938L46.3267 72.3493Z'
        }
        fill={'white'}
      />
      <path
        d={'M46.2297 72.4048L46.3281 72.3493L46.2297 72.2938V72.4048Z'}
        fill={'white'}
      />
      <path
        d={
          'M44.3595 73.4563L46.2305 72.4048V71.8513L44.3614 72.9028L44.3595 73.4563Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2281 73.6692L47.2734 73.0805L46.8947 72.8695L46.2243 73.2397V73.6618L46.2281 73.6692ZM47.2715 72.8769V72.6492L47.0689 72.7621L47.2715 72.8769Z'
        }
        fill={'white'}
      />
      <path
        d={'M47.2734 73.0824L46.8985 72.8695L47.2734 73.0824Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.2301 73.2471L46.9005 72.8695L47.2755 73.0824L47.2773 73.0805V72.8843L47.0747 72.7714L47.2773 72.6566V72.527L46.2301 73.1157V73.2471Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M45.2704 74.2098L46.2305 73.6693V73.1158L45.2723 73.6563L45.2704 74.2098Z'
        }
        fill={'white'}
      />
      <path
        d={'M46.2281 74.9336L47.2734 74.3468V73.7932L46.2281 74.3801V74.9336Z'}
        fill={'white'}
      />
      <path
        d={
          'M44.7288 75.7778L46.2305 74.9336V74.3801L44.7307 75.2242L44.7288 75.7778Z'
        }
        fill={'white'}
      />
      <path
        d={'M46.23 76.198L47.2715 75.613L47.2734 75.0576L46.23 75.6445V76.198Z'}
        fill={'white'}
      />
      <path
        d={'M45.8858 76.3924L46.2305 76.198V75.6445L45.8858 75.837V76.3924Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.228 77.4624L47.2676 76.8774L47.2695 76.3239L46.228 76.9089V77.4624Z'
        }
        fill={'white'}
      />
      <path
        d={'M44.583 78.388L46.2305 77.4624V76.9089L44.583 77.8345V78.388Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.2299 78.7267L47.2676 78.1436L47.2695 77.5901L46.2299 78.1732V78.7267Z'
        }
        fill={'white'}
      />
      <path
        d={'M44.7231 79.5746L46.2305 78.7267V78.1732L44.7231 79.0211V79.5746Z'}
        fill={'white'}
      />
      <path
        d={
          'M31.6434 86.9258L32.7247 86.3186L32.7266 85.7632L31.6434 86.3723V86.9258Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.5042 85.8798L35.9395 84.5099L35.9414 83.9564L33.5061 85.3263L33.5042 85.8798Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3375 64.1077L33.4297 61.2457V60.6922L28.3394 63.5523L28.3375 64.1077Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M34.2041 60.8088L36.0372 59.7777L36.0391 59.2242L34.206 60.2553L34.2041 60.8088Z'
        }
        fill={'white'}
      />
      <path
        d={'M36.8164 59.3408L38.0625 58.6392V58.0857L36.8164 58.7873V59.3408Z'}
        fill={'white'}
      />
      <path
        d={
          'M28.3351 65.3721L31.1094 63.8133V63.2598L28.337 64.8186L28.3351 65.3721Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M31.888 63.3765L35.2891 61.466V60.9106L31.8899 62.823L31.888 63.3765Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M36.0646 61.0291L37.3106 60.3275L37.3125 59.774L36.0664 60.4737L36.0646 61.0291Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M37.9883 59.9461L39.2344 59.2464V58.691L37.9902 59.3908L37.9883 59.9461Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3325 66.6383L30.3398 65.5091V64.9555L28.3325 66.0848V66.6383Z'}
        fill={'white'}
      />
      <path
        d={
          'M31.1173 65.0722L31.8028 64.6871L31.8047 64.1336L31.1192 64.5187L31.1173 65.0722Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M32.5821 64.2502L33.8281 63.5505V62.9951L32.584 63.6967L32.5821 64.2502Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3301 67.9045L35.7383 63.7393V63.1858L28.3301 67.3491V67.9045Z'}
        fill={'white'}
      />
      <path
        d={
          'M36.5166 63.3024L38.3497 62.2713L38.3516 61.7178L36.5185 62.7489L36.5166 63.3024Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3267 69.1689L30.334 68.0415L30.3359 67.4861L28.3286 68.6154L28.3267 69.1689Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M31.1137 67.6046L35.0469 65.3924V64.8389L31.1156 67.0493L31.1137 67.6046Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M35.8224 64.9555L37.0684 64.2558L37.0703 63.7023L35.8243 64.402L35.8224 64.9555Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3281 70.4352L32.4336 68.1267V67.5713L28.3281 69.8798V70.4352Z'}
        fill={'white'}
      />
      <path
        d={
          'M33.2085 67.6898L36.1797 66.02V65.4665L33.2104 67.1344L33.2085 67.6898Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M36.9556 65.5831L38.9629 64.4539L38.9648 63.9004L36.9575 65.0278L36.9556 65.5831Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3229 71.6995L29.7109 70.9202V70.3666L28.3248 71.146L28.3229 71.6995Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M30.4892 70.4833L32.3223 69.4521L32.3242 68.8986L30.4911 69.9298L30.4892 70.4833Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.1005 69.0153L36.0528 67.3547L36.0547 66.8012L33.1005 68.4618V69.0153Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3209 72.9658L32.5684 70.5777L32.5703 70.0242L28.3209 72.4122V72.9658Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.3496 70.1408L37.3301 67.9027L37.332 67.3492L33.3515 69.5854L33.3496 70.1408Z'
        }
        fill={'white'}
      />
      <path
        d={'M38.1055 67.4658L39.3516 66.7642V66.2107L38.1055 66.9123V67.4658Z'}
        fill={'white'}
      />
      <path
        d={
          'M40.0489 66.3736L41.295 65.6738L41.2969 65.1203L40.0508 65.8201L40.0489 66.3736Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.322 74.232L30.8672 72.7991V72.2456L28.322 73.6766V74.232Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.2285 54.0482L47.2833 53.4558L47.2852 52.9023L46.2285 53.4965V54.0482Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M43.7554 55.4403L46.2305 54.0482V53.4965L43.7573 54.8868L43.7554 55.4403Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2304 55.3144L47.2833 54.722L47.2852 54.1685L46.2304 54.7609V55.3144Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M44.5091 56.2807L46.2305 55.3144V54.7609L44.511 55.7272L44.5091 56.2807Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2284 56.5769L47.2794 55.9864L47.2812 55.4329L46.2284 56.0234V56.5769Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M45.2742 57.1156L46.2305 56.5769V56.0234L45.276 56.5621L45.2742 57.1156Z'
        }
        fill={'white'}
      />
      <path
        d={'M46.2282 57.8413L47.2773 57.2526V56.6991L46.2282 57.2878V57.8413Z'}
        fill={'white'}
      />
      <path
        d={
          'M44.3595 58.8928L46.2305 57.8413V57.2878L44.3614 58.3393L44.3595 58.8928Z'
        }
        fill={'white'}
      />
      <path
        d={'M46.2301 59.1057L47.2773 58.5188V57.9653L46.2301 58.5522V59.1057Z'}
        fill={'white'}
      />
      <path
        d={
          'M45.2704 59.6463L46.2305 59.1057V58.5522L45.2723 59.0927L45.2704 59.6463Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2281 60.3627L46.4781 60.222L46.2281 60.0813V60.3627ZM46.6561 60.1313L47.2734 59.7832V59.2279L46.2281 59.8147V59.8888L46.6542 60.1294'
        }
        fill={'white'}
      />
      <path
        d={'M46.4805 60.2312L46.2305 60.0887L46.4805 60.2312Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.4801 60.2312L46.6562 60.1313L46.2302 59.8924V60.0887L46.4801 60.2312Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M44.7288 61.2142L46.2305 60.3701V59.8166L44.7307 60.6607L44.7288 61.2142Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.23 61.6345L47.2715 61.0495L47.2734 60.6774L47.1106 60.5867L46.23 61.0809V61.6345Z'
        }
        fill={'white'}
      />
      <path
        d={'M47.2734 60.6774L47.1106 60.5867L47.2734 60.6774Z'}
        fill={'white'}
      />
      <path
        d={'M47.2734 60.6774V60.496L47.1106 60.5867L47.2734 60.6774Z'}
        fill={'white'}
      />
      <path
        d={'M45.8858 61.8288L46.2305 61.6344V61.0809L45.8858 61.2753V61.8288Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.228 62.8988L47.2676 62.3157L47.2695 61.7604L46.228 62.3453V62.8988Z'
        }
        fill={'white'}
      />
      <path
        d={'M44.583 63.8263L46.2305 62.8988V62.3453L44.583 63.2709V63.8263Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.2299 64.1632L47.2676 63.5801L47.2695 63.0266L46.2299 63.6097V64.1632Z'
        }
        fill={'white'}
      />
      <path
        d={'M44.7231 65.0111L46.2305 64.1632V63.6097L44.7231 64.4576V65.0111Z'}
        fill={'white'}
      />
      <path
        d={
          'M31.6434 72.3622L32.7247 71.755L32.7266 71.2015L31.6434 71.8087V72.3622Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.5042 71.3181L35.9395 69.9483L35.9414 69.3929L33.5061 70.7628L33.5042 71.3181Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M34.6017 91.85L38.7129 89.5379L38.7148 88.9843L34.6036 91.2965L34.6017 91.85Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M39.9102 88.8659L41.5312 87.957V87.4016L39.9121 88.3124L39.9102 88.8659Z'
        }
        fill={'white'}
      />
    </g>
    <defs>
      <linearGradient
        id={'paint0_linear_189_10285'}
        x1={'20.7303'}
        y1={'44.9957'}
        x2={'57.9821'}
        y2={'117.745'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'#E7DDF0'} />
        <stop offset={'0.17'} stopColor={'#D8CDED'} />
        <stop offset={'0.42'} stopColor={'#E7DDF0'} />
        <stop offset={'0.65'} stopColor={'#DACDED'} />
        <stop offset={'0.85'} stopColor={'#E5D8F0'} />
      </linearGradient>
      <clipPath id={'clip0_189_10285'}>
        <rect
          width={'59'}
          height={'119'}
          fill={'white'}
          transform={'matrix(-1 0 0 1 59 0)'}
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
