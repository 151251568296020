import * as Yup from 'yup';
import { AddressField } from '@/components/common/field/AddressField';
import { AddressFieldFreeSolo } from '@/components/common/field/AddressFieldFreeSolo';
import { AttachFilePanel } from '@/components/common/AttachFilePanel';
import { Button, Grid, Skeleton, Typography } from '@mui/material';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import {
  MESSAGE_INVALID_BANK_DETAILS_SIZE,
  getMessageMaxLetterSize,
} from '@/utils/validation-utils';
import { MaskedOutlinedField } from '@/components/common/field/MaskedOutlinedField';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PhoneNumberField } from '@/components/common/field/PhoneNumberField';
import { RegionDTO } from '@/types/RegionDTO';
import { RegionSelectField } from '@/components/common/field/RegionSelectField';
import { TempleFormValues } from '@/types/form/TempleFormValues';
import { TempleViewDTO } from '@/types/TempleViewDTO';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { bankDetailsSchema } from '@/validation/bankDetailsSchema';
import { emailSchema } from '@/validation/emailSchema';
import { getFileArray } from '@/utils/file-utils';
import { imagesFilesSchema } from '@/validation/imagesFilesSchema';
import { nameSchema } from '@/validation/nameSchema';
import { phoneSchema } from '@/validation/phoneSchema';
import { regionSchema } from '@/validation/regionSchema';
import { useGetRegionsQuery } from '@/services/api/templesApiSlice';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, Fragment, MouseEvent, useRef } from 'react';

interface Props {
  initialFormValues: TempleFormValues;
  authUser?: UserDTO;
  temple?: TempleViewDTO;
  loadingData?: boolean;
  sendingData?: boolean;
  handleSubmit: (
    values: TempleFormValues,
    helpers?: FormikHelpers<TempleFormValues>
  ) => Promise<void>;
  isAdminPage?: boolean;
  isMainRegistrationPage?: boolean;
  isAdminAddTemplePage?: boolean;
  isDisabled?: boolean;
  submitButtonText: string;
  isMobile?: boolean;
}

//TODO: доработать Skeleton, сделать более похожим на форму.
export const TempleForm: FC<Props> = (props: Props) => {
  const {
    initialFormValues,
    authUser,
    temple,
    loadingData,
    sendingData,
    handleSubmit,
    submitButtonText,
    isAdminPage,
    isMainRegistrationPage,
    isAdminAddTemplePage,
    isDisabled,
    isMobile,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: regions,
    isFetching: isRegionsFetching,
    isLoading: isRegionsLoading,
  } = useGetRegionsQuery();

  const loadingRegions = isRegionsFetching || isRegionsLoading;

  const validationSchema = Yup.object({
    name: nameSchema.max(100, getMessageMaxLetterSize(100)),
    address: nameSchema.max(100, getMessageMaxLetterSize(100)),
    description: nameSchema.max(30000, getMessageMaxLetterSize(30000)),
    firstName: nameSchema.max(100, getMessageMaxLetterSize(100)),
    lastName: nameSchema.max(100, getMessageMaxLetterSize(100)),
    middleName: nameSchema.max(100, getMessageMaxLetterSize(100)),
    bankName: nameSchema.max(100, getMessageMaxLetterSize(100)),
    email: emailSchema.max(100, getMessageMaxLetterSize(100)),
    phone: phoneSchema.max(100, getMessageMaxLetterSize(100)),
    region: isMainRegistrationPage
      ? Yup.object().nullable().notRequired()
      : regionSchema,
    inn: bankDetailsSchema.min(10, MESSAGE_INVALID_BANK_DETAILS_SIZE),
    kpp: bankDetailsSchema.min(9, MESSAGE_INVALID_BANK_DETAILS_SIZE),
    oktmo: bankDetailsSchema.min(13, MESSAGE_INVALID_BANK_DETAILS_SIZE),
    bic: bankDetailsSchema.min(9, MESSAGE_INVALID_BANK_DETAILS_SIZE),
    bankAccount: bankDetailsSchema.min(20, MESSAGE_INVALID_BANK_DETAILS_SIZE),
    attachFiles: imagesFilesSchema,
  });

  const initialValues: TempleFormValues = {
    name: temple?.name || initialFormValues.name,
    address: temple?.address || initialFormValues.address,
    description: temple?.description || initialFormValues.description,
    inn: temple?.inn || initialFormValues.inn,
    kpp: temple?.kpp || initialFormValues.kpp,
    oktmo: temple?.oktmo || initialFormValues.oktmo,
    bic: temple?.bic || initialFormValues.bic,
    bankAccount: temple?.bankAccount || initialFormValues.bankAccount,
    bankName: temple?.bankName || initialFormValues.bankName,
    firstName: authUser?.firstName || initialFormValues.firstName,
    lastName: authUser?.lastName || initialFormValues.lastName,
    middleName: authUser?.middleName || initialFormValues.middleName,
    phone: authUser?.phone || initialFormValues.phone,
    email: authUser?.email || initialFormValues.email,
    //FIXME: should add memorization to getFileArray ?
    attachFiles: temple?.attachFile
      ? getFileArray(temple.attachFile)
      : initialFormValues.attachFiles,
    region:
      temple?.regionId && regions
        ? regions.find((region: RegionDTO) => region.id === temple.regionId) ||
          null
        : initialFormValues.region,
    mainImageIndex: initialFormValues.mainImageIndex,
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={!isMainRegistrationPage && !isAdminAddTemplePage}>
      {(formikProps: FormikProps<TempleFormValues>) => {
        const { values, errors, touched, submitForm, setFieldValue } =
          formikProps;
        const handleSetFieldValue = (
          field: string,
          value: string | unknown,
          isErrorField: boolean
        ) => {
          setFieldValue(field, value, isErrorField);
        };
        const handleClickUploadButton = () => {
          inputRef.current?.click();
        };
        const handleDeleteFile = (index: number) => {
          const files = values.attachFiles;
          files.splice(index, 1);
          setFieldValue('attachFiles', files, Boolean(errors.attachFiles));
          handleSetMainPhoto(0);
        };
        const handleChangeUpload = () => {
          if (inputRef.current?.files) {
            if (inputRef.current.files.length + values.attachFiles.length < 6) {
              Array.from(inputRef.current.files).forEach((file) => {
                if (file.size < 2097152) {
                  const imageType = file.type.split('/')[1];
                  if (
                    imageType === 'jpeg' ||
                    imageType === 'jpg' ||
                    imageType === 'png'
                  ) {
                    const files = values.attachFiles;
                    files.push(file);
                    setFieldValue(
                      'attachFiles',
                      files,
                      Boolean(errors.attachFiles)
                    );
                  } else {
                    enqueueSnackbar(
                      'Поддерживаемый формат файла только jpg, jpeg, png',
                      {
                        variant: 'error',
                      }
                    );
                  }
                } else {
                  enqueueSnackbar('Максимальный размер файла - 2 мегабайта', {
                    variant: 'error',
                  });
                }
              });
            } else {
              enqueueSnackbar('Максимальное количество файлов - 5', {
                variant: 'error',
              });
            }
          }
        };
        const handleResetFileList = (e: MouseEvent) => {
          (e.target as HTMLInputElement).value = '';
        };
        const handleSetMainPhoto = (index: number) => {
          setFieldValue('mainImageIndex', index, false);
        };
        const handleSetAddressField = (address?: string) => {
          setFieldValue('address', address, Boolean(errors.address));
        };
        return loadingData ? (
          <Grid container={true} direction={'column'} spacing={2}>
            <Grid item={true}>
              <Grid item={true}>
                <Skeleton width={'30%'} height={'30px'} />
              </Grid>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={4} mt={3}>
                  <Skeleton width={'100%'} height={'48px'} />
                </Grid>
                <Grid item={true} xs={4} mt={3}>
                  <Skeleton width={'100%'} height={'48px'} />
                </Grid>
                <Grid item={true} xs={4} mt={3}>
                  <Skeleton width={'100%'} height={'48px'} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Fragment>
            <Grid container={true} direction={'column'} rowSpacing={3}>
              <Grid item={true}>
                <Typography
                  fontSize={'18px'}
                  fontWeight={500}
                  lineHeight={'28px'}>
                  {'Общедоступная информация'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true} spacing={3}>
                  <Grid item={true} md={isMainRegistrationPage ? 6 : 4} xs={12}>
                    <OutlinedField
                      disabled={sendingData || isDisabled}
                      name={'name'}
                      label={'Название церкви'}
                      withLabel={true}
                      value={values.name}
                      placeholder={'Введите название'}
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.name)
                        );
                      }}
                    />
                  </Grid>
                  {!isMainRegistrationPage && (
                    <Grid item={true} md={4} xs={12}>
                      <RegionSelectField
                        disabled={sendingData || isDisabled}
                        regions={regions || []}
                        region={values.region}
                        isLoading={loadingRegions}
                        error={touched.region && Boolean(errors.region)}
                        helperText={touched.region ? errors.region : ''}
                        addNewRegion={isAdminPage}
                        onChange={(value: RegionDTO | null) => {
                          handleSetFieldValue(
                            'region',
                            value,
                            Boolean(errors.region)
                          );
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item={true} md={isMainRegistrationPage ? 6 : 4} xs={12}>
                    {isAdminPage ? (
                      <AddressFieldFreeSolo
                        setAddressToForm={handleSetAddressField}
                        initialAddress={initialValues.address}
                        regions={regions}
                        setRegionToForm={(value: RegionDTO | null) => {
                          handleSetFieldValue(
                            'region',
                            value,
                            Boolean(errors.region)
                          );
                        }}
                        error={Boolean(errors.address)}
                        helperText={errors.address}
                        disabled={sendingData || isDisabled}
                      />
                    ) : (
                      <AddressField
                        initialAddress={initialValues.address}
                        setAddressToFrom={handleSetAddressField}
                        error={Boolean(errors.address)}
                        helperText={errors.address}
                        disabled={sendingData || isDisabled}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true}>
                <Grid container={true} direction={'column'}>
                  <Grid item={true}>
                    <Grid container={true} columnSpacing={3} rowSpacing={1.5}>
                      <Grid item={true} sm={'auto'} xs={12}>
                        <Button
                          onClick={() => handleClickUploadButton()}
                          sx={{ padding: '11px 30px' }}
                          variant={'outlined'}
                          color={errors.attachFiles ? 'error' : 'primary'}
                          fullWidth={true}
                          disabled={
                            values.attachFiles.length > 4 ||
                            sendingData ||
                            isDisabled
                          }
                          size={'medium'}>
                          {'Приложить фотографию'}
                        </Button>
                        <input
                          onChange={() => handleChangeUpload()}
                          onClick={(e) => handleResetFileList(e)}
                          type={'file'}
                          multiple={true}
                          ref={inputRef}
                          accept={'image/png, image/jpeg'}
                          hidden={true}
                        />
                      </Grid>
                      <Grid
                        item={true}
                        alignSelf={'center'}
                        sm={'auto'}
                        xs={12}>
                        <Typography
                          variant={'body2'}
                          whiteSpace={'pre-line'}
                          color={(theme: Theme) => theme.colors.grayText}>
                          {
                            'Максимальное количество файлов 5 шт. Формат JPG или PNG, не более 2 МБ.'
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {errors.attachFiles && (
                    <Grid item={true}>
                      <Typography
                        sx={(theme: Theme) => ({
                          fontSize: theme.spacing(1.25),
                          lineHeight: theme.spacing(2),
                          color: theme.colors.red,
                          marginTop: theme.spacing(0.5),
                        })}>
                        {errors.attachFiles}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {values.attachFiles.length > 0 && (
                <Grid
                  container={true}
                  spacing={3}
                  mt={isMobile ? 2 : 0}
                  rowSpacing={isMobile ? 1 : 3}>
                  {values.attachFiles.map((file, index) => (
                    <Grid
                      item={true}
                      key={index}
                      style={{ maxWidth: '100%' }}
                      sm={'auto'}
                      xs={12}>
                      <AttachFilePanel
                        attachedFile={file}
                        index={index}
                        isMain={
                          isDisabled ? false : index === values.mainImageIndex
                        }
                        setMainPhoto={handleSetMainPhoto}
                        onClear={() => {
                          handleDeleteFile(index);
                        }}
                        isAdminPage={isAdminPage}
                        disabled={sendingData || isDisabled}
                        fullWidth={isMobile}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              <Grid item={true} mt={errors.attachFiles ? 0.5 : 1.5}>
                <OutlinedField
                  disabled={sendingData || isDisabled}
                  name={'description'}
                  label={'Описание церкви'}
                  withLabel={true}
                  value={values.description}
                  multiline={true}
                  minRows={isMobile ? 2 : 4}
                  maxRows={10}
                  isNotAbsoluteHelperText={true}
                  placeholder={'Введите описание церкви'}
                  error={Boolean(errors.description)}
                  helperText={errors.description}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleSetFieldValue(
                      e.target.name,
                      e.target.value,
                      Boolean(errors.description)
                    );
                  }}
                />
              </Grid>
              <Grid
                item={true}
                mt={errors.description ? 0.625 : isMobile ? 1 : 3}>
                <Typography
                  fontSize={'18px'}
                  fontWeight={500}
                  lineHeight={'28px'}>
                  {'Реквизиты (общедоступно)'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true} spacing={isMobile ? 1.5 : 3}>
                  <Grid item={true} sm={3} xs={12}>
                    <MaskedOutlinedField
                      disabled={sendingData || isDisabled}
                      name={'inn'}
                      label={'ИНН'}
                      withLabel={true}
                      value={values.inn}
                      placeholder={'0000000000'}
                      mask={'9999999999'}
                      error={Boolean(errors.inn)}
                      helperText={errors.inn}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.inn)
                        );
                      }}
                    />
                  </Grid>
                  <Grid item={true} sm={3} xs={12}>
                    <MaskedOutlinedField
                      disabled={sendingData || isDisabled}
                      name={'kpp'}
                      label={'КПП'}
                      withLabel={true}
                      value={values.kpp}
                      placeholder={'000 000 000'}
                      mask={'999 999 999'}
                      error={Boolean(errors.kpp)}
                      helperText={errors.kpp}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.kpp)
                        );
                      }}
                    />
                  </Grid>
                  <Grid item={true} sm={3} xs={12}>
                    <MaskedOutlinedField
                      disabled={sendingData || isDisabled}
                      name={'oktmo'}
                      label={'ОГРН'}
                      withLabel={true}
                      value={values.oktmo}
                      placeholder={'0 00 00 0000000 0'}
                      mask={'9 99 99 9999999 9'}
                      error={Boolean(errors.oktmo)}
                      helperText={errors.oktmo}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.oktmo)
                        );
                      }}
                    />
                  </Grid>
                  <Grid item={true} sm={3} xs={12}>
                    <MaskedOutlinedField
                      disabled={sendingData || isDisabled}
                      name={'bic'}
                      label={'БИК'}
                      withLabel={true}
                      value={values.bic}
                      placeholder={'000 000 000'}
                      mask={'999 999 999'}
                      error={Boolean(errors.bic)}
                      helperText={errors.bic}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.bic)
                        );
                      }}
                    />
                  </Grid>
                  <Grid item={true} sm={isAdminPage ? 3 : 6} xs={12}>
                    <MaskedOutlinedField
                      disabled={sendingData || isDisabled}
                      name={'bankAccount'}
                      label={'Номер счета'}
                      withLabel={true}
                      value={values.bankAccount}
                      placeholder={'0000 0000 0000 0000 0000'}
                      mask={'9999 9999 9999 9999 9999'}
                      error={Boolean(errors.bankAccount)}
                      helperText={errors.bankAccount}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.bankAccount)
                        );
                      }}
                    />
                  </Grid>
                  <Grid item={true} sm={isAdminPage ? 3 : 6} xs={12}>
                    <OutlinedField
                      disabled={sendingData || isDisabled}
                      name={'bankName'}
                      label={'Название банка'}
                      withLabel={true}
                      value={values.bankName}
                      placeholder={'Введите название'}
                      error={Boolean(errors.bankName)}
                      helperText={errors.bankName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.bankName)
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} mt={isMobile ? 1 : 3}>
                <Typography
                  fontSize={'18px'}
                  fontWeight={500}
                  lineHeight={'28px'}>
                  {'Контакты представителя церкви'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Grid
                  container={true}
                  spacing={isMobile ? 1.5 : 3}
                  columns={20}>
                  <Grid item={true} xl={isAdminPage ? 4 : 5} sm={6} xs={20}>
                    <OutlinedField
                      disabled={sendingData || isDisabled}
                      name={'lastName'}
                      label={'Фамилия'}
                      withLabel={true}
                      value={values.lastName}
                      placeholder={'Фамилия'}
                      error={Boolean(errors.lastName)}
                      helperText={errors.lastName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.lastName)
                        );
                      }}
                    />
                  </Grid>
                  <Grid item={true} xl={isAdminPage ? 4 : 5} sm={6} xs={20}>
                    <OutlinedField
                      disabled={sendingData || isDisabled}
                      name={'firstName'}
                      label={'Имя'}
                      withLabel={true}
                      value={values.firstName}
                      placeholder={'Имя'}
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.firstName)
                        );
                      }}
                    />
                  </Grid>
                  <Grid item={true} xl={isAdminPage ? 4 : 5} sm={6} xs={20}>
                    <OutlinedField
                      disabled={sendingData || isDisabled}
                      name={'middleName'}
                      label={'Отчество'}
                      withLabel={true}
                      value={values.middleName}
                      placeholder={'Отчество'}
                      error={Boolean(errors.middleName)}
                      helperText={errors.middleName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.middleName)
                        );
                      }}
                    />
                  </Grid>
                  {!isAdminPage && !isMobile && (
                    <Grid item={true} lg={5} xl={4} sm={0} />
                  )}
                  <Grid item={true} xl={isAdminPage ? 4 : 5} sm={10} xs={20}>
                    <PhoneNumberField
                      disabled={sendingData || isDisabled}
                      name={'phone'}
                      label={'Телефон'}
                      withLabel={true}
                      value={values.phone}
                      placeholder={'Телефон'}
                      error={Boolean(errors.phone)}
                      helperText={errors.phone}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.phone)
                        );
                      }}
                    />
                  </Grid>
                  <Grid item={true} xl={4} sm={10} xs={20}>
                    <OutlinedField
                      disabled={sendingData || isDisabled}
                      name={'email'}
                      label={'E-mail'}
                      withLabel={true}
                      value={values.email}
                      placeholder={'xxx@xxx.xx'}
                      error={Boolean(errors.email)}
                      helperText={errors.email}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(
                          e.target.name,
                          e.target.value,
                          Boolean(errors.email)
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} mt={isMobile ? 2 : 4} mb={4}>
                <Grid container={true} spacing={3}>
                  <Grid item={true} md={3} sm={6} xs={12}>
                    <Button
                      fullWidth={true}
                      disabled={sendingData || isDisabled}
                      variant={'contained'}
                      onClick={submitForm}>
                      {submitButtonText}
                    </Button>
                  </Grid>
                </Grid>
                <Grid container={true}>
                  {Object.keys(errors).length !== 0 && (
                    <Grid item={true} mt={1}>
                      <Typography
                        fontSize={'10px'}
                        lineHeight={'16px'}
                        color={'error'}>
                        {'Заполнены не все поля!'}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        );
      }}
    </Formik>
  );
};
