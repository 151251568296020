import { TempleViewType } from '@/types/filter/TempleViewType';
import { config } from '@/app/config';

export const ROUTE_HOME = '/';
export const ROUTE_TEMPLES = '/temples';
export const ROUTE_TEMPLES_TUTORIAL = '/temples?isNew=true';
export const ROUTE_TEMPLE_PAGE = '/temples/:id/:donate';
export const ROUTE_DONATIONS = '/donations';
export const ROUTE_ABOUT = '/about';
export const ROUTE_HELP = '/help';
export const ROUTE_HELP_WORK = '/help/work';
export const ROUTE_HELP_FAQ = '/help/faq';
export const ROUTE_HELP_CONNECT = '/help/connect';
export const ROUTE_HELP_FEEDBACK = '/help/feedback';
export const ROUTE_AUTHENTICATION = '/sign-in';
export const ROUTE_REGISTRATION = '/sign-up';
export const ROUTE_REGISTRATION_FORM = '/sign-up/registration';
export const ROUTE_REGISTRATION_SUCCESS = '/sign-up/success';
export const ROUTE_REGISTRATION_PASSWORD = '/sign-up/active';
export const ROUTE_REGISTRATION_RESTORE = '/sign-up/restore';
export const ROUTE_REGISTRATION_RESTORE_SUCCESS = '/sign-up/restore-success';
export const ROUTE_RESTORE = '/restore';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_PAYMENTS = '/payments';
export const ROUTE_ADMIN = '/admin';
export const ROUTE_ADMIN_PANEL = '/admin/panel';
export const ROUTE_ADMIN_PAYMENTS = '/admin/payments';
export const ROUTE_ADMIN_REQUESTS = '/admin/requests';
export const ROUTE_ADMIN_FEEDBACK = '/admin/feedback';
export const ROUTE_ADMIN_EDIT_TEMPLE = '/admin/temples/:id';
export const ROUTE_ADMIN_REGISTRATION_REQUEST = '/admin/registration/:id';
export const ROUTE_ADMIN_ADD_TEMPLE = '/admin/temples/add';
export const ROUTE_PAYMENT_TEMPLATE = `${config.baseUrl}/donation/get-template?uuid=:uuid`;

export const getTemplePage = (
  templeId?: number,
  type?: TempleViewType
): string => {
  return ROUTE_TEMPLE_PAGE.replace(':id', String(templeId)).replace(
    ':donate',
    type ? type : TempleViewType.VIEW
  );
};

export const getRegistrationRequestPage = (templeId?: number): string => {
  return ROUTE_ADMIN_REGISTRATION_REQUEST.replace(':id', String(templeId));
};

export const getAdminTemplePage = (templeId?: number): string => {
  return ROUTE_ADMIN_EDIT_TEMPLE.replace(':id', String(templeId));
};

export const getPaymentTemplate = (uuid: string): string => {
  return ROUTE_PAYMENT_TEMPLATE.replace(':uuid', uuid);
};
