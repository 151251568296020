import { AuthenticationForm } from '@/components/auth/AuthenticationForm';
import { Box, Grid } from '@mui/material';
import { RegistrationInfo } from '@/components/auth/RegistrationInfo';
import { Theme } from '@mui/material/styles';
import React, { FC, Fragment } from 'react';

export const AuthenticationPage: FC = () => {
  return (
    <Fragment>
      <Grid
        container={true}
        mb={{ md: 20, xs: 10 }}
        direction={{ md: 'row', xs: 'column-reverse' }}
        rowSpacing={2}>
        <Grid item={true} md={6} xs={12} mt={4}>
          <RegistrationInfo />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            position={'relative'}
            width={'100%'}
            height={'min-content'}
            borderRadius={'12px'}
            maxWidth={(theme: Theme) => theme.spacing(80)}
            padding={(theme: Theme) => theme.spacing(6, 5, 7)}
            sx={{
              backgroundColor: (theme: Theme) => theme.colors.grayBackground,
              margin: 'auto',
            }}>
            <Box
              display={'flex'}
              width={'100%'}
              maxWidth={(theme: Theme) => theme.spacing(40)}>
              <AuthenticationForm />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};
