import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const ExitIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 16 16'}
    sx={{ width: '16px', height: '16px' }}
    {...props}>
    <path
      d={
        'M11.4571 1.09085C11.4353 1.07983 11.4109 1.07462 11.3865 1.07571C11.362 1.07679 11.3383 1.08414 11.3175 1.09704C11.2967 1.10995 11.2796 1.12798 11.2677 1.14941C11.2559 1.17084 11.2498 1.19495 11.25 1.21942V2.36585C11.25 2.46406 11.3018 2.55513 11.3839 2.60871C11.7866 2.86216 12.1592 3.1605 12.4946 3.49799C13.0785 4.08371 13.5375 4.76406 13.8571 5.52121C14.1877 6.30501 14.3571 7.14733 14.3553 7.99799C14.3553 8.85692 14.1875 9.69085 13.8571 10.4748C13.5378 11.2308 13.0752 11.9179 12.4946 12.498C11.916 13.08 11.2294 13.5434 10.4732 13.8623C9.69105 14.1944 8.85712 14.3623 7.99997 14.3623C7.14283 14.3623 6.3089 14.1944 5.52676 13.8623C4.77058 13.5434 4.08393 13.08 3.50533 12.498C2.9248 11.9179 2.46211 11.2308 2.14283 10.4748C1.81226 9.69097 1.64283 8.84865 1.64462 7.99799C1.64462 7.13906 1.81247 6.30513 2.14283 5.52121C2.46247 4.76406 2.9214 4.08371 3.50533 3.49799C3.84462 3.15871 4.21605 2.86228 4.61604 2.60871C4.69997 2.55692 4.74997 2.46406 4.74997 2.36585V1.21763C4.74997 1.11049 4.63747 1.04263 4.54283 1.08906C2.04462 2.34621 0.321402 4.91942 0.285687 7.90156C0.235687 12.1658 3.72319 15.7087 7.98569 15.7176C12.2535 15.7266 15.7143 12.2659 15.7143 7.99621C15.7143 4.97478 13.9821 2.35871 11.4571 1.09085ZM7.42855 8.86049H8.5714C8.64997 8.86049 8.71426 8.79621 8.71426 8.71764V0.43192C8.71426 0.353348 8.64997 0.289062 8.5714 0.289062H7.42855C7.34997 0.289062 7.28569 0.353348 7.28569 0.43192V8.71764C7.28569 8.79621 7.34997 8.86049 7.42855 8.86049Z'
      }
      fill={'#797A7B'}
    />
  </SvgIcon>
);
