import { Autoplay } from 'swiper';
import { Box } from '@mui/material';
import { MainImageContainer } from '@/components/common/swiper/MainImageContainer';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image1 from '@assets/images/swiper/image1.jpg';
import Image2 from '@assets/images/swiper/image2.jpg';
import Image3 from '@assets/images/swiper/image3.jpg';
import React, { FC } from 'react';
import 'swiper/css';

export const MainSwiper: FC = () => {
  return (
    <Box
      position={'absolute'}
      height={'736px'}
      left={0}
      right={0}
      top={0}
      zIndex={-1}>
      <Swiper
        loop={true}
        pagination={false}
        autoplay={{ delay: 10000 }}
        modules={[Autoplay]}>
        <SwiperSlide>
          <MainImageContainer src={Image1} shadow={0.4} />
        </SwiperSlide>
        <SwiperSlide>
          <MainImageContainer src={Image2} shadow={0.2} />
        </SwiperSlide>
        <SwiperSlide>
          <MainImageContainer src={Image3} shadow={0.2} />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};
