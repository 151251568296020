import { AdminRejectView } from '@/components/modal/AdminRejectView';
import { AdminTemplePreview } from '@/components/modal/AdminTemplePreview';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, Typography } from '@mui/material';
import { RegistrationRequestDTO } from '@/types/RegistrationRequestDTO';
import { TempleForm } from '@/components/common/TempleForm';
import { TempleFormValues } from '@/types/form/TempleFormValues';
import { fillDtoFromRegistrationForm } from '@/utils/request-utils';
import { getCurrentEditTempleForm } from '@/services/templesSlice';
import { useAppSelector } from '@/app/hooks';
import {
  useEditRegistrationRequestMutation,
  useGetRegistrationRequestQuery,
} from '@/services/api/requestApiSlice';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useState } from 'react';

interface Params {
  id: string;
}

export const AdminApproveRequestPage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<Params>();

  const {
    data: temple,
    isLoading: isTempleLoading,
    isFetching: isTempleFetching,
  } = useGetRegistrationRequestQuery(parseInt(id));

  const [openPreview, setOpenPreview] = useState<boolean>(false);

  const [editRequestValue, setEditRequestValue] = useState<{
    request: RegistrationRequestDTO;
    images: File[];
  } | null>(null);

  const [editRegistrationRequest, { isLoading: isLoading }] =
    useEditRegistrationRequestMutation();
  const handleSubmitPreview = async (
    values: TempleFormValues
  ): Promise<void> => {
    const requestDto: RegistrationRequestDTO = fillDtoFromRegistrationForm(
      values,
      parseInt(id)
    );
    await editRegistrationRequest({
      request: { id: parseInt(id), ...requestDto },
      images: values.attachFiles,
    })
      .unwrap()
      .then(() => {
        if (temple?.edited) {
          setEditRequestValue({
            request: requestDto,
            images: values.attachFiles,
          });
        }
        setOpenPreview(true);
      })
      .catch((e: { status: number; data: ErrorDTO }) => {
        enqueueSnackbar(e.data.message, {
          variant: 'error',
        });
      });
  };

  const [openRejectView, setOpenRejectView] = useState<boolean>(false);

  const handleClearEditValue = () => {
    setEditRequestValue(null);
  };
  const initialEditValues = useAppSelector(getCurrentEditTempleForm);
  return (
    <Fragment>
      <Grid container={true} direction={'column'}>
        <Grid item={true} pb={2}>
          <Typography variant={'h2'}>
            {temple?.edited
              ? 'Одобрение внесенных изменений в церкви'
              : 'Одобрение внесенных изменений в церкви'}
          </Typography>
        </Grid>
        <Grid item={true} pb={4}>
          <Typography variant={'body2'}>
            {temple?.edited
              ? 'Для одобрения заявки на изменения необходимо проверить все поля'
              : 'Для одобрения заявки на подключение необходимо проверить все поля'}
          </Typography>
        </Grid>
        <Grid item={true}>
          <TempleForm
            initialFormValues={initialEditValues}
            temple={temple}
            authUser={temple?.deacon}
            loadingData={isTempleFetching || isTempleLoading}
            sendingData={isLoading}
            handleSubmit={handleSubmitPreview}
            submitButtonText={'Продолжить'}
            isAdminPage={true}
          />
        </Grid>
      </Grid>
      <AdminTemplePreview
        open={openPreview}
        setOpen={setOpenPreview}
        temple={temple}
        isTempleFetching={isTempleFetching}
        isTempleLoading={isTempleLoading}
        setOpenRejectView={setOpenRejectView}
        editRequestValue={editRequestValue}
        clearEditRequestValue={handleClearEditValue}
      />
      {temple && (
        <AdminRejectView
          open={openRejectView}
          setOpen={setOpenRejectView}
          temple={temple}
          setOpenPreview={setOpenPreview}
        />
      )}
    </Fragment>
  );
};
