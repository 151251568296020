import { BannerCloseButton } from '@/components/common/banner/BannerCloseButton';
import { InfoIcon } from '@/app/icons/InfoIcon';
import { OutlineSuccessIcon } from '@/app/icons/OutlineSuccessIcon';
import { OutlineWarnIcon } from '@/app/icons/OutlineWarnIcon';
import {
  SnackbarKey,
  SnackbarProvider,
  SnackbarProviderProps,
} from 'notistack';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';

type Props = SnackbarProviderProps;

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: `${theme.colors.greenBackground} !important`,
    borderRadius: '12px !important',
    padding: `${theme.spacing(1, 2)} !important`,
    '& .SnackbarItem-action .MuiIconButton-root': {
      color: 'white',
    },
  },
  error: {
    backgroundColor: `${theme.colors.red} !important`,
    borderRadius: '12px !important',
    padding: `${theme.spacing(1, 2)} !important`,
    '& .SnackbarItem-action .MuiIconButton-root': {
      color: 'white',
    },
  },
  info: {
    color: `${theme.colors.textColor} !important`,
    backgroundColor: `white !important`,
    borderRadius: '12px !important',
    padding: `${theme.spacing(1, 2)} !important`,
    '& .SnackbarItem-action .MuiIconButton-root': {
      color: theme.colors.textColor,
    },
  },
  warning: {
    color: `${theme.colors.textColor} !important`,
    backgroundColor: `${theme.colors.tooltip} !important`,
    borderRadius: '12px !important',
    padding: `${theme.spacing(1, 2)} !important`,
    '& .SnackbarItem-action .MuiIconButton-root': {
      color: theme.colors.textColor,
    },
  },
}));

export const BannerProvider: FC<Props> = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <SnackbarProvider
      style={{ flexWrap: 'nowrap' }}
      maxSnack={3}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      iconVariant={{
        success: (
          <OutlineSuccessIcon
            sx={{
              width: (theme: Theme) => theme.spacing(3),
              height: (theme: Theme) => theme.spacing(3),
              color: 'white',
              display: 'block',
              marginRight: (theme: Theme) => theme.spacing(1),
            }}
          />
        ),
        error: (
          <InfoIcon
            sx={{
              width: (theme: Theme) => theme.spacing(3),
              height: (theme: Theme) => theme.spacing(3),
              color: 'white',
              display: 'block',
              marginRight: (theme: Theme) => theme.spacing(1),
            }}
          />
        ),
        info: (
          <InfoIcon
            sx={{
              width: (theme: Theme) => theme.spacing(3),
              height: (theme: Theme) => theme.spacing(3),
              color: (theme: Theme) => theme.colors.textColor,
              display: 'block',
              marginRight: (theme: Theme) => theme.spacing(1),
            }}
          />
        ),
        warning: (
          <OutlineWarnIcon
            sx={{
              width: (theme: Theme) => theme.spacing(3),
              height: (theme: Theme) => theme.spacing(3),
              color: (theme: Theme) => theme.colors.textColor,
              display: 'block',
              marginRight: (theme: Theme) => theme.spacing(1),
            }}
          />
        ),
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantInfo: classes.info,
        variantWarning: classes.warning,
      }}
      action={(key: SnackbarKey) => <BannerCloseButton snackbarKey={key} />}
      {...props}>
      {children}
    </SnackbarProvider>
  );
};
