import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const DownIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={
        'M12.3945 16.4795C12.6318 16.4795 12.8516 16.374 13.0186 16.1982L19.417 9.65039C19.5752 9.49219 19.6631 9.29004 19.6631 9.06152C19.6631 8.58691 19.3027 8.22656 18.8281 8.22656C18.6084 8.22656 18.3887 8.31445 18.2305 8.47266L12.3945 14.4492L6.5498 8.47266C6.3916 8.31445 6.18945 8.22656 5.95215 8.22656C5.48633 8.22656 5.11719 8.58691 5.11719 9.06152C5.11719 9.29004 5.20508 9.49219 5.36328 9.65039L11.7617 16.1982C11.9463 16.3828 12.1484 16.4795 12.3945 16.4795Z'
      }
    />
  </SvgIcon>
);
