import * as Yup from 'yup';
import {
  MESSAGE_INVALID_EMAIl,
  MESSAGE_REQUIRED,
} from '@/utils/validation-utils';

export const emailSchema: Yup.StringSchema = Yup.string()
  .required(MESSAGE_REQUIRED)
  .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{1,10}$/, MESSAGE_INVALID_EMAIl)
  .email(MESSAGE_INVALID_EMAIl);
