import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const CloseIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={
        'M13.3866 11.9997L20.4178 3.61847C20.5357 3.47919 20.4366 3.26758 20.2545 3.26758H18.117C17.9911 3.26758 17.8705 3.32383 17.7875 3.42026L11.9884 10.3336L6.18928 3.42026C6.10892 3.32383 5.98838 3.26758 5.85981 3.26758H3.72231C3.54017 3.26758 3.44106 3.47919 3.55892 3.61847L10.5902 11.9997L3.55892 20.381C3.53252 20.412 3.51558 20.45 3.51012 20.4904C3.50465 20.5308 3.5109 20.5719 3.5281 20.6088C3.5453 20.6458 3.57275 20.677 3.60717 20.6988C3.6416 20.7206 3.68156 20.7321 3.72231 20.7319H5.85981C5.98571 20.7319 6.10624 20.6756 6.18928 20.5792L11.9884 13.6658L17.7875 20.5792C17.8678 20.6756 17.9884 20.7319 18.117 20.7319H20.2545C20.4366 20.7319 20.5357 20.5203 20.4178 20.381L13.3866 11.9997Z'
      }
    />
  </SvgIcon>
);
