import { Grid, Skeleton } from '@mui/material';
import { useGetItWorkContentQuery } from '@/services/api/helpApiSlice';
import CollapseHelpText from '@/components/help/CollapseHelpText';
import React, { FC } from 'react';

export const WorkBlock: FC = () => {
  const { data: data, isFetching: isFetching } = useGetItWorkContentQuery();
  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'}>
      {isFetching
        ? Array.from(
            new Array(4).map((value, index) => (
              <Grid item={true} key={index}>
                <Skeleton
                  width={'100%'}
                  height={'48px'}
                  variant={'rectangular'}
                  sx={{ borderRadius: '6px' }}
                />
              </Grid>
            ))
          )
        : data &&
          data.map((dto, index) => (
            <Grid item={true} key={index}>
              <CollapseHelpText tittle={dto.subject} hide={index > 0}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: dto.content,
                  }}
                />
              </CollapseHelpText>
            </Grid>
          ))}
    </Grid>
  );
};
