import { Box, Theme, useMediaQuery } from '@mui/material';
import { InformationTemplate } from '@/components/common/InformationTemplate';
import React, { FC } from 'react';

export const RegistrationSuccessPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  return (
    <Box mt={isMobile ? 4 : 0}>
      <InformationTemplate
        title={'Заявка успешно подана!'}
        content={
          'В данный момент заявка проходит ' +
          'проверку модератором. Ответ с ' +
          'результатами регистрации вам ' +
          'придет на указанную почту. Если у ' +
          'вас появятся вопросы, уточнить их ' +
          'можно через раздел помощь.'
        }
        isMobile={isMobile}
      />
    </Box>
  );
};
