import { EyeCrossedIcon } from '@/app/icons/EyeCrossedIcon';
import { EyeIcon } from '@/app/icons/EyeIcon';
import { IconButton, InputAdornment } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC, MouseEvent } from 'react';

interface Props {
  showPassword: boolean;
  setShowPassword: (isShow: boolean) => void;
}

export const PasswordAdornment: FC<Props> = (props: Props) => {
  const { showPassword, setShowPassword } = props;
  const handleShowPasswordClick = (): void => {
    setShowPassword(!showPassword);
  };
  return (
    <InputAdornment position={'end'}>
      <IconButton
        aria-label={'toggle password visibility'}
        size={'dense'}
        edge={'end'}
        sx={{ padding: 0, margin: 0 }}
        onClick={handleShowPasswordClick}
        onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
        }}>
        {showPassword ? (
          <EyeIcon
            sx={{
              width: (theme: Theme) => theme.spacing(4),
              height: (theme: Theme) => theme.spacing(4),
            }}
          />
        ) : (
          <EyeCrossedIcon
            sx={{
              width: (theme: Theme) => theme.spacing(4),
              height: (theme: Theme) => theme.spacing(4),
            }}
          />
        )}
      </IconButton>
    </InputAdornment>
  );
};
