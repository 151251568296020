import {
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props extends SelectProps {
  size?: 'small' | 'medium';
  helperText?: string;
  disabled?: boolean;
  withLabel?: boolean;
  isNotAbsoluteHelperText?: boolean;
}

export const OutlinedSelect: FC<Props> = (props: Props) => {
  const {
    size,
    label,
    error,
    helperText,
    children,
    withLabel,
    isNotAbsoluteHelperText,
    ...other
  } = props;
  return (
    <FormControl
      variant={'outlined'}
      size={size}
      fullWidth={true}
      sx={(theme: Theme) => ({
        marginTop: theme.spacing(withLabel ? 3 : 0),
      })}>
      {label && (
        <FormLabel error={error} id={props.name + '-input-label'}>
          {label}
        </FormLabel>
      )}
      <Select
        id={'props.name + -select'}
        error={error}
        renderValue={
          other.value === undefined || other.value === null
            ? () => (
                <Typography color={(theme: Theme) => theme.colors.grayText}>
                  {other.placeholder || ''}
                </Typography>
              )
            : other.renderValue
        }
        {...other}>
        {children}
      </Select>
      {helperText && (
        <FormHelperText
          error={error}
          id={props.name + '-input-helperText'}
          sx={
            isNotAbsoluteHelperText
              ? { position: 'inherit' }
              : {
                  position: 'absolute',
                  transform:
                    size == 'small'
                      ? 'translate(0, 40px)'
                      : 'translate(0, 48px)',
                }
          }>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
