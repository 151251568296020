import { getNumEnding } from '@/utils/string-utils';

export const MIN_DONATION = 50;
export const MAX_DONATION = 999999999;
export const MESSAGE_REQUIRED = 'Обязательное поле';
export const MESSAGE_INVALID_EMAIl = 'Некорректный адрес электронной почты';
export const MESSAGE_FULL_NAME_REQUIRED = 'Укажите, как минимум, Фамилию и Имя';
export const MESSAGE_FULL_NAME_ALLOWED_CHARACTERS = 'Недопустимые символы';
export const MESSAGE_INVALID_MIN_AMOUNT =
  'Сумма пожертвований не может быть меньше ${MIN_AMOUNT} руб.';
export const MESSAGE_INVALID_MAX_AMOUNT =
  'Сумма пожертвований не может быть больше ${MAX_AMOUNT} руб.';
export const MESSAGE_INVALID_CREDENTIALS =
  'Неверный адрес электронной почты или пароль';
export const MESSAGE_UNKNOWN = 'Неизвестная ошибка';
export const MESSAGE_INVALID_PHONE = 'Некорректный формат номера';
export const MESSAGE_INVALID_BANK_DETAILS = 'Некорректные реквизиты';
export const MESSAGE_INVALID_FILE_IMAGES = 'Выберите файлы';
export const MESSAGE_INVALID_BANK_DETAILS_SIZE = 'Неполный номер';
export const MESSAGE_INVALID_MAX_SIZE = 'Введите не более ${MAX_LETTERS}';
export const MESSAGE_FIELDS_NOT_MATCH = 'Поля не совпадают';
export const MESSAGE_PASSWORD_CAPITAL_LETTER = 'Не содержит заглавную букву';
export const MESSAGE_PASSWORD_NUMBER = 'Не содержит цифру';
export const MESSAGE_MIN_PASSWORD_LENGTH =
  'Пароль должен содержать минимум 8 символов';

export const allowedFullNameCharsRegExp = /^[А-яёЁa-zA-Z-\s]+$/g;

export const fullNameRegExp =
  /^\s*(?<lastName>[А-яёЁa-zA-Z-]+)\s+(?<firstName>[А-яёЁa-zA-Z-]+)(?<middleName>[А-яёЁa-zA-Z-\s]+)?\s*$/;

export const passwordNumberRegExp = /.*[0-9]+.*/g;

export const fullNumberRangeRegExp = /[0-9]/;

export const passwordCapitalLetterRegExp = /.*[A-Z]+.*/g;

export const getMessageMaxLetterSize = (maxSize: number): string => {
  return MESSAGE_INVALID_MAX_SIZE.replace(
    '${MAX_LETTERS}',
    `${maxSize} ${getNumEnding(maxSize, ['символа', 'символов', 'символов'])}`
  );
};

export const getMessageInvalidMaxAmount = (maxAmount?: number): string => {
  return MESSAGE_INVALID_MAX_AMOUNT.replace(
    '${MAX_AMOUNT}',
    maxAmount ? maxAmount.toString() : MAX_DONATION.toString()
  );
};

export const getMessageInvalidMinAmount = (minAmount?: number): string => {
  return MESSAGE_INVALID_MIN_AMOUNT.replace(
    '${MIN_AMOUNT}',
    minAmount ? minAmount.toString() : MIN_DONATION.toString()
  );
};
