import { AdminPaymentsList } from '@/components/admin/payments/AdminPaymentsList';
import { DateRangeButton } from '@/components/admin/panel/DateRangeButton';
import { Grid, MenuItem, Pagination, Theme, Typography } from '@mui/material';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { RegionDTO } from '@/types/RegionDTO';
import { RegionSelectField } from '@/components/common/field/RegionSelectField';
import { TempleDTO } from '@/types/TempleDTO';
import { dateToUTC, getEndDateRange, getStartDate } from '@/utils/date-utils';
import { formatNumberWithSpaces, getNumEnding } from '@/utils/string-utils';
import {
  getAdminPageRequest,
  setAdminPageNumber,
  setDefaultAdminPageValues,
} from '@/services/adminSlice';
import { getTotalNumberOfPages } from '@/utils/pagination-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetAdminDonationsPageQuery } from '@/services/api/donationsApiSlice';
import { useGetRegionsQuery } from '@/services/api/templesApiSlice';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

export const AdminPaymentsPage: FC = () => {
  const dispatch = useAppDispatch();
  const pageRequest = useAppSelector(getAdminPageRequest);
  const [currentRegion, setCurrentRegion] = useState<RegionDTO | null>(null);
  const [currentTemple, setCurrentTemple] = useState<TempleDTO | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(getStartDate());
  const [endDate, setEndDate] = useState<Date | null>(
    getEndDateRange(new Date())
  );
  const { data: regions } = useGetRegionsQuery();
  const { data: adminDonations } = useGetAdminDonationsPageQuery(
    {
      pageSize: pageRequest.pageSize,
      pageNumber: pageRequest.pageNumber,
      regionId: currentRegion?.id,
      templeId: currentTemple?.id,
      dateStart: startDate ? dateToUTC(startDate).getTime() : null,
      dateEnd: endDate ? dateToUTC(endDate).getTime() : null,
    },
    { refetchOnMountOrArgChange: true }
  );
  const setRegion = (value: RegionDTO | null) => {
    dispatch(setDefaultAdminPageValues());
    setCurrentRegion(value);
  };
  const setTemple = (value?: TempleDTO | null) => {
    dispatch(setDefaultAdminPageValues());
    setCurrentTemple(value ? value : null);
  };
  const handleSetDates = (dateStart: Date | null, dateEnd: Date | null) => {
    dispatch(setDefaultAdminPageValues());
    setStartDate(dateStart);
    setEndDate(dateEnd);
  };
  const handlePageChange = (page: number) => {
    if (pageRequest.pageNumber !== page - 1) {
      dispatch(setAdminPageNumber(page - 1));
    }
  };
  useEffect(() => {
    return () => {
      dispatch(setDefaultAdminPageValues());
    };
  }, []);

  return (
    <Grid container={true} direction={'column'} spacing={3}>
      <Grid item={true}>
        <Typography fontSize={'24px'} fontWeight={500} lineHeight={'30px'}>
          {'Реестр платежей'}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Grid
          container={true}
          direction={'row'}
          alignItems={'center'}
          width={'100%'}>
          <Grid item={true} xs={8}>
            <Grid container={true} spacing={4} justifyContent={'flex-start'}>
              <Grid item={true} sm={4}>
                <RegionSelectField
                  onChange={setRegion}
                  regions={regions}
                  withoutLabel={true}
                />
              </Grid>
              <Grid item={true} sm={4}>
                <OutlinedSelect
                  displayEmpty={true}
                  withLabel={false}
                  disabled={!currentRegion}
                  value={currentTemple?.id}
                  onChange={(event) => {
                    const selectTemple = currentRegion?.temples.find(
                      (temple) => temple.id === (event.target.value as number)
                    );
                    setTemple(selectTemple);
                  }}>
                  {currentRegion?.temples &&
                    currentRegion.temples.map((value) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                </OutlinedSelect>
              </Grid>
              <Grid item={true} sm={4}>
                <DateRangeButton
                  setDates={handleSetDates}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={4}>
            <Grid
              container={true}
              direction={'row'}
              spacing={2}
              justifyContent={'flex-end'}>
              <Grid item={true}>
                <Typography fontSize={'16px'} lineHeight={'24px'}>
                  {`${adminDonations?.transactionsAmount} ${getNumEnding(
                    adminDonations && adminDonations.transactionsAmount
                      ? adminDonations.transactionsAmount
                      : 0,
                    ['транзакция', 'транзакции', 'транзакций']
                  )}`}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography
                  fontSize={'16px'}
                  lineHeight={'24px'}
                  color={(theme: Theme) => theme.colors.grayText}>
                  {'на общую сумму'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography fontSize={'16px'} lineHeight={'24px'}>
                  {`${
                    adminDonations && adminDonations?.donationAmount
                      ? formatNumberWithSpaces(adminDonations.donationAmount)
                      : 0
                  } руб.`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <AdminPaymentsList donations={adminDonations?.donations} />
      </Grid>
      <Grid item={true} mt={1}>
        <Grid
          container={true}
          direction={'column'}
          alignItems={'flex-end'}
          justifyContent={'flex-end'}>
          <Grid item={true} mt={2} display={'flex'}>
            <Pagination
              defaultPage={pageRequest.pageNumber + 1}
              page={pageRequest.pageNumber + 1}
              count={
                adminDonations?.transactionsAmount &&
                adminDonations?.transactionsAmount > 0
                  ? getTotalNumberOfPages(
                      adminDonations?.transactionsAmount,
                      pageRequest.pageSize
                    )
                  : 1
              }
              onChange={(e: ChangeEvent<unknown>, page: number) => {
                handlePageChange(page);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
