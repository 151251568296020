import { AdminPanelActiveType } from '@/types/filter/AdminPanelActiveType';
import { AdminPanelEntityType } from '@/types/filter/AdminPanelEntityType';
import {
  Button,
  Grid,
  InputAdornment,
  Pagination,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DateRangeButton } from '@/components/admin/panel/DateRangeButton';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PanelList } from '@/components/admin/panel/PanelList';
import { ROUTE_ADMIN_ADD_TEMPLE } from '@/app/routes';
import { SearchIcon } from '@/app/icons/SearchIcon';
import { ToggleButtonItem } from '@/components/admin/panel/ToggleButtonItem';
import {
  dateToUTC,
  getEndDateRange,
  getStartDate,
  getStartDateRange,
} from '@/utils/date-utils';
import { format } from 'date-fns';
import {
  getAdminPageRequest,
  setAdminPageNumber,
  setDefaultAdminPageValues,
} from '@/services/adminSlice';
import { getTotalNumberOfPages } from '@/utils/pagination-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useDebounce } from '@/app/hooks/useDebounce';
import {
  useGetAdminTableQuery,
  useGetRequestsCountMutation,
} from '@/services/api/templesApiSlice';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

export const PanelPage: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const pageRequest = useAppSelector(getAdminPageRequest);
  const [requestsCount, setRequestsCount] = useState<number | undefined>(
    undefined
  );
  const [filterType, setFilterType] = useState<AdminPanelEntityType>(
    AdminPanelEntityType.ALL
  );
  const [activeType, setActiveType] = useState<AdminPanelActiveType | null>(
    null
  );
  const [startDate, setStartDate] = useState<Date | null>(getStartDate());
  const [endDate, setEndDate] = useState<Date | null>(
    getEndDateRange(new Date())
  );
  const [searchString, setSearchString] = useState<string | undefined>(
    undefined
  );
  const [searchRequestValues, setSearchRequestValues] = useState<
    string | undefined
  >(undefined);
  const [getRequestsCount, { isLoading: isRequestsCountLoading }] =
    useGetRequestsCountMutation();
  const { data: adminPage } = useGetAdminTableQuery(
    {
      pageSize: pageRequest.pageSize,
      pageNumber: pageRequest.pageNumber,
      searchString: searchRequestValues ? searchRequestValues : undefined,
      dateStart: startDate ? format(dateToUTC(startDate), 'yyyy-MM-dd') : null,
      dateEnd: endDate ? format(dateToUTC(endDate), 'yyyy-MM-dd') : null,
      entityType: filterType,
      activeType: activeType ? activeType : null,
    },
    { refetchOnMountOrArgChange: true }
  );
  const handleSetFilterType = (
    event: React.MouseEvent<HTMLElement>,
    type: AdminPanelEntityType
  ) => {
    if (type != null) {
      dispatch(setDefaultAdminPageValues());
      handleSetActiveType(null);
      setFilterType(type);
    }
  };
  const handleSetDates = (dateStart: Date | null, dateEnd: Date | null) => {
    dispatch(setDefaultAdminPageValues());
    setStartDate(dateStart);
    setEndDate(dateEnd);
  };
  const handleStringSearchRequest = useDebounce<string>(
    (searchString: string): void => {
      dispatch(setDefaultAdminPageValues());
      setSearchRequestValues(searchString);
    },
    500
  );
  const handleSetSearchString = (searchString: string) => {
    handleStringSearchRequest(searchString);
    setSearchString(searchString);
  };
  const handleSetActiveType = (type: AdminPanelActiveType | null) => {
    dispatch(setDefaultAdminPageValues());
    setActiveType(type);
  };
  const handlePageChange = (page: number) => {
    if (pageRequest.pageNumber !== page - 1) {
      dispatch(setAdminPageNumber(page - 1));
    }
  };
  const handleGetRequestsCount = async (): Promise<void> => {
    if (!isRequestsCountLoading) {
      await getRequestsCount()
        .unwrap()
        .then((count: number) => {
          setRequestsCount(count);
        })
        .catch(() => {
          enqueueSnackbar('Невозможно загрузить количество заявок', {
            variant: 'error',
          });
        });
    }
  };
  useEffect(() => {
    handleGetRequestsCount();
  }, [adminPage, requestsCount]);
  useEffect(() => {
    return () => {
      dispatch(setDefaultAdminPageValues());
    };
  }, []);

  return (
    <Grid
      container={true}
      direction={'column'}
      position={'relative'}
      spacing={1}>
      <Grid item={true}>
        <Typography fontSize={'24px'} fontWeight={500} lineHeight={'30px'}>
          {'Панель администратора'}
        </Typography>
      </Grid>
      <Grid item={true} mt={2}>
        <Grid
          container={true}
          spacing={4}
          justifyContent={'flex-start'}
          wrap={'nowrap'}>
          <Grid item={true} xs={4} xl={4.5}>
            <OutlinedField
              name={'searchString'}
              size={'medium'}
              value={searchString}
              placeholder={
                'Поиск по имени, ИНН, названию церкви, номеру телефона'
              }
              onChange={(e) => handleSetSearchString(e.target.value)}
              endAdornment={
                <InputAdornment position={'end'}>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item={true} xs={2.5} xl={3} width={'100%'}>
            <DateRangeButton
              setDates={handleSetDates}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
          <Grid item={true} xs={3.7} xl={2.7}>
            <ToggleButtonGroup
              size={'medium'}
              color={'primary'}
              value={filterType}
              exclusive={true}
              onChange={handleSetFilterType}
              sx={{
                backgroundColor: 'white',
                '& .MuiToggleButtonGroup-grouped': {
                  '&:first-of-type': {
                    borderTopLeftRadius: '6px',
                    borderBottomLeftRadius: '6px',
                  },
                  '&:last-of-type': {
                    borderTopRightRadiusRadius: '6px',
                    borderBottomRightRadius: '6px',
                  },
                },
              }}>
              <ToggleButtonItem
                value={AdminPanelEntityType.ALL}
                label={'Все'}
              />
              <ToggleButtonItem
                value={AdminPanelEntityType.REQUEST}
                label={'Заявки'}
                chipColor={'#4fad2d'}
                count={requestsCount}
              />
              <ToggleButtonItem
                value={AdminPanelEntityType.TEMPLE}
                label={'Церкви'}
                chipColor={'#3e9ded'}
              />
            </ToggleButtonGroup>
          </Grid>
          <Grid item={true} width={'100%'} xs={1.8}>
            <Grid container={true} justifyContent={'flex-end'}>
              <Grid item={true} width={'100%'}>
                <Button
                  fullWidth={true}
                  variant={'contained'}
                  onClick={() => history.push(ROUTE_ADMIN_ADD_TEMPLE)}>
                  <Typography
                    fontSize={'16px'}
                    lineHeight={'24px'}
                    fontWeight={400}>
                    {'Добавить церковь'}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <PanelList
          items={adminPage?.content}
          filterType={filterType}
          activeType={activeType}
          setActiveType={setActiveType}
        />
      </Grid>
      <Grid item={true} mt={1}>
        <Grid
          container={true}
          direction={'column'}
          alignItems={'flex-end'}
          justifyContent={'flex-end'}>
          <Grid item={true} mt={2} display={'flex'}>
            <Pagination
              defaultPage={pageRequest.pageNumber + 1}
              page={pageRequest.pageNumber + 1}
              count={
                adminPage?.count && adminPage?.count > 0
                  ? getTotalNumberOfPages(
                      adminPage?.count,
                      pageRequest.pageSize
                    )
                  : 1
              }
              onChange={(e: ChangeEvent<unknown>, page: number) => {
                handlePageChange(page);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
