export interface Config {
  nodeEnv?: string;
  links: {
    social: {
      vk?: string;
    };
    money?: string;
  };
  baseUrl?: string;
  basePath?: string;
}

const env: string = process.env.ENV || 'production';
const isDevelopment: boolean = env === 'development' || env === 'local';
const variables = isDevelopment ? process.env : (window as any)._env_;

export const config: Config = {
  nodeEnv: variables.NODE_ENV,
  links: {
    social: {
      vk: variables.VK_URL,
    },
    money: variables.MONEY_URL,
  },
  baseUrl: variables.BASE_URL,
  basePath: variables.BASE_PATH,
};

export const GET_OFFER_URL = config.baseUrl + '/static/offer';
