import { AttachFileDTO } from '@/types/AttachFileDTO';
import { InitTempleFormValues } from '@/types/form/InitTempleFormValues';
import { PageRequestDTO } from '@/types/PageRequestDTO';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RegionDTO } from '@/types/RegionDTO';
import { RootState } from '@/app/store';
import { TempleDTO } from '@/types/TempleDTO';
import { TempleFormValues } from '@/types/form/TempleFormValues';
import { getFileArray } from '@/utils/file-utils';

export interface TemplesState {
  region: RegionDTO | null;
  temple: TempleDTO | null;
  pageRequest: PageRequestDTO;
  createForm: InitTempleFormValues;
  editForm: InitTempleFormValues;
}

const initialState: TemplesState = {
  pageRequest: {
    pageSize: 12,
    mobilePageSize: 4,
    pageNumber: 0,
  },
  region: null,
  temple: null,
  createForm: {
    name: '',
    address: '',
    description: '',
    inn: '',
    kpp: '',
    oktmo: '',
    bic: '',
    bankAccount: '',
    bankName: '',
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    email: '',
    attachFiles: [],
    region: null,
    mainImageIndex: 0,
  },
  editForm: {
    name: '',
    address: '',
    description: '',
    inn: '',
    kpp: '',
    oktmo: '',
    bic: '',
    bankAccount: '',
    bankName: '',
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    email: '',
    attachFiles: [],
    region: null,
    mainImageIndex: 0,
  },
};

export const templesSlice = createSlice({
  name: 'temples',
  initialState,
  reducers: {
    setPageNumber(state: TemplesState, action: PayloadAction<number>) {
      state.pageRequest.pageNumber = action.payload;
    },
    setPageSize(state: TemplesState, action: PayloadAction<number>) {
      state.pageRequest.pageSize = action.payload;
    },
    setDefaultPageValues(state: TemplesState) {
      state.pageRequest.pageNumber = 0;
      state.pageRequest.pageSize = 12;
    },
    setCurrentRegion(
      state: TemplesState,
      action: PayloadAction<RegionDTO | null>
    ) {
      state.region = action.payload;
    },
    setCurrentTemple(
      state: TemplesState,
      action: PayloadAction<TempleDTO | null>
    ) {
      state.temple = action.payload;
    },
    clearRegion(state: TemplesState) {
      state.temple = null;
      state.region = null;
    },
    clearTemple(state: TemplesState) {
      state.temple = null;
    },
    setCreateTempleForm(
      state: TemplesState,
      action: PayloadAction<InitTempleFormValues>
    ) {
      state.createForm = action.payload;
    },
    setAttachFileForm(
      state: TemplesState,
      action: PayloadAction<AttachFileDTO>
    ) {
      state.createForm.attachFiles.push(action.payload);
    },
    clearCreateTempleForm(state: TemplesState) {
      state.createForm = initialState.createForm;
    },
  },
});

export const {
  setPageNumber,
  setDefaultPageValues,
  setPageSize,
  setCurrentRegion,
  setCurrentTemple,
  clearRegion,
  clearTemple,
  setCreateTempleForm,
  clearCreateTempleForm,
  setAttachFileForm,
} = templesSlice.actions;

export const getPageRequest = (state: RootState): PageRequestDTO => {
  return state.temples.pageRequest;
};

export const getCurrentRegion = (state: RootState): RegionDTO | null => {
  return state.temples.region;
};

export const getCurrentTemple = (state: RootState): TempleDTO | null => {
  return state.temples.temple;
};

export const getCurrentCreateTempleForm = (
  state: RootState
): TempleFormValues => {
  return {
    ...state.temples.createForm,
    attachFiles: getFileArray(state.temples.createForm.attachFiles),
  };
};

export const getCurrentEditTempleForm = (
  state: RootState
): TempleFormValues => {
  return {
    ...state.temples.editForm,
    attachFiles: getFileArray(state.temples.editForm.attachFiles),
  };
};
