import { Box } from '@mui/material';
import { ImageContainer } from '@/components/common/swiper/ImageContainer';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image1 from '@assets/images/swiper/login/image1.png';
import Image2 from '@assets/images/swiper/login/image2.png';
import Image3 from '@assets/images/swiper/login/image3.png';
import React, { FC } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';

export const LoginMobileSwiper: FC = () => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        maxWidth: '100%',
        height: '250px',
      }}>
      <Swiper
        loop={true}
        spaceBetween={16}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className={'loginMobileSwiper'}>
        <SwiperSlide>
          <ImageContainer src={Image1} style={{ borderRadius: '12px' }} />
        </SwiperSlide>
        <SwiperSlide>
          <ImageContainer src={Image2} style={{ borderRadius: '12px' }} />
        </SwiperSlide>
        <SwiperSlide>
          <ImageContainer src={Image3} style={{ borderRadius: '12px' }} />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};
