import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const AdminDonationComment: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 23'}
    sx={{ width: '24px', height: '23px' }}
    {...props}>
    <path
      d={
        'M13.6343 9.56244C13.0156 9.56244 12.5361 10.0419 12.5361 10.6339C12.5361 11.2258 13.0156 11.7053 13.6343 11.7053C14.1995 11.7053 14.679 11.2258 14.679 10.6339C14.679 10.0419 14.1995 9.56244 13.6343 9.56244ZM6.13433 9.56244C5.51558 9.56244 5.03611 10.0419 5.03611 10.6339C5.03611 11.2258 5.51558 11.7053 6.13433 11.7053C6.6995 11.7053 7.17897 11.2258 7.17897 10.6339C7.17897 10.0419 6.6995 9.56244 6.13433 9.56244Z'
      }
      fill={'#a9abb2'}
    />
    <path
      d={
        'M22.2318 7.52464C20.9925 5.81877 19.2056 4.58964 17.1693 4.04249V4.04517C16.7113 3.53624 16.1943 3.06749 15.6157 2.64964C11.2309 -0.537865 5.07554 0.434457 1.87464 4.81928C-0.704822 8.38178 -0.595 13.1737 2.03536 16.5782L2.05679 20.13C2.05679 20.2157 2.07018 20.3014 2.09696 20.3818C2.13066 20.4891 2.18518 20.5888 2.25742 20.675C2.32965 20.7612 2.41818 20.8324 2.51795 20.8844C2.61771 20.9364 2.72674 20.9682 2.83881 20.978C2.95088 20.9878 3.06379 20.9755 3.17107 20.9416L6.56214 19.8729C7.45946 20.1916 8.38625 20.3737 9.30768 20.4246L9.29429 20.4354C11.6809 22.1737 14.8095 22.6961 17.6782 21.7479L21.0827 22.8568C21.1684 22.8836 21.2568 22.8996 21.3479 22.8996C21.822 22.8996 22.205 22.5166 22.205 22.0425V18.4532C24.5648 15.2496 24.6264 10.822 22.2318 7.52464ZM6.93714 17.9711L6.61571 17.8371L3.96393 18.6675L3.93714 15.8818L3.72286 15.6407C1.45679 12.8764 1.30679 8.89339 3.42821 5.97107C6.01036 2.42999 10.9604 1.64785 14.4907 4.20321C18.0318 6.77732 18.8166 11.7193 16.2586 15.2389C14.113 18.1827 10.272 19.2702 6.93714 17.9711ZM20.4639 17.5157L20.2496 17.7836L20.2764 20.5693L17.6514 19.6854L17.33 19.8193C15.83 20.3764 14.2309 20.422 12.7496 20.0068L12.7443 20.0041C14.7801 19.3798 16.5584 18.1119 17.8121 16.3907C19.8586 13.5702 20.1907 10.0264 19.0014 7.00499L19.0175 7.01571C19.6336 7.45767 20.1988 8.00946 20.6782 8.67642C22.6229 11.3443 22.513 14.9764 20.4639 17.5157Z'
      }
      fill={'#a9abb2'}
    />
    <path
      d={
        'M9.88433 9.56244C9.26558 9.56244 8.78611 10.0419 8.78611 10.6339C8.78611 11.2258 9.26558 11.7053 9.88433 11.7053C10.4495 11.7053 10.929 11.2258 10.929 10.6339C10.929 10.0419 10.4495 9.56244 9.88433 9.56244Z'
      }
      fill={'#a9abb2'}
    />
  </SvgIcon>
);
