import { AttachFileDTO } from '@/types/AttachFileDTO';
import { Box } from '@mui/material';
import { TempleImageView } from '@/components/modal/TempleImageView';
import React, { FC, useState } from 'react';

interface Props {
  imageContent: string;
  images: AttachFileDTO[] | undefined;
  selectedImage: number;
}

export const TemplePrimaryImage: FC<Props> = (props: Props) => {
  const { imageContent, images, selectedImage } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '400px',
        overflow: 'hidden',
        borderRadius: '6px',
        cursor: 'pointer',
      }}>
      <img
        onClick={() => setOpen(true)}
        src={`data:image/jpeg;base64,${imageContent}`}
        alt={'main-image'}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      {images && (
        <TempleImageView
          open={open}
          onClose={() => setOpen(false)}
          images={images}
          selectedImage={selectedImage}
        />
      )}
    </Box>
  );
};
