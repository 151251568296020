import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { format } from 'date-fns';
import React, { FC } from 'react';

export const Footer: FC = () => {
  return (
    <Grid
      container={true}
      alignItems={'center'}
      spacing={4}
      justifyContent={'space-between'}>
      <Grid item={true}>
        <Typography
          variant={'body2'}
          color={(theme: Theme) => theme.colors.grayText}>
          <span>{`© 2008—${format(new Date(), 'yyyy')}`}</span>
          <br />
          <span>{'ООО «Интертелекомсервис»'}</span>
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography
          variant={'body2'}
          color={(theme: Theme) => theme.colors.grayText}>
          <span>{`© 2006—${format(new Date(), 'yyyy')}`}</span>
          <br />
          <span>{'ООО «ОРЦ»'}</span>
        </Typography>
      </Grid>
    </Grid>
  );
};
