import { ChangePasswordDTO } from '@/types/user/ChangePasswordDTO';
import { ChangePasswordResponseDTO } from '@/types/user/ChangePasswordResponseDTO';
import { SignInDTO } from '@/types/user/SignInDTO';
import { UserDTO } from '@/types/user/UserDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.mutation<UserDTO, SignInDTO>({
      query: (dto: SignInDTO) => ({
        url: '/auth/login',
        method: 'POST',
        body: dto,
      }),
    }),
    setNewPassword: build.mutation<
      ChangePasswordResponseDTO,
      ChangePasswordDTO
    >({
      query: (dto: ChangePasswordDTO) => ({
        url: '/auth/new-password',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + dto.token,
        },
        body: dto,
      }),
    }),
    restorePassword: build.mutation<unknown, string>({
      query: (email: string) => ({
        url: '/auth/restore',
        method: 'POST',
        body: email,
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSetNewPasswordMutation,
  useRestorePasswordMutation,
} = authApiSlice;
